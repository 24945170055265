import React from "react";
import classnames from "classnames";
import { workloadInfo as styles } from "./styles";
import {
  ContainersList,
  DropdownMenu,
  mapNumberToVCpu,
  mapVCpuToAvailableMemory,
  mapVCpuToNumber,
  TextField,
  vCPU,
  WizardTextField,
  WorkloadBatchOptionalSettings,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  formEventHandler,
  isDataWorkloadPythonCodeEnabled,
  isDataWorkloadsContainerRepositoriesEnabled,
  isEmptyString,
  noop,
} from "@util";
import {
  ContainerRepository,
  CpuArchitecture,
  CreateWorkloadRequest,
  CreateWorkloadRequestAttributes,
  DEFAULT_WORKLOAD_TYPE_LABELS,
  RuntimePlatform,
  WorkloadBatchPropertiesAttributes,
  WorkloadRuntimeOption,
  WorkloadType,
  WorkloadTypeLabels
} from "@data";
import AdvancedSettings from "./AdvancedSettings";
import { useListRepositories } from "@hooks";

export enum WorkloadLanguage {
  NONE = "",
  JAVA = "Java",
  PYTHON = "Python",
}

export enum JavaWorkloadVersion {
  NONE = "",
  JAVA8 = "v1.8",
  JAVA21 = "v21",
}

export enum PythonWorkloadVersion {
  NONE = "",
  PYTHON312 = "v3.12",
}

export type WorkloadLanguageVersion = JavaWorkloadVersion | PythonWorkloadVersion | "";

export const WORKLOAD_LANGUAGES: WorkloadLanguage[] = isDataWorkloadPythonCodeEnabled() ? [
  WorkloadLanguage.JAVA,
  WorkloadLanguage.PYTHON,
] : [WorkloadLanguage.JAVA];

export const JAVA_WORKLOAD_LANGUAGE_VERSION_LABELS: string[] = [
  JavaWorkloadVersion.JAVA8,
  JavaWorkloadVersion.JAVA21,
];

export const PYTHON_WORKLOAD_LANGUAGE_VERSION_LABELS: string[] = [
  PythonWorkloadVersion.PYTHON312,
];

export const DEFAULT_WORKLOAD_TYPES: WorkloadType[] = [WorkloadType.FUNCTION, WorkloadType.QUERY].concat(
  isDataWorkloadsContainerRepositoriesEnabled() ? [WorkloadType.BATCH] : []);

const mapWorkloadRuntimeOptionToWorkloadLanguage = (runtime: WorkloadRuntimeOption): WorkloadLanguage => {
  switch (runtime) {
    case WorkloadRuntimeOption.JAVA8:
    case WorkloadRuntimeOption.JAVA21:
      return WorkloadLanguage.JAVA;
    case WorkloadRuntimeOption.PYTHON312:
      return WorkloadLanguage.PYTHON;
    default:
      return WorkloadLanguage.NONE;
  }
};

const mapWorkloadRuntimeOptionToWorkloadLanguageVersion = (runtime: WorkloadRuntimeOption): WorkloadLanguageVersion => {
  switch (runtime) {
    case WorkloadRuntimeOption.JAVA8:
      return JavaWorkloadVersion.JAVA8;
    case WorkloadRuntimeOption.JAVA21:
      return JavaWorkloadVersion.JAVA21;
    case WorkloadRuntimeOption.PYTHON312:
      return PythonWorkloadVersion.PYTHON312;
    default:
      return "";
  }
};

const mapWorkloadLanguageToLabel = (language: WorkloadLanguage): string => language;

const mapWorkloadLanguageVersionToLabel = (version: string): React.ReactNode => {
  switch (version) {
    case JavaWorkloadVersion.JAVA8:
      return JavaWorkloadVersion.JAVA8;
    case JavaWorkloadVersion.JAVA21:
      return JavaWorkloadVersion.JAVA21;
    case PythonWorkloadVersion.PYTHON312:
      return PythonWorkloadVersion.PYTHON312;
    default:
      return "";
  }
};

const mapWorkloadLanguageVersionToWorkloadRuntimeOption = (version: WorkloadLanguageVersion): WorkloadRuntimeOption => {
  switch (version) {
    case JavaWorkloadVersion.JAVA8:
      return WorkloadRuntimeOption.JAVA8;
    case JavaWorkloadVersion.JAVA21:
      return WorkloadRuntimeOption.JAVA21;
    case PythonWorkloadVersion.PYTHON312:
      return WorkloadRuntimeOption.PYTHON312;
    default:
      return WorkloadRuntimeOption.NONE;
  }
};

export interface Model {
  data?: CreateWorkloadRequest;
  types?: WorkloadType[];
  typeLabels?: WorkloadTypeLabels;
}

export interface Actions {
  clearSelectedDataSets?: () => void;
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
  mapWorkloadTypeToLabel?: (type: WorkloadType) => React.ReactNode | string | null;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TypeView = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = CreateWorkloadRequest.EMPTY,
    types = DEFAULT_WORKLOAD_TYPES,
    typeLabels = DEFAULT_WORKLOAD_TYPE_LABELS,
    mapWorkloadTypeToLabel = React.useCallback((type: WorkloadType) =>
      typeLabels[type] || null, [typeLabels]),
    setWorkloadData = noop,
    clearSelectedDataSets = noop,
  } = props;

  const isFunctionType = React.useMemo(() => data.isFunctionType(), [data]);
  const isBatchType = React.useMemo(() => data.isBatchType(), [data]);

  const [ selectedWorkloadLanguage, setSelectedWorkloadLanguage ] =
    React.useState<WorkloadLanguage>(mapWorkloadRuntimeOptionToWorkloadLanguage(data.getRuntime()));

  const [ selectedWorkloadLanguageVersion, setSelectedWorkloadLanguageVersion ] =
    React.useState<WorkloadLanguageVersion>(mapWorkloadRuntimeOptionToWorkloadLanguageVersion(data.getRuntime()));

  const [ selectedRuntime, setSelectedRuntime ] = React.useState<WorkloadRuntimeOption>(data.getRuntime());

  const languageVersionLabels = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.JAVA:
        return JAVA_WORKLOAD_LANGUAGE_VERSION_LABELS;
      case WorkloadLanguage.PYTHON:
        return PYTHON_WORKLOAD_LANGUAGE_VERSION_LABELS;
      default:
        return [];
    }
  }, [ selectedWorkloadLanguage ]);

  const entryPointHelperText = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "When the Python runtime environment is selected, the request handler is in the format [file name].[function name]. This will be the entry point for the Python workload.";
      case WorkloadLanguage.JAVA:
        return "When the Java runtime environment is selected, the request entry point must be the class that implements the com.signify.iot.platform.bdi.dataprocessing.DataProcessingWorkload interface.";
      default:
        return "Specifies the function in the file to be executed first when you invoke a function.";
    }
  }, [ selectedWorkloadLanguage ]);

  const entryPointPlaceholder = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "workload.handler";
      case WorkloadLanguage.JAVA:
        return "com.signify.workload.WorkloadEventEntryPoint";
      default:
        return "Enter a request entry point";
    }
  }, [ selectedWorkloadLanguage ]);

  const showLanguageVersion = React.useMemo(() =>
      WorkloadLanguage.NONE !== selectedWorkloadLanguage,
    [ selectedWorkloadLanguage ]);

  const showEntryPoint = React.useMemo(() =>
      selectedRuntime !== WorkloadRuntimeOption.NONE,
    [ selectedRuntime ]);

  const entryPointLabel = React.useMemo(() => {
    switch (selectedWorkloadLanguage) {
      case WorkloadLanguage.PYTHON:
        return "Request Handler";
      case WorkloadLanguage.JAVA:
        return "Request Entry Point";
      default:
        return "Request Entry Point";
    }
  }, [selectedWorkloadLanguage]);

  const [containerRepo, setContainerRepo] = React.useState<ContainerRepository[]>([]);

  const updateBatchProps = React.useCallback((batchProps: Partial<WorkloadBatchPropertiesAttributes>) => {
    setWorkloadData({
      batchWorkloadProperties: {
        ...data.getBatchWorkloadProperties().toJS(),
        ...batchProps,
      }
    });
  }, [setWorkloadData, data]);

  const updateContainerRepo = React.useCallback((repo: ContainerRepository[]) => {
    setContainerRepo(repo);
    const newRepo = repo.length > 0 ? repo[0] : ContainerRepository.EMPTY;
    updateBatchProps({
      repositoryAlias: newRepo.getAlias(),
    });
  }, [setContainerRepo, updateBatchProps]);

  const updateRuntimePlatform = React.useCallback((runtime: Partial<RuntimePlatform>) => {
    updateBatchProps({
      runtimePlatform: {
        ...data.getBatchWorkloadProperties().getRuntimePlatform(),
        ...runtime,
      }
    });
  }, [updateBatchProps, data]);

  const updateVCpu = React.useCallback((value: vCPU) => {
    const vCpu = mapVCpuToNumber(value);
    const defaultMem = mapVCpuToAvailableMemory(vCpu)[0];
    updateRuntimePlatform({ vCpu: vCpu, memory: Number(defaultMem) });
  }, [updateRuntimePlatform]);

  const [{ repositories, ...model }, actions] = useListRepositories({});

  React.useEffect(() => {
    if (!isEmptyString(data.getBatchWorkloadProperties().getRepositoryAlias())) {
      const currRepo = repositories.find(r => r.getAlias() === data.getBatchWorkloadProperties().getRepositoryAlias());
      if (currRepo) {
        setContainerRepo([currRepo]);
      }
    }
  }, [repositories]);

  React.useEffect(() => {
    setSelectedRuntime(mapWorkloadLanguageVersionToWorkloadRuntimeOption(
      selectedWorkloadLanguageVersion));
  }, [
    selectedWorkloadLanguageVersion,
    setSelectedRuntime,
  ]);

  React.useEffect(() => {
    if (selectedRuntime === WorkloadRuntimeOption.PYTHON312 && isEmptyString(data.getEntryPoint())) {
      setWorkloadData({
        runtime: selectedRuntime,
        entryPoint: "workload_function.event_handler",
      });
    }
  }, [selectedRuntime]);

  return (
    <div className={classnames("workloadType", classes.container)}>
      <label className={classes.title}>
        Configure Workload Type
      </label>
      <div className={classnames("typeContainer", classes.typeContainer)}>
        <DropdownMenu
          className={classnames("type", classes.dropdown, classes.selectType)}
          selectClassName={classes.dropdownMenuSelect}
          fullWidth={true}
          variant="outlined"
          dropdownMenuLabel="Type"
          dropdownMenuHint="Select the workload type"
          hideEmptyValue={true}
          dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          mapValueToLabel={mapWorkloadTypeToLabel}
          emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
          values={types}
          selectedValue={data.getType()}
          setSelectedValue={type => {
            clearSelectedDataSets();
            setWorkloadData({
              "@type": WorkloadType[type],
              type: WorkloadType[type],
              data: { inputs: [], outputs: [] },
              // Make sure to reset the workloadInternetConfig attribute if not a function type
              // in case the user previously selected a function type, added internet config and then
              // changed the type to query
              ...(type === WorkloadType.FUNCTION ? ({}) : ({
                workloadInternetConfig: {
                  accessibleDns: [],
                },
              })),
            });
          }}
        />
        {isFunctionType && (
          <React.Fragment>
            <DropdownMenu
              className={classnames("language", classes.dropdown, classes.language)}
              selectClassName={classes.dropdownMenuSelect}
              fullWidth={true}
              variant="outlined"
              dropdownMenuHint="Select language that the workload code will be written in"
              dropdownMenuLabel="Language"
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabel="Select language"
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              values={WORKLOAD_LANGUAGES}
              mapValueToLabel={mapWorkloadLanguageToLabel}
              selectedValue={selectedWorkloadLanguage}
              setSelectedValue={language => {
                setWorkloadData({ runtime: WorkloadRuntimeOption.NONE, entryPoint: "" });
                setSelectedRuntime(WorkloadRuntimeOption.NONE);
                setSelectedWorkloadLanguageVersion("");
                setSelectedWorkloadLanguage(language as WorkloadLanguage);
              }}
            />
            {showLanguageVersion && (
              <DropdownMenu
                className={classnames("languageVersion", classes.dropdown, classes.languageVersion)}
                selectClassName={classes.dropdownMenuSelect}
                fullWidth={true}
                variant="outlined"
                dropdownMenuHint="Select language version that the workload code will be written in"
                dropdownMenuLabel="Version"
                dropdownMenuLabelClassName={classes.dropdownMenuLabel}
                emptyValueLabel="Select version"
                emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
                values={languageVersionLabels}
                mapValueToLabel={mapWorkloadLanguageVersionToLabel}
                selectedValue={selectedWorkloadLanguageVersion}
                setSelectedValue={language => {
                  setWorkloadData({ runtime: WorkloadRuntimeOption.NONE, entryPoint: "" });
                  setSelectedWorkloadLanguageVersion(language as WorkloadLanguageVersion);
                  setSelectedRuntime(mapWorkloadLanguageVersionToWorkloadRuntimeOption(
                    language as WorkloadLanguageVersion));
                }}
              />
            )}
            {showEntryPoint && (
              <TextField
                className={classnames("entryPoint", classes.entryPoint)}
                size="small"
                name="entryPoint"
                color="secondary"
                variant="outlined"
                label={entryPointLabel}
                disableSpecialChars={true}
                placeholder={entryPointPlaceholder}
                InputLabelProps={{ shrink: true }}
                helperText={entryPointHelperText}
                value={data.getEntryPoint()}
                onChange={formEventHandler(updatedValue => setWorkloadData({
                  runtime: selectedRuntime,
                  entryPoint: updatedValue,
                }))}
              />
            )}
          </React.Fragment>
        )}
        {isBatchType && (
          <div>
            <ContainersList
              {...model}
              {...actions}
              repositories={repositories}
              className={classnames("containersList", classes.containersList)}
              selectable={true}
              selectAllDisabled={true}
              maxNumSelectedItems={1}
              selectedItems={containerRepo}
              setSelectedItems={updateContainerRepo}
              summaryViewLabel="Select a container repository"
              showIcon={false}
            />
            <WizardTextField
              label="Container Image Tag"
              className="containerImageTag"
              required={true}
              value={data.getBatchWorkloadProperties().getContainerImageTag()}
              setValue={(tag) => updateBatchProps({ containerImageTag: tag })}
              placeholder="Container image tag"
            />
            <DropdownMenu
              className={classnames("cpuArchitecture", classes.dropdown)}
              dropdownMenuLabel="CPU Architecture"
              selectedValue={data.getBatchWorkloadProperties().getCpuArchitecture()}
              setSelectedValue={(value: CpuArchitecture) => updateRuntimePlatform({ cpuArchitecture: value })}
              values={[CpuArchitecture.ARM64, CpuArchitecture.X86_64]}
              hideEmptyValue={true}
              dropdownMenuHint="Select the CPU architecture"
              variant="outlined"
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
            <DropdownMenu
              className={classnames("vCpu", classes.dropdown)}
              dropdownMenuLabel="vCPU"
              selectedValue={mapNumberToVCpu(data.getBatchWorkloadProperties().getVCpu())}
              setSelectedValue={(value: vCPU) => updateVCpu(value)}
              values={[vCPU.ONE, vCPU.TWO, vCPU.FOUR, vCPU.EIGHT, vCPU.SIXTEEN]}
              hideEmptyValue={true}
              dropdownMenuHint="Select the number of vCPUs reserved"
              variant="outlined"
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
            <DropdownMenu
              className={classnames("batchMemory", classes.dropdown)}
              dropdownMenuLabel="Memory"
              selectedValue={data.getBatchWorkloadProperties().getMemoryForBatchWorkload().toString()}
              setSelectedValue={(value: string) => updateRuntimePlatform({ memory: Number(value) })}
              values={mapVCpuToAvailableMemory(data.getBatchWorkloadProperties().getVCpu())}
              hideEmptyValue={true}
              dropdownMenuHint="Select the amount of memory the workload can use in MB"
              variant="outlined"
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
          </div>
        )}
        {isFunctionType && (
          <AdvancedSettings data={data} setWorkloadData={setWorkloadData}/>
        )}
        {isBatchType && (
          <WorkloadBatchOptionalSettings data={data.getBatchWorkloadProperties()} setBatchProps={updateBatchProps} />
        )}
      </div>
    </div>
  );
});

export default TypeView;

import React from "react";
import { ReleaseNotePlatformDomain, SummaryViewData } from "@data";
import ReleaseNoteDetails, {
  ReleaseNoteDetailsActions,
  ReleaseNoteDetailsModel,
} from "../components/ReleaseNoteDetails";
import { useGetByDomainAndVersion } from "@hooks";
import { formatDateToUTC } from "@util";

interface ReleaseNoteDetailsContainerModel extends ReleaseNoteDetailsModel {
  domain: ReleaseNotePlatformDomain;
  version: string;
}

interface ReleaseNoteDetailsContainerActions extends ReleaseNoteDetailsActions {
}

type Model = ReleaseNoteDetailsContainerModel;
type Actions = ReleaseNoteDetailsContainerActions;
type Props = Model & Actions;

const ReleaseNoteDetailsContainer = (props: Props) => {

  const {
    domain,
    version,
    ...otherProps
  } = props;

  // If UUID is entered in URL in cases of old bookmark,
  // we will redirect it to new version based url

  const [{ releaseNote, showLoadingIndicator, ...model }, actions] = useGetByDomainAndVersion({
    domain,
    version
  });

  const title = React.useMemo(() =>
    releaseNote.getTitle() || "Release Note Details", [releaseNote]);

  const introduction = React.useMemo(() =>
    releaseNote.getSummary(), [releaseNote]);

  const description = React.useMemo(() =>
    releaseNote.getDescription(), [releaseNote]);

  const issues = React.useMemo(() =>
    releaseNote.getIssues(), [releaseNote]);

  const summaryViewItems = React.useMemo(() => ([
    new SummaryViewData({
      className: "domain",
      name: "Domain",
      value: releaseNote.getPlatformDomain(),
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: releaseNote.getVersion(),
    }),
    new SummaryViewData({
      className: "releaseDate",
      name: "Date",
      value: showLoadingIndicator ? null : formatDateToUTC(releaseNote.getReleaseDate()),
    }),
    new SummaryViewData({
      className: "releaseType",
      name: "Type",
      value: releaseNote.getReleaseType(),
    }),
    new SummaryViewData({
      className: "releaseStatus",
      name: "Status",
      value: releaseNote.getReleaseStatus(),
    }),
    new SummaryViewData({
      className: "downtime",
      name: "Downtime",
      value: showLoadingIndicator || releaseNote.hasDowntime() ? "" : "No Downtime expected",
    }),
  ]), [releaseNote, showLoadingIndicator]);

  return (
    <ReleaseNoteDetails
      {...model}
      {...actions}
      {...otherProps}
      title={title}
      showLoadingIndicator={showLoadingIndicator}
      introduction={introduction}
      downtime={releaseNote.hasDowntime()}
      downtimeDescription={releaseNote.getDowntimeDescription()}
      description={description}
      summaryViewItems={summaryViewItems}
      issues={issues}
    />
  );
};

export default ReleaseNoteDetailsContainer;

import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withApplicationIdHeader,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";
import { ContainerRepository } from "@data";

const DATA_PROCESSING_API = process.env.REACT_APP_DATA_PROCESSING_API || "";

export interface ListRepositoriesResponse {
  containerRepositories: ContainerRepository[];
  paging: {
    next?: string;
  };
}

export const listContainerRepositories = (authToken: string): Promise<ListRepositoriesResponse> => {
  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/container-repositories`;

    const settings = {
      method: "GET",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      }),
    };

    const defaultErrorMessage = "Fetch container repositories failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createContainerRepository = (authToken: string, body: string): Promise<void> => {
  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Container Repository Body", body],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/container-repositories`;

    const settings = {
      method: "POST",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      body: body,
    };

    const defaultErrorMessage = "Failed to create a container repository";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getContainerRepository = (authToken: string, id: string): Promise<ContainerRepository> => {
  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Container Repository ID", id],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/container-repositories/${id}`;

    const settings = {
      method: "GET",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
    };

    const defaultErrorMessage = "Failed to get details for the container repository";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateContainerRepository = (authToken: string, id: string, body: string): Promise<void> => {
  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Container Repository ID", id],
      ["Container Repository Body", body],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/container-repositories/${id}`;

    const settings = {
      method: "PUT",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: body,
    };

    const defaultErrorMessage = "Failed to update the container repository";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteContainerRepository = (authToken: string, id: string): Promise<void> => {
  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Container Repository ID", id],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/container-repositories/${id}`;

    const settings = {
      method: "DELETE",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
      }),
    };

    const defaultErrorMessage = "Failed to delete the container repository";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

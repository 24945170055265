import { Record } from "immutable";
import { getStringValue } from "@util";
import UserProfileAttribute from "@data/UserProfileAttribute";

export interface ProfileAttributeDefinitionAttributes {
  name: string;
  description: string;
  hidden: boolean;
  optional: boolean;
  immutable: boolean;
  dataType: string;
  defaultValue: string | number | boolean | null;
  maxLength: number;
}

export class ProfileAttributeDefinition extends Record({
  name: "",
  description: "",
  hidden: false,
  optional: false,
  immutable: false,
  dataType: "",
  defaultValue: "",
  maxLength: 0,
}) implements ProfileAttributeDefinitionAttributes {

  public static EMPTY: ProfileAttributeDefinition = new ProfileAttributeDefinition();

  public readonly name: string;
  public readonly description: string;
  public readonly hidden: boolean;
  public readonly optional: boolean;
  public readonly immutable: boolean;
  public readonly dataType: string;
  public readonly defaultValue: string | number | boolean | null;
  public readonly maxLength: number;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public isHidden(): boolean {
    return this.hidden;
  }

  public isOptional(): boolean {
    return this.optional;
  }

  public isImmutable(): boolean {
    return this.immutable;
  }

  public getDataType(): string {
    return getStringValue(this.dataType);
  }

  public toUserProfileAttribute(): UserProfileAttribute {
    return new UserProfileAttribute({
      name: this.name,
      value: this.defaultValue,
      dataType: this.dataType,
      required: !this.isOptional(),
    });
  }

  public getDefaultValue(): string | number | boolean | null {
    return this.toUserProfileAttribute().getValue();
  }

  public getDefaultValueAsString(): string {
    return this.toUserProfileAttribute().getValueAsString();
  }

  public getMaxLength(): number {
    return this.maxLength;
  }

}

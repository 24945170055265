import React from "react";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getStringValue, isEmptyString, noop } from "@util";
import {
  BatchWorkloadCapabilities,
  WorkloadBatchProperties,
  WorkloadBatchPropertiesAttributes,
} from "@data";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  DropdownMenu,
  IconButton,
  mapMinutesToSeconds,
  mapSecondsToMinutes,
} from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import AddIcon from "@material-ui/icons/AddOutlined";

export interface WorkloadBatchOptionalSettingsModel {
  data?: WorkloadBatchProperties;
  isEditMode?: boolean;
}

export interface WorkloadBatchOptionalSettingsActions {
  setBatchProps?: (batchProps: Partial<WorkloadBatchPropertiesAttributes>) => void;
}

type Props = WithStyles<typeof styles> &
  WorkloadBatchOptionalSettingsModel & WorkloadBatchOptionalSettingsActions;

export const DEFAULT_CAPABILITIES_VALUES = [
  BatchWorkloadCapabilities.INTERNET_ACCESS,
  BatchWorkloadCapabilities.IOT_PLATFORM_HTTP_API_ACCESS,
];

export const WorkloadBatchOptionalSettings = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = WorkloadBatchProperties.EMPTY,
    isEditMode = false,
    setBatchProps = noop,
  } = props;

  const [commandInputValue, setCommandInputValue] = React.useState("");
  const [roleInputValue, setRoleInputValue] = React.useState("");
  const [dnsInputValue, setDnsInputValue] = React.useState("");

  const showAddCommandButton = React.useMemo(() => {
    const inputValue = getStringValue(commandInputValue);
    return (!isEmptyString(inputValue) && data.getCommand().indexOf(inputValue) === -1);
  }, [data, commandInputValue]);

  const showAddRoleButton = React.useMemo(() => {
    const inputValue = getStringValue(roleInputValue);
    return (!isEmptyString(inputValue) && data.getBatchWorkloadRoles().indexOf(inputValue) === -1);
  }, [data, roleInputValue]);

  const showAddDnsButton = React.useMemo(() => {
    const inputValue = getStringValue(dnsInputValue);
    return (!isEmptyString(inputValue) && data.getAccessibleDnsNamesForBatch().indexOf(inputValue) === -1);
  }, [data, dnsInputValue]);

  const updateCommand = React.useCallback((value: string[]) => {
    setBatchProps({ command: value });
  }, [setBatchProps]);

  const updateRoles = React.useCallback((value: string[]) => {
    setBatchProps({ roles: value });
  }, [setBatchProps]);

  const updateDns = React.useCallback((value: string[]) => {
    setBatchProps({
      security: {
        ...data.getBatchWorkloadSecurity(),
        networking: {
          internet: {
            accessibleDnsNames: value
          }
        }
      }
    });
  }, [setBatchProps, data]);

  const updateCapabilities = React.useCallback((value: string[]) => {
    setBatchProps({
      security: {
        ...data.getBatchWorkloadSecurity(),
        capabilities: value
      }
    });
  }, [setBatchProps, data]);

  const onClickAddButtonCommand = React.useCallback(() => {
    updateCommand(Array.from(new Set(data.getCommand().concat(commandInputValue))));
    setCommandInputValue("");
  }, [updateCommand, commandInputValue, data, setCommandInputValue]);

  const onClickAddButtonRoles = React.useCallback(() => {
    updateRoles(Array.from(new Set(data.getBatchWorkloadRoles().concat(roleInputValue))));
    setRoleInputValue("");
  }, [updateRoles, roleInputValue, data, setRoleInputValue]);

  const onClickAddButtonDns = React.useCallback(() => {
    updateDns(Array.from(new Set(data.getAccessibleDnsNamesForBatch().concat(dnsInputValue))));
    setDnsInputValue("");
  }, [updateDns, dnsInputValue, data, setDnsInputValue]);

  const updateTimeout = React.useCallback((value: string) => {
    const secs = mapMinutesToSeconds(value);
    setBatchProps({ timeout: secs });
  }, [setBatchProps]);

  return (
    <div className={classnames("workloadBatchOptionalSettings", classes.container)}>
      <Accordion className={classes.noLine} expanded={true}>
        <AccordionSummary
          className={classnames(classes.summary, classes.noPadding)}
          expandIcon={<ExpandMoreIcon />}
        >
          <label className={classes.label}>Optional Settings</label>
        </AccordionSummary>
        <AccordionDetails className={classnames(classes.noPadding, classes.details)}>
          <div className={classnames("autocompleteContainerCommand", classes.autocompleteContainer)}>
            <Autocomplete
              className={classnames("commands", classes.commands)}
              optionClassName={classnames("autocompleteOption", classes.autocompleteOption)}
              label="Command(s)"
              placeholder="Add any optional command(s)"
              noOptionsLabel=""
              openOnFocus={true}
              selectOnFocus={true}
              disableClearable={true}
              freeSolo={true}
              value={data.getCommand()}
              setValue={updateCommand}
              onInputValueChange={setCommandInputValue}
            />
            {showAddCommandButton && (
              <IconButton
                className={classnames("addCommandIconButton", classes.addIconButton)}
                color="primary"
                onClick={onClickAddButtonCommand}
              >
                <AddIcon className={classnames("addCommandIcon", classes.addIcon)} />
              </IconButton>
            )}
          </div>
          <div className={classnames("autocompleteContainerRoles", classes.autocompleteContainer)}>
            <Autocomplete
              className={classnames("roles", classes.roles)}
              optionClassName={classnames("autocompleteOption", classes.autocompleteOption)}
              label="Role(s)"
              placeholder="Add any optional role(s) needed"
              helperText="pattern: arn:aws:[a-zA-Z0-9-]+:[a-zA-Z0-9-]*:[0-9]*:[a-zA-Z0-9-:/._+]*$"
              noOptionsLabel=""
              openOnFocus={false}
              selectOnFocus={false}
              disableClearable={true}
              freeSolo={true}
              value={data.getBatchWorkloadRoles()}
              setValue={updateRoles}
              onInputValueChange={setRoleInputValue}
            />
            {showAddRoleButton && (
              <IconButton
                className={classnames("addRolesIconButton", classes.addIconButton)}
                color="primary"
                onClick={onClickAddButtonRoles}
              >
                <AddIcon className={classnames("addRolesIcon", classes.addIcon)} />
              </IconButton>
            )}
          </div>
          {!isEditMode && (
            <React.Fragment>
              <div className={classnames("autocompleteContainerDns", classes.autocompleteContainer)}>
                <Autocomplete
                  className={classnames("accessibleDns", classes.accessibleDns)}
                  optionClassName={classnames("autocompleteOption", classes.autocompleteOption)}
                  label="Accessible DNS Name(s)"
                  placeholder="Add accessible DNS"
                  noOptionsLabel=""
                  openOnFocus={false}
                  selectOnFocus={false}
                  disableClearable={true}
                  freeSolo={true}
                  value={data.getAccessibleDnsNamesForBatch()}
                  setValue={updateDns}
                  onInputValueChange={setDnsInputValue}
                />
                {showAddDnsButton && (
                  <IconButton
                    className={classnames("addDnsIconButton", classes.addIconButton)}
                    color="primary"
                    onClick={onClickAddButtonDns}
                  >
                    <AddIcon className={classnames("addDnsIcon", classes.addIcon)} />
                  </IconButton>
                )}
              </div>
              <div className={classnames("autocompleteContainerCapabilities", classes.autocompleteContainer)}>
                <Autocomplete
                  className={classnames("capabilities", classes.capabilities)}
                  label="Capabilities"
                  placeholder="Add any capabilities"
                  noOptionsLabel=""
                  options={DEFAULT_CAPABILITIES_VALUES}
                  value={data.getBatchWorkloadCapabilities()}
                  setValue={updateCapabilities}
                  customValuesDisabled={true}
                />
              </div>
            </React.Fragment>
          )}
          <DropdownMenu
            className={classnames("timeout", classes.dropdown)}
            dropdownMenuLabel="Timeout"
            selectedValue={mapSecondsToMinutes(data.getTimeoutForBatchWorkload())}
            setSelectedValue={(value: string) => updateTimeout(value)}
            values={["1 minute", "2 minutes", "5 minutes", "10 minutes", "15 minutes"]}
            hideEmptyValue={true}
            dropdownMenuHint="Select the maximum duration of a running instance of the workload"
            variant="outlined"
            selectClassName={classes.dropdownMenuSelect}
            dropdownMenuLabelClassName={classes.dropdownMenuLabel}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default WorkloadBatchOptionalSettings;

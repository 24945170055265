import useCurrentAccountId from "@hooks/use-current-account-id";
import isPhoneNumberUserSupportEnabled from "@util/isPhoneNumberUserSupportEnabled";
import getPhoneNumberUserWhitelistedAccounts from "@util/getPhoneNumberUserWhitelistedAccounts";
import isPhoneNumberUserAccountWhitelistingEnabled from "@util/isPhoneNumberUserAccountWhitelistingEnabled";

export const useCheckIfPhoneNumberUserSupportEnabled = (): boolean => {

  const accountId = useCurrentAccountId();

  const phoneNumberUserSupportEnabled = isPhoneNumberUserSupportEnabled();

  if (!phoneNumberUserSupportEnabled) {
    return false;
  }

  if (!isPhoneNumberUserAccountWhitelistingEnabled()) {
    return true;
  }

  return getPhoneNumberUserWhitelistedAccounts().includes(accountId);
};

export default useCheckIfPhoneNumberUserSupportEnabled;

import createStyles from "@material-ui/core/styles/createStyles";
import { PrimaryIcon } from "@modules/releaseNotes/components/styles";
import Color from "@Color";

export { PrimaryIcon, PrimaryIcon as ModuleIcon };

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    summaryView: {
      "& .content": {
        marginTop: 25,
      },
    },
    introduction: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    introductionTitle: {
      color: Color.TEXT,
      fontWeight: 400,
      fontSize: 24,
    },
    introductionBody: {
      color: Color.TEXT,
      marginTop: 10,
      marginBottom: 0,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "24px",
    },
    introDescription: {
      color: Color.TEXT,
      marginBottom: 0,
      fontWeight: 400,
      fontSize: 14,
    },
    releaseNoteIssues: {
      marginTop: 25,
    },
    releaseNoteIssuesList: {},
    breakingChanges: {
      "& .tableBodyRow label, a, svg": {
        fontWeight: 600,
      },
    },
    downtimeAlert: {
      marginTop: 10,
      marginBottom: 0,
    },
    newFeatures: {},
    defectFixes: {},
    otherEnhancements: {},
    knownIssues: {},
    changeRequests: {},
  });

export default styles;

import React from "react";
import classnames from "classnames";
import { SmsTemplate } from "@data";
import Typography from "@material-ui/core/Typography";
import { selectSmsTemplate as styles } from "./styles";
import { getPathToSmsTemplate } from "@modules/smsTemplateDetails/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SmsTemplatesList, {
  SmsTemplatesListActions,
  SmsTemplatesListModel,
} from "@components/sms-templates-list";
import MessagePreview from "./MessagePreview";

export interface Model extends SmsTemplatesListModel {
  templateSelected?: boolean;
}

export interface Actions extends SmsTemplatesListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SelectSmsTemplate = withStyles(styles)((props: Props) => {

  const {
    classes,
    noResultsLabel = "There are not any valid SMS templates in this IoT Account to invite a user with",
    selectedItems = [],
    templateSelected = React.useMemo(() => selectedItems.length > 0, [selectedItems]),
    onClickShowMoreInfoInNewTab = React.useCallback((template: SmsTemplate): string => {
      return getPathToSmsTemplate(template.getId());
    }, []),
    children,
    ...otherProps
  } = props;

  const title = React.useMemo(() =>
      templateSelected
        ? "We will invite this user with the following SMS template"
        : "Would you like to select a specific SMS template that should be used to invite this user?",
    [templateSelected]);

  const message = React.useMemo(() => selectedItems.length > 0 ? selectedItems[0].getTextBody() : "", [selectedItems]);

  return (
    <React.Fragment>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <div className={classnames("container", classes.container)}>
        <div className={classnames("column", classes.listContainer)}>
          <SmsTemplatesList
            {...otherProps}
            className={classnames("smsTemplates", classes.list)}
            selectable={true}
            showSearch={false}
            showSummary={false}
            selectAllDisabled={true}
            maxNumSelectedItems={1}
            selectedItems={selectedItems}
            noResultsLabel={noResultsLabel}
            summaryViewLabel={title}
            onClickShowMoreInfoInNewTab={onClickShowMoreInfoInNewTab}
          />
        </div>
        <div className={classnames("messagePreview", classes.preview)}>
          <Typography className={classnames("title", classes.title, classes.subtitle)} variant="h4">
            Message Preview
          </Typography>
          <MessagePreview message={message} />
        </div>
      </div>
      {children}
    </React.Fragment>
  );
});

export default SelectSmsTemplate;

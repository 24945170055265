import ModuleIcon from "@material-ui/icons/PhotoLibrary";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const repositoryInfo = () =>
  createStyles({
    container: {

    },
    dropdown: {

    },
    textFieldContainer: {

    }
  });

import React from "react";
import { isEmptyString } from "@util";
import { CostReportingGroupedMetric } from "@data";
import useCostReportingClient from "../use-cost-reporting-client";
import { GetCostReportingGroupedMetricsOptions, GetCostReportingGroupedMetricsResponse } from "@network";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps
} from "@hooks";

type SuccessResponse = GetCostReportingGroupedMetricsResponse;

export interface UseGetCostReportingGroupedMetricsProps extends
  Partial<UsePaginatedApiRequestProps<SuccessResponse>>, GetCostReportingGroupedMetricsOptions {
}

export interface UseGetCostReportingGroupedMetricsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  groupedMetrics: CostReportingGroupedMetric[];
}

export interface UseGetCostReportingGroupedMetricsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetCostReportingGroupedMetricsProps;
type Model = UseGetCostReportingGroupedMetricsModel;
type Actions = UseGetCostReportingGroupedMetricsActions;
type Result = [Model, Actions];

export const useGetCostReportingGroupedMetrics = (props: Props): Result => {

  const {
    periodType,
    metricPartition,
    domainType,
    groupType,
    categoryType,
    metricType,
    resourceType,
    defaultErrorMessage = "Failed to fetch grouped metrics",
    limit,
    ...otherProps
  } = props;

  const CostReportingClient = useCostReportingClient();

  const [groupedMetrics, setGroupedMetrics] = React.useState<CostReportingGroupedMetric[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
    CostReportingClient.getCostReportingGroupedMetrics({
      periodType,
      domainType,
      groupType,
      categoryType,
      metricType,
      resourceType,
      ...(isEmptyString(metricPartition) ? ({ metricPartition: "NONE" }) : ({ metricPartition })),
      ...(isEmptyString(next) ? ({}) : ({ next })),
      limit,
    }), [
    CostReportingClient,
    periodType,
    metricPartition,
    domainType,
    groupType,
    categoryType,
    metricType,
    resourceType,
    limit,
  ]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedGroupedMetrics = React.useMemo(() => {
    const { metrics: items = [] } = successResponse || {};
    return items.map(attrs => new CostReportingGroupedMetric(attrs));
  }, [successResponse]);

  const loadedGroupedMetrics = React.useMemo(() => {
    return updatedGroupedMetrics.length && !groupedMetrics.length ? updatedGroupedMetrics : groupedMetrics;
  }, [updatedGroupedMetrics, groupedMetrics]);

  const reset = React.useCallback(() => {
    setGroupedMetrics([]);
    baseReset();
  }, [setGroupedMetrics, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    groupedMetrics: loadedGroupedMetrics,
  }), [
    baseModel,
    successResponse,
    loadedGroupedMetrics,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setGroupedMetrics(currentDataSets => currentDataSets.concat(updatedGroupedMetrics));
  }, [updatedGroupedMetrics, setGroupedMetrics]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetCostReportingGroupedMetrics;

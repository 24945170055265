import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import UserIdentityType from "@data/UserIdentityType";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface SelectUserIdentityTypeModel {
  className?: string;
  title?: string;
}

export interface SelectUserIdentityTypeActions {
  setIdentityType?: (userIdentityType: UserIdentityType | null) => void;
}

type Model = SelectUserIdentityTypeModel;
type Actions = SelectUserIdentityTypeActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SelectUserIdentityType = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Do you want to create a user based on an email address or a phone number?",
    setIdentityType = noop,
    children,
  } = props;

  return (
    <div className={classnames("selectUserIdentityType", className, classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h1">
        {title}
      </Typography>
      <div className={classnames("cards", classes.cards)}>
        <div
          className={classnames("emailCard", classes.card)}
          onClick={clickHandler(() => setIdentityType(UserIdentityType.EMAIL))}
        >
          <div className={classnames("cardHeader", classes.cardHeader)}>
            <EmailIcon className={classnames("emailIcon", classes.cardIcon)} />
            <Typography className={classnames("cardTitle", classes.cardTitle)} variant="h3">
              Email
            </Typography>
          </div>
          <Typography className={classnames("emailCardDescription", classes.cardDescription)} variant="body1">
            User ID will be based off an email address
          </Typography>
        </div>
        <div
          className={classnames("phoneNumberCard", classes.card)}
          onClick={clickHandler(() => setIdentityType(UserIdentityType.PHONE))}
        >
          <div className={classnames("cardHeader", classes.cardHeader)}>
            <PhoneIcon className={classnames("phoneIcon", classes.cardIcon)} />
            <Typography className={classnames("cardTitle", classes.cardTitle)} variant="h3">
              Phone Number
            </Typography>
          </div>
          <Typography className={classnames("phoneNumberCardDescription", classes.cardDescription)} variant="body1">
            User ID will be based off a phone number
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
});

export default SelectUserIdentityType;

import { Module } from "@data";
import { createModule } from "@base/createModule";
import { PrimaryIcon as icon } from "./components/styles";
import { MODULE_ID, MODULE_TITLE, MODULE_PATH } from "./constants";
import COST_REPORTS_REQUIREMENTS from "./requirements";
import view from "./CostReportsView";

export const costReportsModule: Module = createModule({
  ...COST_REPORTS_REQUIREMENTS,
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: MODULE_TITLE,
  title: MODULE_TITLE,
  className: "costReportsModule",
  icon,
  view,
});

export default costReportsModule;

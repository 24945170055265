import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { useEditBatchProperties, useListRepositories } from "@hooks";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import {
  ContainerRepository,
  RuntimePlatform,
} from "@data";
import { WorkloadBatchProperties, WorkloadBatchPropertiesAttributes } from "@data/WorkloadBatchProperties";
import {
  ContainersList,
  DropdownMenu,
  mapNumberToVCpu,
  mapVCpuToAvailableMemory,
  mapVCpuToNumber,
  vCPU,
  WizardTextField,
  WorkloadBatchOptionalSettings
} from "@components";

export interface WorkloadBatchPropertiesDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  etag?: string;
  initialData?: WorkloadBatchProperties;
  dialogClassName?: string;
}

export interface WorkloadBatchPropertiesDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & WorkloadBatchPropertiesDialogModel & WorkloadBatchPropertiesDialogActions;

export const WorkloadBatchPropertiesDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    open,
    name = "",
    version = 1,
    etag = "",
    initialData = WorkloadBatchProperties.EMPTY,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  if (!open) {
    return null;
  }

  const [batchPropertiesRequest, setBatchPropertiesRequest] = React.useState<WorkloadBatchProperties>(
    new WorkloadBatchProperties(initialData.toJS())
  );
  const [containerRepo, setContainerRepo] = React.useState<ContainerRepository[]>([]);

  const [{ showLoadingIndicator, ...otherModel }, { editBatchProperties, reset }] =
    useEditBatchProperties({ name, version, etag, batchProperties: batchPropertiesRequest.toJS() });

  const confirm = React.useCallback(() => {
    editBatchProperties();
  }, [editBatchProperties]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
  }, [reset, cancelDialog]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
  }, [reset, onSuccess]);

  const disabled = React.useMemo(() =>
    isEmptyString(batchPropertiesRequest.getContainerImageTag())
      || isEmptyString(batchPropertiesRequest.getRepositoryAlias()),
    [batchPropertiesRequest]);

  const updateBatchProps = React.useCallback((batchProps: Partial<WorkloadBatchPropertiesAttributes>) => {
    setBatchPropertiesRequest(new WorkloadBatchProperties({
      ...batchPropertiesRequest.toJS(),
      ...batchProps,
    }));
  }, [setBatchPropertiesRequest, batchPropertiesRequest]);

  const updateContainerRepo = React.useCallback((repo: ContainerRepository[]) => {
    setContainerRepo(repo);
    const newRepo = repo.length > 0 ? repo[0] : ContainerRepository.EMPTY;
    updateBatchProps({
      repositoryAlias: newRepo.getAlias(),
    });
  }, [setContainerRepo, updateBatchProps]);

  const updateRuntimePlatform = React.useCallback((runtime: Partial<RuntimePlatform>) => {
    updateBatchProps({
      runtimePlatform: {
        ...batchPropertiesRequest.getRuntimePlatform(),
        ...runtime,
      }
    });
  }, [updateBatchProps, batchPropertiesRequest]);

  const updateVCpu = React.useCallback((value: vCPU) => {
    const vCpu = mapVCpuToNumber(value);
    const defaultMem = mapVCpuToAvailableMemory(vCpu)[0];
    updateRuntimePlatform({ vCpu: vCpu, memory: Number(defaultMem) });
  }, [updateRuntimePlatform]);

  const [{ repositories, ...model }, actions] = useListRepositories({});

  React.useEffect(() => {
    if (!isEmptyString(batchPropertiesRequest.getRepositoryAlias())) {
      const currRepo = repositories.find(r => r.getAlias() === batchPropertiesRequest.getRepositoryAlias());
      if (currRepo) {
        setContainerRepo([currRepo]);
      }
    }
  }, [repositories]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadBatchPropertiesDialog", dialogClassName)}
      title="Edit Batch Properties"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      continueButtonDisabled={disabled}
      onSuccessMessageShown={onSuccessMessageShown}
      open={open}
      maxWidth={"md"}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("editBatchPropertiesContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Edit Batch Properties:
          </label>
          <div>
            <ContainersList
              {...model}
              {...actions}
              repositories={repositories}
              className={classnames("containersList", classes.containersList)}
              selectable={true}
              selectAllDisabled={true}
              maxNumSelectedItems={1}
              selectedItems={containerRepo}
              setSelectedItems={updateContainerRepo}
              summaryViewLabel="Select a container repository"
              showIcon={false}
            />
            <WizardTextField
              label="Container Image Tag"
              className="containerImageTag"
              required={true}
              value={batchPropertiesRequest.getContainerImageTag()}
              setValue={(tag) => updateBatchProps({ containerImageTag: tag })}
              placeholder="Container image tag"
            />
            <DropdownMenu
              className={classnames("vCpu", classes.dropdown)}
              dropdownMenuLabel="vCPU"
              selectedValue={mapNumberToVCpu(batchPropertiesRequest.getVCpu())}
              setSelectedValue={(value: vCPU) => updateVCpu(value)}
              values={[vCPU.ONE, vCPU.TWO, vCPU.FOUR, vCPU.EIGHT, vCPU.SIXTEEN]}
              hideEmptyValue={true}
              dropdownMenuHint="Select the number of vCPUs reserved"
              variant="outlined"
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
            <DropdownMenu
              className={classnames("batchMemory", classes.dropdown)}
              dropdownMenuLabel="Memory"
              selectedValue={batchPropertiesRequest.getMemoryForBatchWorkload().toString()}
              setSelectedValue={(value: string) => updateRuntimePlatform({ memory: Number(value) })}
              values={mapVCpuToAvailableMemory(batchPropertiesRequest.getVCpu())}
              hideEmptyValue={true}
              dropdownMenuHint="Select the amount of memory the workload can use in MB"
              variant="outlined"
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
          </div>
          <WorkloadBatchOptionalSettings
            data={batchPropertiesRequest}
            setBatchProps={updateBatchProps}
            isEditMode={true}
          />
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadBatchPropertiesDialog;

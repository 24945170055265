import React from "react";
import { isEmptyString, noop } from "@util";
import { UserProfileAttribute } from "@data";
import SortedSearchResultsList, {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";
import { ProfileAttributesIcon } from "@components/user-profile-attributes-list/styles";
import { UserProfileAttributesListColumn as Column } from "./UserProfileAttributesListColumn";
import { UserProfileAttributeListItem } from "./UserProfileAttributeListItem";

export const DEFAULT_USER_PROFILE_ATTRIBUTES_LIST_COLUMNS: Column[] = [
  Column.NAME,
  Column.VALUE,
  Column.DATA_TYPE,
  Column.REQUIRED,
];

export interface UserProfileAttributesListModel extends
  SortedSearchResultsListModel<UserProfileAttribute, Column> {

  userId?: string;
  showBinaryData?: boolean;
  selfAuthorized?: boolean;
}

export interface UserProfileAttributesListActions extends
  SortedSearchResultsListActions<UserProfileAttribute, Column> {

}

type Props = UserProfileAttributesListModel & UserProfileAttributesListActions;

export const UserProfileAttributesList = (props: Props) => {

  const {
    className = "userProfileAttributes",
    listClassName = "userProfileAttributesList",
    columns = DEFAULT_USER_PROFILE_ATTRIBUTES_LIST_COLUMNS,
    sortByColumn = Column.NAME,
    sortOrderAscending = true,
    noResultsLabel: defaultNoResultsLabel = "No profile attributes found",
    loadingLabel = "Fetching profile attributes...",
    summaryViewQuantities = {
      other: "profile attributes",
      one: "profile attribute",
    },
    items = [],
    nameFilter = "",
    userId = "",
    showBinaryData,
    selfAuthorized,
    onClickAction = noop,
    renderItem = (attribute: UserProfileAttribute, column: Column) => (
      <UserProfileAttributeListItem
        column={column}
        userId={userId}
        attribute={attribute}
        showBinaryData={showBinaryData}
        selfAuthorized={selfAuthorized}
      />
    ),
    ...otherProps
  } = props;

  const showFilteredView = React.useMemo(() =>
    !isEmptyString(nameFilter), [nameFilter]);

  const noResultsLabel = React.useMemo(() => {
    if (showFilteredView) {
      return "No Results";
    } else {
      return defaultNoResultsLabel;
    }
  }, [showFilteredView, defaultNoResultsLabel]);

  return (
    <SortedSearchResultsList
      {...otherProps}
      className={className}
      listClassName={listClassName}
      items={items}
      columns={columns}
      nameFilter={nameFilter}
      loadingLabel={loadingLabel}
      nameFilterDelay={25}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={ProfileAttributesIcon}
      summaryViewQuantities={summaryViewQuantities}
      renderItem={renderItem}
    />
  );
};

export default UserProfileAttributesList;

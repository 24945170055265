import React from "react";
import classnames from "classnames";
import { ContainerRepository } from "@data";
import ModuleListView, {
  createColumns,
  ListViewItem,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";
import { PrimaryIcon } from "@modules/containerRepositories/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { ActionMenuItem } from "@components/actions-menu";
import { DeleteContainerRepositoryDialog } from "@components/delete-container-repository-dialog";
import { noop } from "@util";

export enum ContainersListViewColumn {
  ALIAS = "Alias",
  TYPE = "Type",
}

export enum ContainersListViewAction {
  DELETE = "DELETE",
}

export const DELETE_REPOSITORY: ActionMenuItem = Object.freeze({
  id: ContainersListViewAction.DELETE,
  name: "Delete Repository",
});

type Item = ContainerRepository;
type Column = ContainersListViewColumn;

export interface ContainersListViewModel extends ModuleListViewModel<Item, Column> {
  repositories?: Item[];
  showActions?: boolean;
}

export interface ContainersListViewActions extends ModuleListViewActions<Item, Column> {
}

type Model = ContainersListViewModel;
type Actions = ContainersListViewActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const ContainersList = withStyles(styles)((props: Props) => {
  const {
    classes,
    repositories = [],
    showActions = false,
    actions = showActions ? [DELETE_REPOSITORY] : [],
    summaryViewQuantities = {
      other: "repositories",
      one: "repository"
    },
    refresh = noop,
    ...otherProps
  } = props;

  const moduleListItems = React.useMemo<ListViewItem<ContainerRepository>[]>(() =>
    repositories.map((repository: ContainerRepository) => ({
      item: repository,
      icon: PrimaryIcon,
      columnAttributes: createColumns([
        {
          className: "alias",
          value: repository.getAlias(),
          column: ContainersListViewColumn.ALIAS,
          firstColumn: true,
        },
        {
          className: "repositoryType",
          value: repository.getType(),
          column: ContainersListViewColumn.TYPE,
        },
      ]),
    })), [repositories]);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  const [selectedRepository, setSelectedRepository] = React.useState<string>("");

  const onClick = React.useCallback((item: ContainerRepository, action: ActionMenuItem) => {
    if (action.id === ContainersListViewAction.DELETE) {
      setOpenDeleteDialog(true);
      setSelectedRepository(item.getAlias());
    }
  }, [setOpenDeleteDialog, setSelectedRepository]);

  const onDeleteSuccess = React.useCallback(() => {
    setOpenDeleteDialog(false);
    setSelectedRepository("");
    refresh();
  }, [setOpenDeleteDialog, setSelectedRepository, refresh]);

  return (
    <ModuleListView
      {...otherProps}
      showSummary={true}
      showSearch={false}
      summaryViewIcon={PrimaryIcon}
      summaryViewQuantities={summaryViewQuantities}
      noResultsLabel="No repositories found"
      listClassName={classnames("repositoriesList", classes.list)}
      columns={[ContainersListViewColumn.ALIAS, ContainersListViewColumn.TYPE]}
      listViewItems={moduleListItems}
      actions={actions}
      onClickAction={onClick}
      refresh={refresh}
    >
      {openDeleteDialog && (
        <DeleteContainerRepositoryDialog
          alias={selectedRepository}
          open={openDeleteDialog}
          onSuccess={onDeleteSuccess}
          cancel={() => setOpenDeleteDialog(false)}
        />
      )}
    </ModuleListView>
  );
});

export default ContainersList;

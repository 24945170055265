import { useApiRequestAnalytics } from "@hooks";
import { ContainerManagerClient, ListRepositoriesResponse } from "@network";
import { ContainerRepository } from "@data";

export type ListContainerRepositoriesApi = () => Promise<ListRepositoriesResponse>;
export type CreateContainerRepositoryApi = (json: string) => Promise<void>;
export type GetContainerRepositoryApi = (id: string) => Promise<ContainerRepository>;
export type UpdateContainerRepositoryApi = (id: string, json: string) => Promise<void>;
export type DeleteContainerRepositoryApi = (id: string) => Promise<void>;

export interface ContainerManagementClientApi {
  listContainerRepositories: ListContainerRepositoriesApi;
  createContainerRepository: CreateContainerRepositoryApi;
  getContainerRepository: GetContainerRepositoryApi;
  updateContainerRepository: UpdateContainerRepositoryApi;
  deleteContainerRepository: DeleteContainerRepositoryApi;
}

enum ActionTypes {
  LIST_CONTAINER_REPOSITORIES_REQUEST = "container_manager_client/list_container_repositories_request",
  LIST_CONTAINER_REPOSITORIES_SUCCESS = "container_manager_client/list_container_repositories_success",
  LIST_CONTAINER_REPOSITORIES_FAILED = "container_manager_client/list_container_repositories_failed",

  CREATE_CONTAINER_REPOSITORY_REQUEST = "container_manager_client/create_container_repository_request",
  CREATE_CONTAINER_REPOSITORY_SUCCESS = "container_manager_client/create_container_repository_success",
  CREATE_CONTAINER_REPOSITORY_FAILED = "container_manager_client/create_container_repository_failed",

  GET_CONTAINER_REPOSITORY_REQUEST = "container_manager_client/get_container_repository_request",
  GET_CONTAINER_REPOSITORY_SUCCESS = "container_manager_client/get_container_repository_success",
  GET_CONTAINER_REPOSITORY_FAILED = "container_manager_client/get_container_repository_failed",

  UPDATE_CONTAINER_REPOSITORY_REQUEST = "container_manager_client/update_container_repository_request",
  UPDATE_CONTAINER_REPOSITORY_SUCCESS = "container_manager_client/update_container_repository_success",
  UPDATE_CONTAINER_REPOSITORY_FAILED = "container_manager_client/update_container_repository_failed",

  DELETE_CONTAINER_REPOSITORY_REQUEST = "container_manager_client/delete_container_repository_request",
  DELETE_CONTAINER_REPOSITORY_SUCCESS = "container_manager_client/delete_container_repository_success",
  DELETE_CONTAINER_REPOSITORY_FAILED = "container_manager_client/delete_container_repository_failed",
}

export const useListContainerRepositoriesApi = (): ListContainerRepositoriesApi =>
  useApiRequestAnalytics(ContainerManagerClient.listContainerRepositories, {
    REQUEST_EVENT: ActionTypes.LIST_CONTAINER_REPOSITORIES_REQUEST,
    SUCCESS_EVENT: ActionTypes.LIST_CONTAINER_REPOSITORIES_SUCCESS,
    ERROR_EVENT: ActionTypes.LIST_CONTAINER_REPOSITORIES_FAILED,
  });

export const useCreateContainerRepositoryApi = (): CreateContainerRepositoryApi =>
  useApiRequestAnalytics(ContainerManagerClient.createContainerRepository, {
    REQUEST_EVENT: ActionTypes.CREATE_CONTAINER_REPOSITORY_REQUEST,
    SUCCESS_EVENT: ActionTypes.CREATE_CONTAINER_REPOSITORY_SUCCESS,
    ERROR_EVENT: ActionTypes.CREATE_CONTAINER_REPOSITORY_FAILED,
  });

export const useGetContainerRepositoryApi = (): GetContainerRepositoryApi =>
  useApiRequestAnalytics(ContainerManagerClient.getContainerRepository, {
    REQUEST_EVENT: ActionTypes.GET_CONTAINER_REPOSITORY_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_CONTAINER_REPOSITORY_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_CONTAINER_REPOSITORY_FAILED,
  });

export const useUpdateContainerRepositoryApi = (): DeleteContainerRepositoryApi =>
  useApiRequestAnalytics(ContainerManagerClient.updateContainerRepository, {
    REQUEST_EVENT: ActionTypes.UPDATE_CONTAINER_REPOSITORY_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_CONTAINER_REPOSITORY_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_CONTAINER_REPOSITORY_FAILED,
  });

export const useDeleteContainerRepositoryApi = (): DeleteContainerRepositoryApi =>
  useApiRequestAnalytics(ContainerManagerClient.deleteContainerRepository, {
    REQUEST_EVENT: ActionTypes.DELETE_CONTAINER_REPOSITORY_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_CONTAINER_REPOSITORY_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_CONTAINER_REPOSITORY_FAILED,
  });

export const useContainerManagerClient = (): ContainerManagementClientApi => ({
  listContainerRepositories: useListContainerRepositoriesApi(),
  createContainerRepository: useCreateContainerRepositoryApi(),
  getContainerRepository: useGetContainerRepositoryApi(),
  updateContainerRepository: useUpdateContainerRepositoryApi(),
  deleteContainerRepository: useDeleteContainerRepositoryApi(),
});

export { ActionTypes as ContainerManagementClientActionType };

export default useContainerManagerClient;

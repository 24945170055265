import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { useDeleteSmsTemplate } from "@hooks";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface DeleteSmsTemplateDialogModel extends PortalModuleDialogModel<string> {
  templateId?: string;
}

export interface DeleteSmsTemplateDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles<typeof styles> & DeleteSmsTemplateDialogModel & DeleteSmsTemplateDialogActions;

export const DeleteSmsTemplateDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    templateId = "",
    cancel = noop,
    onSuccessMessageShown = noop,
    ...otherProps
  } = props;

  const [{ showLoadingIndicator, ...otherModel }, { refresh: deleteSmsTemplate, reset, ...otherActions }] =
    useDeleteSmsTemplate({ templateId });

  const cancelDialog = React.useCallback(() => {
    cancel();
    reset();
  }, [cancel, reset]);

  const onSuccess = React.useCallback(() => {
    reset();
    onSuccessMessageShown();
  }, [onSuccessMessageShown, reset]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("deleteSmsTemplateDialog")}
      title="Delete SMS Template"
      loading={showLoadingIndicator}
      continueButtonLabel="Delete SMS Template"
      continueButtonDisabled={isEmptyString(templateId)}
      confirm={deleteSmsTemplate}
      cancel={cancelDialog}
      onClose={reset}
      onSuccessMessageShown={onSuccess}
    >
      <div className={classnames("deleteSmsTemplate", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Are you sure you want to delete this SMS template?
          </label>
          <label className={classnames("subtitle", classes.subtitle)}>
            <span className={classes.warning}>WARNING:</span>This action cannot be undone!
          </label>
          <label className={classnames("templateId", classes.templateId)}>
            {templateId}
          </label>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DeleteSmsTemplateDialog;

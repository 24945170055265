import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      paddingTop: 10,
    },
    noLine: {
      "& .MuiAccordion-root:before": {
        display: "none",
      },
    },
    summary: {
      width: "fit-content",
      minHeight: 64,
    },
    noPadding: {
      padding: 0,
    },
    label: {
      fontSize: 18,
      fontWeight: 300,
    },
    dropdown: {
      marginBottom: 25,
    },
    details: {
      flexDirection: "column",
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
      "& .MuiSelect-select": {
        paddingBottom: 12,
      }
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      lineHeight: "12px",
    },
    commands: {
      margin: 0,
      flex: "0 0 700px",
    },
    roles: {
      margin: 0,
      flex: "0 0 700px",
    },
    accessibleDns: {
      margin: 0,
      flex: "0 0 700px",
    },
    capabilities: {
      margin: 0,
      flex: "0 0 700px",
    },
    autocompleteContainer: {
      flex: "1 0 auto",
      marginTop: 25,
      marginBottom: 25,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    autocompleteOption: {
      "&[aria-disabled='true']": {
        opacity: "1.0",
      },
    },
    addIconButton: {
      marginLeft: 16,
      width: 24,
      height: 24,
      borderRadius: 32,
      padding: 0,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
      },
    },
    addIcon: {
      width: 16,
      height: 16,
    },
  });
import React from "react";
import { isEmptyString } from "@util";
import { CostReportingMetadata } from "@data";
import useCostReportingClient from "../use-cost-reporting-client";
import { GetCostReportingMetadataOptions, GetCostReportingMetadataResponse } from "@network";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps
} from "@hooks";

type SuccessResponse = GetCostReportingMetadataResponse;

export interface UseGetCostReportingMetadataProps extends
  Partial<UsePaginatedApiRequestProps<SuccessResponse>>, GetCostReportingMetadataOptions {
}

export interface UseGetCostReportingMetadataModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  metadata: CostReportingMetadata[];
}

export interface UseGetCostReportingMetadataActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetCostReportingMetadataProps;
type Model = UseGetCostReportingMetadataModel;
type Actions = UseGetCostReportingMetadataActions;
type Result = [Model, Actions];

export const useGetCostReportingMetadata = (props: Props): Result => {

  const {
    metadataGroupKey,
    defaultErrorMessage = "Failed to fetch metadata",
    ...otherProps
  } = props;

  const CostReportingClient = useCostReportingClient();

  const [metadata, setMetadata] = React.useState<CostReportingMetadata[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
    CostReportingClient.getCostReportingMetadata({
      metadataGroupKey,
      ...(isEmptyString(next) ? ({}) : ({ next })),
    }), [CostReportingClient, metadataGroupKey]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedMetadata = React.useMemo(() => {
    const { items = [] } = successResponse || {};
    return items.map(attrs => new CostReportingMetadata(attrs));
  }, [successResponse]);

  const loadedMetadata = React.useMemo(() => {
    return updatedMetadata.length && !metadata.length ? updatedMetadata : metadata;
  }, [updatedMetadata, metadata]);

  const reset = React.useCallback(() => {
    setMetadata([]);
    baseReset();
  }, [setMetadata, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    metadata: loadedMetadata,
  }), [
    baseModel,
    successResponse,
    loadedMetadata,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setMetadata(currentDataSets => currentDataSets.concat(updatedMetadata));
  }, [updatedMetadata, setMetadata]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetCostReportingMetadata;

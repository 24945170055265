import React from "react";
import { batchDetailsView as styles } from "./styles";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ContinueButton,
  SummaryViewActionItems,
  SummaryViewWithActions,
  SummaryViewWithActionsModel
} from "@components";

export interface Model extends SummaryViewWithActionsModel {
  summaryViewItems?: SummaryViewActionItems[];
  disableEdit?: boolean;
}

export interface Actions {
  onClickEdit?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const BatchDetailsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewItems,
    disableEdit = false,
    onClickEdit,
    children,
  } = props;

  return (
    <div className={classnames("workloadBatchDetailsView", classes.container)}>
      <div className={classnames("titleContainer", classes.titleContainer)}>
        <label className={classnames("title", classes.title)}>
          Batch Configuration Details
        </label>
        <ContinueButton
          className={classnames("editButton", classes.editButton)}
          color="primary"
          label="Edit Configuration"
          disabled={disableEdit}
          onClick={onClickEdit}
        />
      </div>
      <SummaryViewWithActions
        className={classnames("workloadBatchDetailsSummary", classes.summaryContainer)}
        items={summaryViewItems}
      />
      {children}
    </div>
  );

});

export default BatchDetailsView;
import React from "react";
import { User } from "@data";
import { getIdentityType } from "./selectors";
import { useHistory } from "react-router-dom";
import UserIdentityType from "@data/UserIdentityType";
import { useDispatch, useSelector } from "react-redux";
import { getPathToUser } from "@modules/userDetails/helpers";
import useCheckIfPhoneNumberUserSupportEnabled
  from "@hooks/use-check-if-phone-number-user-support-enabled";
import SelectUserIdentityType from "./components/SelectUserIdentityType";
import { setIdentityType, setIsFederatedUser, setIsPortalUser } from "./actions";
import CreateUser from "./containers/CreateUser";

export function CreateUserView() {

  const history = useHistory();

  const dispatch = useDispatch();

  const identityType = useSelector(getIdentityType);

  const phoneNumberUserSupportEnabled = useCheckIfPhoneNumberUserSupportEnabled();

  const [initialized, setInitialized] = React.useState(false);

  const showSelectUserIdentityType = React.useMemo(() =>
      phoneNumberUserSupportEnabled && identityType === UserIdentityType.NONE,
    [phoneNumberUserSupportEnabled, identityType]);

  const updateIdentityType = React.useCallback((value: UserIdentityType) => {
    dispatch(setIdentityType(value));
    if (value === UserIdentityType.PHONE) {
      dispatch(setIsPortalUser(false));
      dispatch(setIsFederatedUser(false));
    }
  }, [dispatch]);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const showUserDetails = React.useCallback((user: User) => {
    history.replace(getPathToUser(user.getUserId()));
  }, [history]);

  // When phone number user support is disabled, we need to make sure the redux store for this
  // module is updated to reflect that we intend to create an email user. We can't default the
  // reducer state to email because when phone number user support is enabled, the select user
  // identity type logic depends on it initially being UserIdentityType.NONE before showing
  // the create user wizard to ensure the user picks the type of user they intend to create.
  React.useEffect(() => {
    if (!initialized) {
      if (!phoneNumberUserSupportEnabled) {
        updateIdentityType(UserIdentityType.EMAIL);
      }
      setInitialized(true);
    }
  }, [initialized, phoneNumberUserSupportEnabled, updateIdentityType, setInitialized]);

  if (!initialized) {
    return null;
  }

  if (showSelectUserIdentityType) {
    return (
      <SelectUserIdentityType
        setIdentityType={updateIdentityType}
      />
    );
  }

  return (
    <CreateUser
      cancel={cancel}
      showUserDetails={showUserDetails}
    />
  );
}

export default CreateUserView;

import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { DropdownMenu } from "@components";
import { mapMinutesToSeconds, mapSecondsToMinutes } from "@modules/dataWorkloadWizard/components/AdvancedSettings";
import { useEditTimeout } from "@hooks";

export interface WorkloadTimeoutDialogModel extends PortalModuleDialogModel<string> {
  name?: string;
  version?: number;
  timeout: number;
  etag: string;
  dialogClassName?: string;
}

export interface WorkloadTimeoutDialogActions extends PortalModuleDialogActions<string> {
  setTimeout?: (timeout: string) => void;
}

type Props = WithStyles<typeof styles> & WorkloadTimeoutDialogModel & WorkloadTimeoutDialogActions;

export const WorkloadTimeoutDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    dialogClassName,
    name = "",
    version = 1,
    timeout,
    etag,
    setTimeout = noop,
    cancel: cancelDialog = noop,
    onSuccessMessageShown: onSuccess = noop,
    ...otherProps
  } = props;

  // Moving it here to fix IOTSST-3083
  if (isEmptyString(name)) {
    return null;
  }

  const [newTimeout, setNewTimeout] = React.useState<number>(timeout);

  const [{ showLoadingIndicator, ...otherModel }, { editTimeout, reset }] =
    useEditTimeout({ name, version, etag, timeout: newTimeout });

  const onClose = React.useCallback(() => setNewTimeout(timeout), [setNewTimeout, timeout]);

  const confirm = React.useCallback(() => {
    editTimeout();
  }, [editTimeout]);

  const cancel = React.useCallback(() => {
    reset();
    cancelDialog();
    onClose();
  }, [reset, cancelDialog, onClose]);

  const onSuccessMessageShown = React.useCallback(() => {
    reset();
    onSuccess();
    onClose();
  }, [reset, onSuccess, onClose]);

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      className={classnames("workloadTimeoutDialog", dialogClassName)}
      title="Edit Workload Timeout"
      loading={showLoadingIndicator}
      continueButtonLabel="Save"
      onSuccessMessageShown={onSuccessMessageShown}
      confirm={confirm}
      cancel={cancel}
    >
      <div className={classnames("workloadTimeoutContainer", classes.container)}>
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            Select the new timeout for this workload:
          </label>
          <div className={classnames("workloadTimeout", classes.timeoutSelection)}>
            <DropdownMenu
              className="timeout"
              selectedValue={mapSecondsToMinutes(newTimeout)}
              setSelectedValue={(value: string) => setNewTimeout(mapMinutesToSeconds(value))}
              values={["1 minute", "2 minutes", "5 minutes", "10 minutes", "15 minutes"]}
              hideEmptyValue={true}
              dropdownMenuHint="The maximum duration of a running instance"
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
            />
          </div>
        </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default WorkloadTimeoutDialog;

import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  isDataWorkloadsModuleEnabled,
  isQualityReportsEnabled,
  isCostReportsEnabled,
  isReleaseNotesEnabled,
} from "@main/selectors";
import { ReleaseNoteReleaseType, ReleaseStatus } from "@data";
import { useGetCostReportingGroupedMetrics, useListReleaseNotes } from "@hooks";
import WelcomePage, { WelcomePageActions, WelcomePageModel } from "../components/WelcomePage";

interface ContainerModel extends WelcomePageModel {
}

interface ContainerActions extends WelcomePageActions {
}

type Props = ContainerModel & ContainerActions;

const WelcomePageContainer = (props: Props) => {

  const { showWhatsNew, showCostReports } = props;

  const [
    {
      releaseNotes,
      errorMessage: whatsNewErrorMessage,
      showLoadingIndicator: whatsNewShowLoadingIndicator,
    },
    { refresh: whatsNewRefresh },
  ] = useListReleaseNotes({ deferRequest: !showWhatsNew });

  const whatsNewReleaseNotes = React.useMemo(() => releaseNotes
    .filter(releaseNote =>
      (releaseNote.getReleaseType() === ReleaseNoteReleaseType.MAJOR ||
        releaseNote.getReleaseType() === ReleaseNoteReleaseType.MINOR) &&
      (releaseNote.getReleaseStatus() === ReleaseStatus.COMPLETED))
    .slice(0, 3),
    [releaseNotes]);

  const [
    {
      groupedMetrics: costReportMetrics,
      showErrorView: costReportShowErrorView,
      showLoadingIndicator: costReportShowLoadingIndicator,
    },
    { refresh: costReportRefresh },
  ] = useGetCostReportingGroupedMetrics({
    deferRequest: !showCostReports,
    periodType: "MONTHLY",
    metricPartition: "",
    limit: 5,
  });  

  return (
    <WelcomePage
      {...props}
      {...(showWhatsNew && {
        whatsNewReleaseNotes,
        whatsNewErrorMessage,
        whatsNewShowLoadingIndicator,
        whatsNewRefresh,
      })}
      {...(showCostReports && {
        costReportMetrics,
        costReportShowErrorView,
        costReportShowLoadingIndicator,
        costReportRefresh,
      })}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showDataWorkloads: isDataWorkloadsModuleEnabled(state),
  showWhatsNew: isReleaseNotesEnabled(state),
  showCostReports: isCostReportsEnabled(state),
  showQualityReports: isQualityReportsEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePageContainer);

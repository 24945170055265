import React from "react";
import { useHistory } from "react-router-dom";
import UserIdentityType from "@data/UserIdentityType";
import { getPathToUser } from "@modules/userDetails/helpers";
import useCheckIfPhoneNumberUserSupportEnabled
  from "@hooks/use-check-if-phone-number-user-support-enabled";
import SelectUserIdentityType from "./components/SelectUserIdentityType";
import InviteUser from "./containers/InviteUser";

export function InviteUserView() {

  const history = useHistory();

  const phoneNumberUserSupportEnabled = useCheckIfPhoneNumberUserSupportEnabled();

  const [initialized, setInitialized] = React.useState(false);

  const [identityType, setIdentityType] = React.useState<UserIdentityType>(UserIdentityType.NONE);

  const showSelectUserIdentityType = React.useMemo(() =>
      phoneNumberUserSupportEnabled && identityType === UserIdentityType.NONE,
    [phoneNumberUserSupportEnabled, identityType]);

  const showUserDetails = React.useCallback((userId: string) => {
    history.replace(getPathToUser(userId));
  }, [history]);

  React.useEffect(() => {
    if (!initialized) {
      if (!phoneNumberUserSupportEnabled) {
        setIdentityType(UserIdentityType.EMAIL);
      }
      setInitialized(true);
    }
  }, [initialized, phoneNumberUserSupportEnabled, setIdentityType, setInitialized]);

  if (!initialized) {
    return null;
  }

  if (showSelectUserIdentityType) {
    return (
      <SelectUserIdentityType
        setIdentityType={setIdentityType}
      />
    );
  }

  return (
    <InviteUser
      identityType={identityType}
      showUserDetails={showUserDetails}
    />
  );
}

export default InviteUserView;

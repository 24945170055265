export enum vCPU {
  ONE = "1.0",
  TWO = "2.0",
  FOUR = "4.0",
  EIGHT = "8.0",
  SIXTEEN = "16.0",
}

export const mapNumberToVCpu = (vCpu: number) => {
  switch (vCpu) {
    case 1:
      return vCPU.ONE;
    case 2:
      return vCPU.TWO;
    case 4:
      return vCPU.FOUR;
    case 8:
      return vCPU.EIGHT;
    case 16:
      return vCPU.SIXTEEN;
    default:
      return vCPU.ONE;
  }
};

export const mapVCpuToNumber = (vCpu: vCPU) => {
  switch (vCpu) {
    case vCPU.ONE:
      return 1;
    case vCPU.TWO:
      return 2;
    case vCPU.FOUR:
      return 4;
    case vCPU.EIGHT:
      return 8;
    case vCPU.SIXTEEN:
      return 16;
    default:
      return 1;
  }
};

export const mapVCpuToAvailableMemory = (vCpu: number) => {
  switch (vCpu) {
    case 1:
      return ["2048", "3072", "4096", "5120", "6144", "7168", "8192"];
    case 2:
      return ["4096", "5120", "6144", "7168", "8192", "9216", "10240", "11264", "12288", "13312", "14336", "15360", "16384"];
    case 4:
      return ["8192", "9216", "10240", "11264", "12288", "13312", "14336", "15360", "16384", "17408", "18432", "19456", "20480", "21504", "22528",
        "23552", "24576", "25600", "26624", "27648", "28672", "29696", "30720"];
    case 8:
      return ["16384", "20480", "24576", "28672", "32768", "36864", "40960", "45056", "49152", "53248",
        "57344", "61440"];
    case 16:
      return ["32768", "40960", "49512", "57344", "65536", "73728", "81920", "90112", "98304", "106496", "114688", "122880"];
    default:
      return ["2048"];
  }
};

export const mapMinutesToSeconds = (minutes: string) => parseInt(minutes.split(" ")[0], 10) * 60;

export const mapSecondsToMinutes = (seconds: number) => {
  const min = seconds / 60;
  return min > 1 ? `${min} minutes` : `${min} minute`;
};
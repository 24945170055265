import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useUpdateContainerRepositoryCredentials } from "@hooks";
import { SimpleCredentials } from "@data";
import { WizardTextField } from "@components";

export enum ContainerRepositoryOperation {
  EDIT_USERNAME = "Edit Username",
  EDIT_PASSWORD = "Edit Password",
}

export interface UpdateContainerRepositoryDialogModel extends PortalModuleDialogModel<string> {
  alias?: string;
  username: string;
  operation: ContainerRepositoryOperation;
}

export interface UpdateContainerRepositoryDialogActions extends PortalModuleDialogActions<string> {
  onSuccess?: () => void;
  cancel?: () => void;
}

type Props = WithStyles<typeof styles> & UpdateContainerRepositoryDialogModel & UpdateContainerRepositoryDialogActions;

export const UpdateContainerRepositoryDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    alias = "",
    username  = "",
    operation = ContainerRepositoryOperation.EDIT_USERNAME,
    onSuccess = noop,
    cancel = noop,
    ...otherProps
  } = props;

  const isEditUsername = React.useMemo(() => operation === ContainerRepositoryOperation.EDIT_USERNAME, [operation]);
  const isEditPassword = React.useMemo(() => operation === ContainerRepositoryOperation.EDIT_PASSWORD, [operation]);

  const [newCredentials, setNewCredentials] = React.useState<SimpleCredentials>({username: isEditUsername ? username : "", password: ""});

  const [model, { refresh: updateRepo }] =
    useUpdateContainerRepositoryCredentials({
      id: alias,
      credentials: newCredentials,
    });

  const validField = React.useMemo(() => {
    switch (operation) {
      case ContainerRepositoryOperation.EDIT_USERNAME:
        return !isEmptyString(newCredentials.username);
      case ContainerRepositoryOperation.EDIT_PASSWORD:
        return !isEmptyString(newCredentials.password);
      default:
        return true;
    }
  }, [operation, newCredentials]);

  if (isEmptyString(alias)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      className={classnames("updateContainerRepositoryDialog")}
      title="Update Container Repository"
      continueButtonLabel="Update"
      continueButtonDisabled={!validField}
      confirm={updateRepo}
      onSuccessMessageShown={onSuccess}
      cancel={cancel}
    >
      <div className={classnames("updateContainerRepository", classes.container)}>
          <React.Fragment>
            <label className={classnames("title", classes.title)}>
              Enter the new credentials for the container repository
            </label>
            {isEditUsername && (
              <WizardTextField
                className="username"
                label="Username"
                value={newCredentials.username}
                setValue={(newUser) => setNewCredentials({...newCredentials, username: newUser })}
              />
            )}
            {isEditPassword && (
              <WizardTextField
                className="password"
                label="Password"
                value={newCredentials.password}
                setValue={(password) => setNewCredentials({...newCredentials, password })}
              />
            )}
          </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default UpdateContainerRepositoryDialog;

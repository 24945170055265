import { Record } from "immutable";
import { getStringValue } from "@util";

export interface CostReportingMetadataAttributes {
  metadataGroupKey: string;
  metadataKey: string;
  metadataValue: string;
}

export class CostReportingMetadata extends Record({
  metadataGroupKey: "",
  metadataKey: "",
  metadataValue: "",
}) implements CostReportingMetadataAttributes {

  public static EMPTY: CostReportingMetadata = new CostReportingMetadata();

  public readonly metadataGroupKey: string;
  public readonly metadataKey: string;
  public readonly metadataValue: string;

  public getMetadataGroupKey(): string {
    return getStringValue(this.metadataGroupKey);
  }

  public getMetadataKey(): string {
    return getStringValue(this.metadataKey);
  }

  public getMetadataValue(): string {
    return getStringValue(this.metadataValue);
  }

}

export default CostReportingMetadata;

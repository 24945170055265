import { useApiRequestAnalytics } from "@hooks";
import {
  CostReportingClient,
  GetCostReportingMetadataOptions,
  GetCostReportingMetadataResponse,
  GetCostReportingMetricsResponse,
  GetCostReportingMetricsOptions,
  GetCostReportingGroupedMetricsOptions,
  GetCostReportingGroupedMetricsResponse
} from "@network";

export type GetCostReportingMetadataApi =
  (options?: GetCostReportingMetadataOptions) => Promise<GetCostReportingMetadataResponse>;

export type GetCostReportingMetricsApi =
  (options?: GetCostReportingMetricsOptions) => Promise<GetCostReportingMetricsResponse>;

export type GetCostReportingGroupedMetricsApi =
  (options?: GetCostReportingGroupedMetricsOptions) => Promise<GetCostReportingGroupedMetricsResponse>;

export interface CostReportingClientApi {
  getCostReportingMetadata: GetCostReportingMetadataApi;
  getCostReportingMetrics: GetCostReportingMetricsApi;
  getCostReportingGroupedMetrics: GetCostReportingGroupedMetricsApi;
}

enum ActionTypes {
  GET_COST_REPORTING_METADATA_REQUEST = "cost_reports_client/get_cost_reporting_metadata_request",
  GET_COST_REPORTING_METADATA_SUCCESS = "cost_reports_client/get_cost_reporting_metadata_success",
  GET_COST_REPORTING_METADATA_FAILED = "cost_reports_client/get_cost_reporting_metadata_failed",

  GET_COST_REPORTING_METRICS_REQUEST = "cost_reports_client/get_cost_reporting_metrics_request",
  GET_COST_REPORTING_METRICS_SUCCESS = "cost_reports_client/get_cost_reporting_metrics_success",
  GET_COST_REPORTING_METRICS_FAILED = "cost_reports_client/get_cost_reporting_metrics_failed",

  GET_COST_REPORTING_GROUPED_METRICS_REQUEST = "cost_reports_client/get_cost_reporting_grouped_metrics_request",
  GET_COST_REPORTING_GROUPED_METRICS_SUCCESS = "cost_reports_client/get_cost_reporting_grouped_metrics_success",
  GET_COST_REPORTING_GROUPED_METRICS_FAILED = "cost_reports_client/get_cost_reporting_grouped_metrics_failed",
}

export const useGetCostReportingMetadataApi = () =>
  useApiRequestAnalytics(CostReportingClient.getCostReportingMetadata, {
    REQUEST_EVENT: ActionTypes.GET_COST_REPORTING_METADATA_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_COST_REPORTING_METADATA_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_COST_REPORTING_METADATA_FAILED,
  });

export const useGetCostReportingMetricsApi = () =>
  useApiRequestAnalytics(CostReportingClient.getCostReportingMetrics, {
    REQUEST_EVENT: ActionTypes.GET_COST_REPORTING_METRICS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_COST_REPORTING_METRICS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_COST_REPORTING_METRICS_FAILED,
  });

export const useGetCostReportingGroupedMetricsApi = () =>
  useApiRequestAnalytics(CostReportingClient.getCostReportingGroupedMetrics, {
    REQUEST_EVENT: ActionTypes.GET_COST_REPORTING_GROUPED_METRICS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_COST_REPORTING_GROUPED_METRICS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_COST_REPORTING_GROUPED_METRICS_FAILED,
  });

export const useCostReportingClient = (): CostReportingClientApi => ({
  getCostReportingMetadata: useGetCostReportingMetadataApi(),
  getCostReportingMetrics: useGetCostReportingMetricsApi(),
  getCostReportingGroupedMetrics: useGetCostReportingGroupedMetricsApi(),
});

export { ActionTypes as CostReportingActionType };

export default useCostReportingClient;

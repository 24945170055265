import {
  getDataWorkloadsModuleWhitelistedAccounts,
  isDataWorkloadsContainerRepositoriesEnabled,
  isDataWorkloadsModuleAccountWhitelistingEnabled,
  isDataWorkloadsModuleEnabled,
} from "@util";

export const CONTAINER_REPOSITORY_REQUIREMENTS = {
  disabled: !(isDataWorkloadsModuleEnabled() && isDataWorkloadsContainerRepositoriesEnabled()),
  ...(!isDataWorkloadsModuleAccountWhitelistingEnabled() ? ({}) : ({
    whitelistedAccounts: getDataWorkloadsModuleWhitelistedAccounts(),
  })),
};

export default CONTAINER_REPOSITORY_REQUIREMENTS;

import { isEmptyString } from "@util";
import { AdminNoteAttributes } from "@data";
import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export interface GetAdminNotesResponse {
  notes: AdminNoteAttributes[];
  paging?: {
    next?: string
  };
}

export const createAdminNote = (authToken?: string,
                                userId?: string,
                                json?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Note", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/notes`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to add admin note";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getAdminNotes = (authToken?: string,
                              userId?: string,
                              next: string = ""): Promise<GetAdminNotesResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({ next });

    const url = REGIONAL_API +
      `/security/identity/v1/users/${userId}/administration/notes${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to fetch admin notes";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

import { Record } from "immutable";
import { getStringValue } from "@util";

export interface BatchWorkloadInternetConfig {
  accessibleDns: string[];
}

export enum CpuArchitecture {
  ARM64 = "ARM64",
  X86_64 = "X86_64",
}

export enum BatchWorkloadCapabilities {
  INTERNET_ACCESS = "INTERNET_ACCESS",
  IOT_PLATFORM_HTTP_API_ACCESS = "IOT_PLATFORM_HTTP_API_ACCESS",
}

export interface RuntimePlatform {
  cpuArchitecture: CpuArchitecture;
  memory: number;
  vCpu: number;
}

export interface InternetProps {
  accessibleDnsNames: string[];
}

export interface NetworkingProps {
  internet: InternetProps;
}

export interface SecurityProperties {
  capabilities?: BatchWorkloadCapabilities[];
  networking?: NetworkingProps;
}

export interface WorkloadBatchPropertiesAttributes {
  command?: string[];
  containerImageTag: string;
  repositoryAlias: string;
  roles?: string[];
  runtimePlatform: RuntimePlatform;
  timeout?: number;
  workloadInternetConfig?: BatchWorkloadInternetConfig;
  security?: SecurityProperties;
}

export class WorkloadBatchProperties extends Record({
  command: [],
  containerImageTag: "",
  repositoryAlias: "",
  roles: [],
  runtimePlatform: {
    cpuArchitecture: CpuArchitecture.X86_64,
    memory: 2048,
    vCpu: 1,
  },
  timeout: 60,
  security: {
    capabilities: [],
    networking: {
      internet: {
        accessibleDnsNames: [],
      },
    },
  },
}) implements WorkloadBatchPropertiesAttributes {

  public static EMPTY: WorkloadBatchProperties = new WorkloadBatchProperties();

  public readonly command: string[];
  public readonly containerImageTag: string;
  public readonly repositoryAlias: string;
  public readonly roles: string[];
  public readonly runtimePlatform: RuntimePlatform;
  public readonly timeout: number;
  public readonly workloadInternetConfig: BatchWorkloadInternetConfig;
  public readonly security: SecurityProperties;

  public getContainerImageTag(): string {
    return getStringValue(this.containerImageTag);
  }

  public getRepositoryAlias(): string {
    return getStringValue(this.repositoryAlias);
  }

  public getBatchWorkloadRoles(): string[] {
    return Array.isArray(this.roles) ? this.roles : [] ;
  }

  public getBatchWorkloadRolesAsString(): string {
    const roles = this.getBatchWorkloadRoles();
    return roles.join(" , ");
  }

  public getRuntimePlatform(): RuntimePlatform {
    return this.runtimePlatform || {};
  }

  public getTimeoutForBatchWorkload(): number {
    return this.timeout || 0;
  }

  public getWorkloadInternetConfigForBatchWorkload(): BatchWorkloadInternetConfig {
    return this.workloadInternetConfig || { accessibleDns: [] };
  }

  public getCommand(): string[] {
    return Array.isArray(this.command) ? this.command : [] ;
  }

  public getCpuArchitecture(): CpuArchitecture {
    return this.getRuntimePlatform().cpuArchitecture;
  }

  public getMemoryForBatchWorkload(): number {
    return this.getRuntimePlatform().memory;
  }

  public getVCpu(): number {
    return this.getRuntimePlatform().vCpu;
  }

  public getCommandAsString(): string {
    const command = this.getCommand();
    return command.join(" , ");
  }

  public getBatchWorkloadSecurity(): SecurityProperties {
    return this.security || {};
  }

  public getBatchWorkloadCapabilities(): BatchWorkloadCapabilities[] {
    const { capabilities = [] } = this.security || {};
    return Array.isArray(capabilities) ? capabilities : [] ;
  }

  public getCapabilitiesAsString(): string {
    const capabilities = this.getBatchWorkloadCapabilities();
    return capabilities.join(" , ");
  }

  public getNetworkingProps(): NetworkingProps {
    const { networking = { internet : { accessibleDnsNames: [] } } } = this.security || {};
    return networking;
  }

  public getAccessibleDnsNamesForBatch(): string[] {
    return this.getNetworkingProps().internet.accessibleDnsNames || [];
  }

  public getAccessibleDnsNamesForBatchAsString(): string {
    const accessibleDnsNames = this.getAccessibleDnsNamesForBatch();
    return accessibleDnsNames.join(" , ");
  }
}

export default WorkloadBatchProperties;

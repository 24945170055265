import React from "react";
import classnames from "classnames";
import { formEventHandler, isEmptyString, noop } from "@util";
import { editUserProfile as styles } from "./styles";
import {
  DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES,
  UserProfileAttributeLanguage,
  UserProfileAttribute,
} from "@data";
import {
  mapLanguageToOptionName,
  TextFieldStyled,
  UserProfileAttributeListItem,
  UserProfileAttributeListItemProps,
} from "@components";
import UserProfileAttributesListColumn from "./UserProfileAttributesListColumn";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface EditUserProfileAttributeListItem extends UserProfileAttributeListItemProps {
  readOnlyValues?: string[];
  languageMenuItems?: React.ReactNode[];
  setValue?: (value: string) => void;
}

type Props = WithStyles<typeof styles> & EditUserProfileAttributeListItem;

export const EditUserProfileAttributeListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    readOnlyValues = DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES,
    languageMenuItems = Object.values(UserProfileAttributeLanguage)
      .map((value: UserProfileAttributeLanguage) => {
        const label = mapLanguageToOptionName(value);
        return isEmptyString(label) ? null : (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        );
      })
      .filter(menuItem => menuItem !== null),
    setValue = noop,
    ...otherProps
  } = props;

  const { column, attribute = UserProfileAttribute.EMPTY } = otherProps;

  if (UserProfileAttributesListColumn.VALUE === column) {

    const name = attribute.getName();

    if (name === "language") {
      return (
        <FormControl className={classnames("formControl", classes.select, name)}>
          <Select
            value={attribute.getValueAsString()}
            onChange={formEventHandler(setValue)}
          >
            {languageMenuItems}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextFieldStyled
        className={classnames("profileAttributeTextField", name)}
        name={name}
        disabled={readOnlyValues.indexOf(name) >= 0}
        value={attribute.getValueAsString()}
        setValue={setValue}
      />
    );
  }

  return <UserProfileAttributeListItem {...otherProps} />;
});

export default EditUserProfileAttributeListItem;

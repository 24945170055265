import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum RegistryType {
  GENERIC = "GENERIC",
  ECR = "ECR",
}

export interface ContainerRepositorySecurityAttributes {
  authentication: {
    simpleCredentials: SimpleCredentials;
  };
}

export interface SimpleCredentials {
  username?: string;
  password?: string;
}

export interface ContainerRepositoryAttributes {
  description?: string;
  type: RegistryType;
  alias: string;
  url: string;
  security?: ContainerRepositorySecurityAttributes;
}

export class ContainerRepository extends Record({
  description: "",
  type: RegistryType.ECR,
  alias: "",
  url: "",
  security: {
    authentication: {
      simpleCredentials: {
        username: "",
        password: "",
      },
    },
  }
}) implements ContainerRepositoryAttributes {

  public static EMPTY: ContainerRepository = new ContainerRepository();

  public readonly description: string;
  public readonly type: RegistryType;
  public readonly alias: string;
  public readonly url: string;
  public readonly security: ContainerRepositorySecurityAttributes;

  public getAlias(): string {
    return getStringValue(this.alias);
  }

  public getUrl(): string {
    return getStringValue(this.url);
  }

  public getType(): RegistryType {
    return this.type;
  }

  public isGeneric(): boolean {
    return this.type === RegistryType.GENERIC;
  }

  public getDescription(): string {
    return this.description;
  }

  public getCredentials(): SimpleCredentials {
    return this.security.authentication.simpleCredentials;
  }

  public getUsername(): string {
    return getStringValue(this.security.authentication.simpleCredentials.username);
  }

  public getPassword(): string {
    return this.security.authentication.simpleCredentials.password ?? "";
  }

  public hasUsernameAndPassword(): boolean {
    return !isEmptyString(this.getUsername()) && !isEmptyString(this.getPassword());
  }
}

export default ContainerRepository;

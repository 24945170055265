import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useUpdateContainerRepositoryDescription } from "@hooks";
import { WizardTextField } from "@components";

export interface UpdateContainerRepositoryDescriptionDialogModel extends PortalModuleDialogModel<string> {
  alias?: string;
  description: string;
}

export interface UpdateContainerRepositoryDescriptionDialogActions extends PortalModuleDialogActions<string> {
  onSuccess?: () => void;
  cancel?: () => void;
}

type Props = WithStyles<typeof styles> & UpdateContainerRepositoryDescriptionDialogModel
  & UpdateContainerRepositoryDescriptionDialogActions;

export const UpdateContainerRepositoryDescriptionDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    alias = "",
    description  = "",
    onSuccess = noop,
    cancel = noop,
    ...otherProps
  } = props;

  const [newDescription, setNewDescription] = React.useState(description);

  const [model, { updateDescription }] =
    useUpdateContainerRepositoryDescription({
      id: alias,
      description: newDescription,
    });

  const validField = React.useMemo(() => {
    return !isEmptyString(newDescription);
  }, [newDescription]);

  if (isEmptyString(alias)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      className={classnames("updateContainerRepositoryDescriptionDialog")}
      title="Update Container Repository"
      continueButtonLabel="Update"
      continueButtonDisabled={!validField}
      confirm={updateDescription}
      onSuccessMessageShown={onSuccess}
      cancel={cancel}
    >
      <div className={classnames("updateContainerRepositoryDesc", classes.container)}>
          <React.Fragment>
            <label className={classnames("title", classes.title)}>
              Enter the new description for the container repository
            </label>
            <WizardTextField
              className="description"
              label="Description"
              value={newDescription}
              setValue={(newDesc) => setNewDescription(newDesc)}
            />
          </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default UpdateContainerRepositoryDescriptionDialog;

import { Module } from "@data";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH, RouteParams } from "./constants";
import { PrimaryIcon as icon } from "@modules/smsTemplates/styles";
import SMS_TEMPLATES_REQUIREMENTS from "@modules/smsTemplates/requirements";
import view from "./SmsTemplateDetailsView";

export const smsTemplateDetailsModule: Module = createModule<RouteParams>({
  ...SMS_TEMPLATES_REQUIREMENTS,
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: "SMS Template Details",
  title: "SMS Template Details",
  className: "smsTemplateDetailsModule",
  icon,
  view,
});

export default smsTemplateDetailsModule;

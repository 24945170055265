import { Module } from "@data";
import { createModule } from "@base/createModule";
import { PrimaryIcon as icon } from "@modules/containerRepositories/styles";
import { MODULE_ID, MODULE_TITLE, MODULE_PATH } from "./constants";
import view from "./ContainerRepositoryDetailsView";
import CONTAINER_REPOSITORY_REQUIREMENTS from "@modules/containerRepositories/requirements";

export const containerRepositoryDetailsModule: Module = createModule({
  ...CONTAINER_REPOSITORY_REQUIREMENTS,
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: MODULE_TITLE,
  title: MODULE_TITLE,
  className: "containerRepositoryDetailsModule",
  icon,
  view,
});

export default containerRepositoryDetailsModule;

import React from "react";
import { ListRepositoriesResponse } from "@network";
import useContainerRepositoryClient from "../use-container-repository-client";
import usePaginatedApiRequest, {
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks/use-paginated-api-request";
import { ContainerRepository } from "@data";

type SuccessResponse = ListRepositoriesResponse;

export interface UseListRepositoriesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
}

export interface UseListRepositoriesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  repositories: ContainerRepository[];
}

export interface UseListRepositoriesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseListRepositoriesProps;
type Model = UseListRepositoriesModel;
type Actions = UseListRepositoriesActions;
type Result = [Model, Actions];

export const useListRepositories = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to list container repositories",
    ...otherProps
  } = props;

  const ContainerRepositoryManagerClient = useContainerRepositoryClient();

  const [repositories, setRepositories] =
    React.useState<ContainerRepository[]>([]);

  const makeApiRequest = React.useCallback(() => {
    return ContainerRepositoryManagerClient.listContainerRepositories();
  }, [ContainerRepositoryManagerClient]);

  const [{ successResponse, ...baseModel }, {
    reset: baseReset,
    refresh: baseRefresh,
    ...baseActions
  }] =
    usePaginatedApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const updatedRepositories = React.useMemo<ContainerRepository[]>(() => {
    const { containerRepositories = [] } = successResponse ? successResponse : {};
    return containerRepositories.map(attrs => new ContainerRepository(attrs));
  }, [successResponse]);

  const reset = React.useCallback(() => {
    setRepositories([]);
    baseReset();
  }, [setRepositories, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  React.useEffect(() => {
    refresh();
  }, [
    refresh,
  ]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    repositories,
  }), [
    baseModel,
    repositories,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setRepositories(currentRepositories => currentRepositories.concat(updatedRepositories));
  }, [setRepositories, updatedRepositories]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useListRepositories;

import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@main/schemas";
import { AuthTokenContext } from "@components";
import { RestClientError, UserIdmLegacyClient } from "@network";
import {
  DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES,
  UpdateUserProfileRequest,
  UserProfileAttribute,
  UserProfileAttributesMap,
} from "@data";
import { getUserId, isCurrentUserViewActive } from "../selectors";
import {
  Actions,
  Model,
  SaveProfileAttributesDialog,
} from "../components/SaveProfileAttributesDialog";
import { isEmptyString } from "@util";

interface ContainerModel extends Model {
  userId?: string;
  selfAuthorized?: boolean;
  attributes?: UserProfileAttributesMap;
  readOnlyValues?: string[];
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SaveProfileAttributesDialogContainer = (props: Props) => {

  const {
    userId,
    selfAuthorized,
    attributes = {},
    readOnlyValues = DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES,
    ...otherProps
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);

  const updatedAttributes: UserProfileAttributesMap = React.useMemo(() =>
    // Remove readonly attributes and attributes with empty string
    Object.keys(attributes)
      .filter(key => readOnlyValues.indexOf(key) === -1)
      .filter(key => !isEmptyString(attributes[key].getValue()))
      .map(key => attributes[key])
      .reduce((combined: UserProfileAttributesMap, attribute: UserProfileAttribute) => {
        combined[attribute.getName()] = new UserProfileAttribute({
          name: attribute.getName(),
          value: attribute.getValue(),
          dataType: attribute.getDataType(),
          required: attribute.isRequired(),
        });
        return combined;
      }, {}), [attributes]);

  const json = React.useMemo(() =>
    UpdateUserProfileRequest.from(updatedAttributes).toJson(),
    [updatedAttributes]);

  const updateUserProfile = React.useCallback(() =>
      UserIdmLegacyClient.updateUserProfile(authToken, userId, json, selfAuthorized),
    [authToken, userId, json, selfAuthorized]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      setErrorMessage("");

      updateUserProfile()
        .then(() => {
          if (!ignore) {
            setShowLoadingIndicator(false);
            setSuccessMessage("User Profile Updated");
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { error = "Update user profile failed" } = response;
            setErrorMessage(error);
            setShowLoadingIndicator(false);
          }
        });
    }

    return () => { ignore = true; };

  }, [
    showLoadingIndicator,
    setErrorMessage,
    updateUserProfile,
    setShowLoadingIndicator,
    setSuccessMessage,
  ]);

  return (
    <SaveProfileAttributesDialog
      {...otherProps}
      open={true}
      errorMessage={errorMessage}
      successMessage={successMessage}
      loading={showLoadingIndicator}
      confirm={() => setShowLoadingIndicator(true)}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getUserId(state),
  selfAuthorized: isCurrentUserViewActive(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  ...ownProps,
});

export default connect<Model, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SaveProfileAttributesDialogContainer);

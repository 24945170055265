import { Module } from "@data";
import { createListViewModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import SMS_TEMPLATES_REQUIREMENTS from "./requirements";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./SmsTemplatesView";

export const smsTemplatesModule: Module = createListViewModule({
  ...SMS_TEMPLATES_REQUIREMENTS,
  id: MODULE_ID,
  path: MODULE_PATH,
  name: "SMS Templates",
  title: "SMS Template Management",
  className: "smsTemplatesModule",
  primaryColor,
  icon,
  view,
});

export default smsTemplatesModule;

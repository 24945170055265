import React from "react";
import useContainerRepositoryClient from "../use-container-repository-client";
import { ContainerRepository } from "@data";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks/use-api-request";

type SuccessResponse = ContainerRepository;

export interface UseGetRepositoryProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  repoId: string;
}

export interface UseGetRepositoryModel extends UseApiRequestModel<SuccessResponse> {
  repository: ContainerRepository;
}

export interface UseGetRepositoryActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetRepositoryProps;
type Model = UseGetRepositoryModel;
type Actions = UseGetRepositoryActions;
type Result = [Model, Actions];

export const useGetRepository = (props: Props): Result => {

  const {
    repoId,
    defaultErrorMessage = "Failed to get container repository",
    ...otherProps
  } = props;

  const ContainerRepositoryManagerClient = useContainerRepositoryClient();

  const makeApiRequest = React.useCallback(() => {
    return ContainerRepositoryManagerClient.getContainerRepository(repoId);
  }, [ContainerRepositoryManagerClient, repoId]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const repository = React.useMemo<ContainerRepository>(() => {
    const attrs = successResponse ? successResponse : {};
    return new ContainerRepository(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    repository,
  }), [
    baseModel,
    repository,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetRepository;

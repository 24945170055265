import React from "react";
import classnames from "classnames";
import { WorkloadState } from "@data";
import Typography from "@material-ui/core/Typography";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
import ActionsMenu, { ActionMenuItem } from "@components/actions-menu";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { Button } from "@components";
import CircularProgress from "@components/circular-progress";

export interface WorkloadStateHeaderModel {
  className?: string;
  title?: string;
  state?: WorkloadState;
  actions?: ActionMenuItem[];
  showLoadingIndicator?: boolean;
  testWorkloadEnabled?: boolean;
  testButtonLoading?: boolean;
}

export interface WorkloadStateHeaderActions {
  onClickAction?: (action: ActionMenuItem) => void;
  testWorkload?: () => void;
}

type Model = WorkloadStateHeaderModel;
type Actions = WorkloadStateHeaderActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadStateHeader = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Workload State",
    state = WorkloadState.NONE,
    actions = [],
    showLoadingIndicator,
    testWorkloadEnabled = false,
    testButtonLoading = false,
    onClickAction,
    testWorkload,
    children,
  } = props;

  const releaseInProgress = React.useMemo(() => state === WorkloadState.RELEASING, [state]);

  const releaseFailed = React.useMemo(() =>
    state === WorkloadState.RELEASE_FAILED, [state]);

  const releaseStateLabel = React.useMemo(() => {
    if (releaseInProgress) {
      return WorkloadState.RELEASING;
    } else if (releaseFailed) {
      return WorkloadState.RELEASE_FAILED;
    } else {
      return WorkloadState.RELEASED;
    }
  }, [releaseInProgress, releaseFailed]);

  const decommissionInProgress = React.useMemo(() => state === WorkloadState.DECOMMISSIONING, [state]);

  const decommissionFailed = React.useMemo(() =>
    state === WorkloadState.DECOMMISSION_FAILED, [state]);

  const decommissionStateLabel = React.useMemo(() => {
    if (decommissionInProgress) {
      return WorkloadState.DECOMMISSIONING;
    } else if (decommissionFailed) {
      return WorkloadState.DECOMMISSION_FAILED;
    } else {
      return WorkloadState.DECOMMISSIONED;
    }
  }, [decommissionInProgress, decommissionFailed]);

  if (showLoadingIndicator) {
    return null;
  }

  return (
    <div className={classnames("workloadStateHeader", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
        <ActionsMenu
          className={classnames("actionsMenu", classes.actionsMenu)}
          buttonVariant="icon"
          actions={actions}
          onClickAction={onClickAction}
        />
        <Button
          className={classnames("testButton", classes.testButton)}
          variant="contained"
          disabled={!testWorkloadEnabled}
          onClick={testWorkload}
        >
          Test Workload
          {testButtonLoading && (
            <CircularProgress
              size={20}
              className={classnames("progressIndicator", classes.progressIndicator)}
            />
          )}
        </Button>
      </div>
      <div className={classnames("content", classes.content)}>

        <div className={classnames("draft", "card", classes.card, classes.draft)}>
          <div
            className={classnames("cardState", classes.cardState, {
              [classes.active]: state === WorkloadState.DRAFT || state === WorkloadState.DEPLOYING
                || state === WorkloadState.DEPLOYMENT_FAILED,
            })}
          >
            <label className={classnames("cardStateLabel", classes.cardStateLabel)}>
              {WorkloadState.DRAFT}
            </label>
          </div>
          <label className={classnames("cardFooterLabel", classes.cardFooterLabel)}>
            A workload version in DRAFT state can be modified and will not collect/execute
            events until promoted to RELEASED.
          </label>
        </div>

        <ArrowIcon className={classnames("arrowIcon", classes.arrowIcon)} />

        <div className={classnames("released", "card", classes.card, classes.released)}>
          <div
            className={classnames("cardState", classes.cardState, {
              [classes.active]: state === WorkloadState.RELEASED,
              [classes.inProgress]: releaseInProgress,
              [classes.failed]: releaseFailed,
            })}
          >
            <label className={classnames("cardStateLabel", classes.cardStateLabel)}>
              {releaseStateLabel}
            </label>
          </div>
          <label className={classnames("cardFooterLabel", classes.cardFooterLabel)}>
            {!releaseInProgress && (
              <>
                A workload version in RELEASED state executes new events as they accumulate
                and cannot be modified.
              </>
            )}
            {releaseInProgress && (
              <>
                RELEASING indicates workload infrastructure is being setup and status
                will change to RELEASED once complete. While workload is in RELEASING state,
                it cannot be modified and will not collect/execute events until it is fully RELEASED.
              </>
            )}
          </label>
        </div>

        <ArrowIcon className={classnames("arrowIcon", classes.arrowIcon)} />

        <div className={classnames("deprecated", "card", classes.card, classes.deprecated)}>
          <div
            className={classnames("cardState", classes.cardState, {
              [classes.active]: state === WorkloadState.DEPRECATED,
            })}
          >
            <label className={classnames("cardStateLabel", classes.cardStateLabel)}>
              {WorkloadState.DEPRECATED}
            </label>
          </div>
          <label className={classnames("cardFooterLabel", classes.cardFooterLabel)}>
            A workload version in DEPRECATED state continues to collect/accumulate triggered
            events, but does not run them.
          </label>
        </div>

        <ArrowIcon className={classnames("arrowIcon", classes.arrowIcon)} />

        <div className={classnames("decommissioned", "card", classes.card, classes.decommissioned)}>
          <div
            className={classnames("cardState", classes.cardState, {
              [classes.active]: state === WorkloadState.DECOMMISSIONED,
              [classes.inProgress]: decommissionInProgress,
              [classes.failed]: decommissionFailed,
            })}
          >
            <label className={classnames("cardStateLabel", classes.cardStateLabel)}>
              {decommissionStateLabel}
            </label>
          </div>
          <label className={classnames("cardFooterLabel", classes.cardFooterLabel)}>
            A workload version in DECOMMISSIONED state neither runs new events nor stores
            events for that version anymore.
          </label>
        </div>

      </div>

      {children}
    </div>
  );
});

export default WorkloadStateHeader;

import { isEmptyString, isValidInteger } from "@util";
import {
  ReleasedWorkloadIdentityAttributes,
  TriggerAttributes,
  UpdateTriggerRequest,
  WorkloadAttributes,
  WorkloadCodePackagingStatus,
  WorkloadInstanceAttributes,
  WorkloadInstanceState,
  WorkloadLogInfoAttributes,
  WorkloadQueryType,
  WorkloadSummaryAttributes,
} from "@data";
import {
  createQueryParams,
  getEtagResponseHeader,
  getResponseHeaderValue,
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";

const DATA_PROCESSING_API = process.env.REACT_APP_DATA_PROCESSING_API || "";
const MAX_GET_INSTANCE_LOG_LIMIT = 500;
const DEFAULT_GET_INSTANCE_LOG_LIMIT = 100;
const DEFAULT_GET_INSTANCES_LIMIT = 100;
const DEFAULT_GET_WORKLOAD_LIMIT = 50;
const DEFAULT_GET_WORKLOAD_VERSIONS_LIMIT = 20;

export interface ListWorkloadSummariesResponse {
  items?: WorkloadSummaryAttributes[];
  paging: {
    next?: string;
  };
}

export interface ListReleasedWorkloadsResponse {
  items?: ReleasedWorkloadIdentityAttributes[];
  paging: {
    next?: string;
  };
}

export interface GetAllVersionsOfWorkloadResponse {
  versions: WorkloadAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetWorkloadCodePackagingStatusResponse {
  packagingStatus: WorkloadCodePackagingStatus;
  errorMessage?: string;
}

export interface UploadWorkloadCodeUrl {
  uploadUrl: string;
}

export interface GetWorkloadInstancesResponse {
  executions: WorkloadInstanceAttributes[];
  paging: {
    next?: string;
  };
}

export type SearchInstancesParams = {
  limit?: number;
  startDate?: string;
  endDate?: string;
  stateFilter?: WorkloadInstanceState;
};

export interface GetWorkloadInstanceLogsResponse extends WorkloadLogInfoAttributes {
  paging: {
    next?: string;
  };
}

export type SearchInstanceLogParams = {
  limit?: number;
  startDate?: string;
  endDate?: string;
  logLevel?: string;
  messageContains?: string;
};

export interface CreateWorkloadResponse {
  name: string;
  version: number;
}

export interface GetWorkloadCodeUrl {
  downloadURL?: string;
  fileName?: string;
}

export interface GetWorkloadCodeHeadResponse {
  etag?: string;
  lastModified?: string;
  contentLength: string;
  originalFilename?: string;
}

export interface GetWorkloadTriggersResponse {
  eventBindings: TriggerAttributes[];
  name: string;
  version: number;
}

export interface UpdateWorkloadTriggerResponse {
  eventBindingId: string;
}

export interface AddTriggerResponse {
  eventSourceId: string;
}

export interface ManuallyExecuteWorkloadResponse {
  executionId: string;
}

export interface WorkloadConfigurationProperties {
  [key: string]: string;
}

export interface GetWorkloadConfigurationResponse {
  properties: WorkloadConfigurationProperties;
  etag: string;
  lastModified: string;
}

export interface ValidateWorkloadNameResponse {
  isValid: boolean;
  message: string;
}

export interface EditWorkloadQueryPropertiesPayload {
  overwrite?: boolean;
  query?: string;
  queryType?: WorkloadQueryType;
  tableName?: string;
}

export const getWorkloadCode = (authToken: string, workloadName: string, version: number)
  : Promise<GetWorkloadCodeUrl> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", workloadName ],
      [ "Workload Version", version > 0 ? `${version}` : "" ],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/name/${workloadName}/version/${version}/code`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = "Fetch workload code download URL failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getWorkloadCodeHead = (authToken: string,
                                    name: string,
                                    version: number): Promise<GetWorkloadCodeHeadResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/code`;

    const settings = {
      method: "HEAD",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload code head failed";

    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(response => {

        const etag = getEtagResponseHeader(response);
        const lastModified = getResponseHeaderValue(response, "last-modified");
        const contentLength = getResponseHeaderValue(response, "file-content-length");
        const originalFilename = getResponseHeaderValue(response, "original-filename");

        return Promise.resolve({ etag, lastModified, contentLength, originalFilename });
      });
  };

  return validate().then(makeRequest);
};

export const getWorkloadCodePackagingStatus = (authToken: string, workloadName: string, version: number)
  : Promise<GetWorkloadCodePackagingStatusResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", workloadName ],
      [ "Workload Version", version > 0 ? `${version}` : "" ],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/name/${workloadName}/version/${version}/code/packaging-status`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = "Fetch workload code packaging status failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage).then(response => {
      if (typeof response === "string") {
        return {
          packagingStatus: response,
        };
      } else {
        return response;
      }
    });
  };

  return validate().then(makeRequest);
};

export const getWorkload = (authToken: string,
                            name: string,
                            version: number | "latest"): Promise<WorkloadAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getWorkloads = (authToken: string,
                             nameFilter: string = "",
                             next: string = "",
                             stateFilter: string = "",
                             limit: number = DEFAULT_GET_WORKLOAD_LIMIT):
  Promise<ListWorkloadSummariesResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_GET_WORKLOAD_LIMIT, limit)) }),
    ...(!isEmptyString(nameFilter) ? ({ nameFilter }) : ({})),
    ...(!isEmptyString(stateFilter) ? ({ stateFilter }) : ({})),
    next,
  });

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workloads failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getReleasedWorkloads = (authToken: string,
                                     nameFilter: string = "",
                                     releasedByNameFilter: string = "",
                                     next: string = "",
                                     limit: number = DEFAULT_GET_WORKLOAD_LIMIT):
                                     Promise<ListReleasedWorkloadsResponse> => {

  const validate = () => withAuthToken(authToken);

  const queryParams = createQueryParams({
    ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_GET_WORKLOAD_LIMIT, limit)) }),
    ...(!isEmptyString(nameFilter) ? ({ nameFilter }) : ({})),
    ...(!isEmptyString(releasedByNameFilter) ? ({ releasedByNameFilter }) : ({})),
    next,
  });

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/state/released${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch released workloads failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getAllVersionsOfWorkload =
  (authToken: string,
   name: string,
   next: string = "",
   limit: number = DEFAULT_GET_WORKLOAD_VERSIONS_LIMIT): Promise<GetAllVersionsOfWorkloadResponse> => {

    const validate = () => withAuthToken(authToken)
      .then(() => withRequiredArguments([
        [ "Workload Name", name ],
      ]));

    const queryParams = createQueryParams({
      next,
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(DEFAULT_GET_WORKLOAD_VERSIONS_LIMIT, limit)) }),
    });

    const makeRequest = () => {

      const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/name/${name}/${queryParams}`;

      const settings = {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${authToken}`,
          "Accept": "application/json",
        },
      };

      const defaultErrorMessage = "Fetch workloads failed";

      return makeJsonApiRequest(url, settings, defaultErrorMessage);
    };

    return validate().then(makeRequest);
  };

export const getWorkloadInstances = (authToken: string,
                                     name: string,
                                     version: number,
                                     next: string = "",
                                     searchParams?: SearchInstancesParams): Promise<GetWorkloadInstancesResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const limit = searchParams?.limit ? searchParams.limit : DEFAULT_GET_INSTANCES_LIMIT;
    const instanceState = searchParams?.stateFilter ?
      (searchParams.stateFilter !== WorkloadInstanceState.NONE ? searchParams.stateFilter : "") : "";
    const queryParams = searchParams ? createQueryParams({
      next,
      ...searchParams,
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, limit) }),
      stateFilter: instanceState,
    }) : "";

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/executions/${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload execution instances failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getWorkloadInstance = (authToken: string,
                                    name: string,
                                    version: number,
                                    executionId: string): Promise<WorkloadInstanceAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Execution ID", executionId ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/executions/${executionId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload instance failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getWorkloadInstanceLogs = (authToken: string,
                                        name: string,
                                        version: number,
                                        executionId: string,
                                        next?: string,
                                        searchParams?: SearchInstanceLogParams):
  Promise<GetWorkloadInstanceLogsResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Execution ID", executionId ],
    ]));

  const makeRequest = () => {

    const limit = searchParams?.limit ? searchParams.limit : DEFAULT_GET_INSTANCE_LOG_LIMIT;
    const queryParams = createQueryParams({
      next,
      ...searchParams,
      ...(!isValidInteger(limit) ? {} : { limit: Math.max(1, Math.min(MAX_GET_INSTANCE_LOG_LIMIT, limit)) }),
    });

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/executions/${executionId}/logs${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload instance logs failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteWorkloadApi = (authToken: string, name: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/name/${name}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete workload ${name}`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const copyCode = (authToken: string,
                         name: string,
                         version: number,
                         sourceName: string,
                         sourceVersion: number): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Destination Workload Name", name],
      ["Destination Workload Version", `${version}`],
      ["Source Workload Name", sourceName],
      ["Source Workload Version", `${sourceVersion}`],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/name/${sourceName}/version/${sourceVersion}/code/copy`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        "destinationName": name,
        "destinationVersion": version
      }),
    };

    const defaultErrorMessage = "Failed to copy workload code";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const uploadCode = (authToken: string,
                           uploadUrl: string,
                           file: File): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Upload URL", uploadUrl ],
    ]));

  const makeRequest = () => {

    const settings = {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };

    const defaultErrorMessage = "Failed to upload code to workload";

    return makeApiRequestAndComplete(uploadUrl, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUploadCodeUrl = (authToken: string,
                                 name: string,
                                 version: number,
                                 fileName: string): Promise<UploadWorkloadCodeUrl> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", version > 0 ? `${version}` : "" ],
      [ "Code File Name", fileName ],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/name/${name}/version/${version}/code?fileName=${fileName}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = "Failed to get URL to upload code to the workload";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createWorkload = (authToken: string,
                               json: string): Promise<CreateWorkloadResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Attributes", json ],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to create workload";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateWorkloadProperties = (authToken: string,
                                         name: string,
                                         version: number,
                                         etag: string,
                                         properties: any): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Workload ETag", etag ],
      [ "Property", properties ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "If-Match": etag,
      },
      body: properties,
    };

    const defaultErrorMessage = "Failed to edit workload property";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);

};

export const editWorkloadQueryProperties = (authToken: string,
                                            name: string,
                                            version: number,
                                            etag: string,
                                            payload: EditWorkloadQueryPropertiesPayload = {}): Promise<void> => {

  const { overwrite, query, queryType, tableName } = payload;

  const body = {
    ...(overwrite !== undefined ? { overwrite } : {}),
    ...(query ? { query } : {}),
    ...(queryType ? { queryType } : {}),
    ...(tableName ? { tableName } : {}),
  };

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Workload ETag", etag ],
      [ "Workload Properties", Object.keys(body).join(",") ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}`;

    const settings = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "If-Match": etag,
      },
      body: JSON.stringify({
        queryProperties: body,
      }),
    };

    const defaultErrorMessage = "Failed to edit query workload properties";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getWorkloadTriggers = (authToken: string,
                                    name: string,
                                    version: number): Promise<GetWorkloadTriggersResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/event-bindings/initiation/criteria`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload triggers failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateWorkloadTrigger = (authToken: string,
                                    name: string,
                                    version: number,
                                    body: UpdateTriggerRequest): Promise<UpdateWorkloadTriggerResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/event-bindings/initiation/criteria`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(body),
    };

    const defaultErrorMessage = "Update workload trigger failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const removeWorkloadTrigger = (authToken: string,
                                      name: string,
                                      version: number,
                                      eventBindings: string[]): Promise<void> => {

  const eventSourceJson = JSON.stringify({ eventBindings });

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Event Sources", eventSourceJson ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/event-bindings/initiation/criteria`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: eventSourceJson,
    };

    const defaultErrorMessage = "Failed to remove trigger(s) for the workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const setWorkloadServiceSecret = (authToken: string,
                                         name: string,
                                         version: number,
                                         accountId: string,
                                         principal: string,
                                         secret: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Account ID", accountId ],
      [ "Principal", principal ],
      [ "Secret", secret ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/secrets/workloads/name/${name}/version/${version}` +
      `/accounts/${accountId}/principals/${principal}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: secret,
    };

    const defaultErrorMessage = "Failed to set secret for the service principal for the workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getWorkloadConfiguration = (authToken: string,
                                         name: string,
                                         version: number): Promise<GetWorkloadConfigurationResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/configuration-properties`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch workload configuration failed";

    // TODO: Is this still true?
    // API returns a 404 error if configurations is not added. Hence, handling it with empty object
    return makeApiRequest(url, settings, defaultErrorMessage)
      .then(response => {
        const etag = getEtagResponseHeader(response);
        const lastModified = getResponseHeaderValue(response, "last-modified");
        return response.json()
          .then(attrs => ({
            ...attrs,
            etag,
            lastModified,
          }));
      })
      .catch(error => {

        const { status = 404 } = error || {};

        if (status === 404) {
          return Promise.resolve({
            properties: {},
            etag: "",
            lastModified: "",
          });
        } else {
          return Promise.reject(error);
        }
      });
  };

  return validate().then(makeRequest);
};

export const updateWorkloadConfiguration = (authToken: string,
                                            name: string,
                                            version: number,
                                            properties: WorkloadConfigurationProperties): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/configuration-properties`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(properties),
    };

    const defaultErrorMessage = "Update workload configuration failed";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateWorkloadConfigurationByKey = (authToken: string,
                                                 name: string,
                                                 version: number,
                                                 key: string,
                                                 value: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Configuration Key", key ],
      [ "Configuration Value", value ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/configuration-properties/${key}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ value }),
    };

    const defaultErrorMessage = `Update workload configuration for ${key} failed`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteWorkloadConfigurationKeys = (authToken: string,
                                                name: string,
                                                version: number,
                                                keys: string[]): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Configuration Keys", keys.join("") ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/configuration-properties`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(keys),
    };

    const defaultErrorMessage = "Delete workload configuration keys failed";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteWorkloadConfigurationByKey = (authToken: string,
                                                 name: string,
                                                 version: number,
                                                 key: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Configuration Key", key ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/configuration-properties/${key}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Delete workload configuration for ${key} failed`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const addWorkloadVariables = (
  authToken: string,
  name: string,
  version: number,
  etag: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Workload Name", name],
      ["Workload Version", `${version}`],
      ["ETag", etag],
      ["Variable Schema", json],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/completion/variables`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to add completion variables for workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editWorkloadVariable = (
  authToken: string,
  name: string,
  version: number,
  variable: string,
  etag: string,
  json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Workload Name", name],
      ["Workload Version", `${version}`],
      ["Variable Name", variable],
      ["ETag", etag],
      ["Variable Schema", json],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/completion/variables/${variable}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "If-Match": etag,
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to edit completion variable";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteWorkloadVariable = (authToken: string,
                                       name: string,
                                       version: number,
                                       etag: string,
                                       variable: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Workload Name", name],
      ["Workload Version", `${version}`],
      ["ETag", etag],
      ["Variable", variable],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/completion/variables/${variable}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage = "Failed to delete completion variable for workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const addWorkloadTrigger = (authToken: string,
                                   name: string,
                                   version: number,
                                   json: string): Promise<AddTriggerResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "Trigger Criteria", json ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/initiation/criteria`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to add trigger for workload";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const manuallyExecuteWorkload = (authToken: string,
                                        name: string,
                                        version: number,
                                        body: string): Promise<ManuallyExecuteWorkloadResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", version > 0 ? `${version}` : "" ],
      [ "Workload Trigger Event", body ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/initiation`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body,
    };

    const defaultErrorMessage = "Failed to manually execute workload";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const cancelWorkloadExecution = (authToken: string,
                                      name: string,
                                      version: number,
                                      executionIds: string[]): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Workload Name", name],
      ["Workload Version", `${version}`],
      ["Execution IDs", executionIds.join(",")],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/executions/cancel`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({ executionIds }),
    };

    const defaultErrorMessage = "Failed to cancel workload execution(s)";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const releaseWorkload = (authToken: string,
                                name: string,
                                version: number): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/state/released`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to release workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deprecateWorkload = (authToken: string,
                                name: string,
                                version: number): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/state/deprecated`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to deprecate workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const decommissionWorkload = (authToken: string,
                                     name: string,
                                     version: number): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/state/decommissioned`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to decommission workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const draftNewWorkloadVersion = (authToken: string,
                                        name: string,
                                        version: number | "latest"): Promise<CreateWorkloadResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to draft new version of workload";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteWorkloadVersion = (authToken: string,
                                      name: string,
                                      version: number): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Workload Name", name],
      ["Workload Version", `${version}`],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to delete version of workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const switchPrincipal = (authToken: string,
                                name: string,
                                version: number,
                                accountId: string,
                                email: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Workload Name", name],
      ["Workload Version", `${version}`],
      ["Account ID", accountId],
      ["Release Principal", email],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}/state/released/release-principal`;
    const releasePrincipal = `lrn:iot:aam::${accountId}:principal:user:${email}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({ releasePrincipal }),
    };

    const defaultErrorMessage = "Failed to switch principal of workload";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const addWorkloadActionLabels = (authToken: string,
                                        name: string,
                                        version: number,
                                        etag: string,
                                        actions: string[]): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "ETag", etag ],
      [ "Actions", actions.join("") ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/completion/actions`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "If-Match": etag,
      },
      body: JSON.stringify({ actions }),
    };

    const defaultErrorMessage = `Update action labels failed`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteWorkloadActionLabel = (authToken: string,
                                        name: string,
                                        version: number,
                                        etag: string,
                                        action: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
      [ "Workload Version", `${version}` ],
      [ "ETag", etag ],
      [ "Action", action ],
    ]));

  const makeRequest = () => {

    const url = DATA_PROCESSING_API +
      `/data/processing/v1/workloads/name/${name}/version/${version}` +
      `/event-bindings/completion/actions/${encodeURIComponent(action)}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "If-Match": etag,
      },
    };

    const defaultErrorMessage = `Delete action label failed`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const validateWorkloadName = (authToken: string,
                                     name: string): Promise<ValidateWorkloadNameResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Workload Name", name ],
    ]));

  const makeRequest = () => {

    const url = `${DATA_PROCESSING_API}/data/processing/v1/workloads/validations`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    };

    const defaultErrorMessage = "Failed to validate workload name";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then(response => {
        const { nameValidationResult } = response;
        return {
          isValid: nameValidationResult.isValid,
          message: nameValidationResult.message
        } as ValidateWorkloadNameResponse;
      });
  };

  return validate().then(makeRequest);
};

import React from "react";
import { isEmptyString } from "@util";
import { WaitForApiRequestView } from "@components";
import CostReports, { CostReportsActions, CostReportsModel } from "../components/CostReports";
import {
  useGetCostReportingGroupedMetrics,
  useGetCostReportingMetrics,
  useGetCostReportingMetadata
} from "@hooks/costReporting";

interface ContainerModel extends CostReportsModel {
}

interface ContainerActions extends CostReportsActions {
}

type Model = ContainerModel;
type Actions = ContainerActions;
type Props = Model & Actions;

const CostReportsContainer = (props: Props) => {

  const [showFilters, setShowFilters] = React.useState(true);
  const [graphUnit, setGraphUnit] = React.useState("COST");
  const [periodType, setPeriodType] = React.useState("MONTHLY");
  const [metricPartition, setMetricPartition] = React.useState("DOMAIN_TYPE");
  const [resourceType, setResourceType] = React.useState("");
  const [domainType, setDomainType] = React.useState("");
  const [groupType, setGroupType] = React.useState("");
  const [categoryType, setCategoryType] = React.useState("");
  const [metricType, setMetricType] = React.useState("");

  const [{
    metadata,
    showLoadingIndicator: loadingMetadata,
    errorMessage: metadataErrorMessage,
    showErrorView: showMetadataErrorView,
    showAccessDenied: showMetadataAccessDenied,
  }, { refresh: refreshMetadata }] = useGetCostReportingMetadata({});

  const [metricModel, metricActions] = useGetCostReportingMetrics({
    periodType,
    resourceType,
    domainType,
    groupType,
    categoryType,
    metricType
  });

  const {
    errorMessage: metricsErrorMessage,
    showErrorView: showMetricsErrorView,
    showAccessDenied: showMetricsAccessDenied,
  } = metricModel;

  const { refresh: refreshMetrics } = metricActions;

  const [{
    groupedMetrics,
    showLoadingIndicator: loadingGroupedMetrics,
    errorMessage: groupedMetricsErrorMessage,
    showErrorView: showGroupedMetricsErrorView,
    showAccessDenied: showGroupedMetricsAccessDenied,
  }, { refresh: refreshGroupedMetrics }
  ] = useGetCostReportingGroupedMetrics({
    periodType,
    metricPartition,
    resourceType,
    domainType,
    groupType,
    categoryType,
    metricType
  });

  const refresh = React.useCallback(() => {
    refreshMetadata();
    refreshMetrics();
    refreshGroupedMetrics();
  }, [refreshMetadata, refreshMetrics, refreshGroupedMetrics]);

  const errorMessage = React.useMemo(() =>
    metadataErrorMessage || metricsErrorMessage || groupedMetricsErrorMessage,
    [metadataErrorMessage, metricsErrorMessage, groupedMetricsErrorMessage]);

  const showAccessDenied = React.useMemo(() =>
    showMetadataAccessDenied || showMetricsAccessDenied || showGroupedMetricsAccessDenied,
    [showMetadataAccessDenied, showMetricsAccessDenied, showGroupedMetricsAccessDenied]);

  const showErrorView = React.useMemo(() =>
    showMetadataErrorView || showMetricsErrorView || showGroupedMetricsErrorView,
    [showMetadataErrorView, showMetricsErrorView, showGroupedMetricsErrorView]);

  React.useEffect(() => {
    if (isEmptyString(resourceType)) {
      setGraphUnit("COST");
    }
    refreshMetrics();
    refreshGroupedMetrics();
  }, [
    setGraphUnit,
    refreshMetrics,
    refreshGroupedMetrics,
    periodType,
    metricPartition,
    resourceType,
    domainType,
    groupType,
    categoryType,
    metricType
  ]);

  return (
    <WaitForApiRequestView
      errorMessage={errorMessage}
      showErrorView={showErrorView}
      showAccessDenied={showAccessDenied}
      showLoadingIndicator={loadingMetadata}
      loadingMessage="Loading Cost Reports..."
      errorTitle="Failed to Load Cost Reports"
      retry={refresh}
    >
      <CostReports
        showFilters={showFilters}
        graphUnit={graphUnit}
        periodType={periodType}
        resourceType={resourceType}
        domainType={domainType}
        groupType={groupType}
        categoryType={categoryType}
        metricType={metricType}
        metricPartition={metricPartition}
        metricModel={metricModel}
        metricActions={metricActions}
        groupedMetrics={groupedMetrics}
        loadingGroupedMetrics={loadingGroupedMetrics}
        metadata={metadata}
        loadingMetadata={loadingMetadata}
        setShowFilters={setShowFilters}
        setGraphUnit={setGraphUnit}
        setPeriodType={setPeriodType}
        setResourceType={setResourceType}
        setMetricPartition={setMetricPartition}
        setDomainType={setDomainType}
        setGroupType={setGroupType}
        setCategoryType={setCategoryType}
        setMetricType={setMetricType}
      />
    </WaitForApiRequestView>
  );
};

export default CostReportsContainer;

import { MODULE_PATH as BASE_URL } from "@modules/smsTemplates/constants";
import { getPathToSmsTemplate } from "@modules/smsTemplateDetails/helpers";

export const getCreateSmsTemplatePath = () =>  `${BASE_URL}/create`;
export const getEditSmsTemplatePath = (id: string) => `${getPathToSmsTemplate(id)}/edit`;

export enum SmsTemplateWizardStep {
  NONE = "",
  TEMPLATE = "template",
  APPLICATION = "application",
  BODY = "body",
  REVIEW = "review",
}

export const DEFAULT_STEPS = [
  SmsTemplateWizardStep.TEMPLATE,
  SmsTemplateWizardStep.APPLICATION,
  SmsTemplateWizardStep.BODY,
  SmsTemplateWizardStep.REVIEW,
];

export const DEFAULT_STEP_LABELS = {
  [SmsTemplateWizardStep.TEMPLATE]: "SMS Template Details",
  [SmsTemplateWizardStep.APPLICATION]: "Application ID (optional)",
  [SmsTemplateWizardStep.BODY]: "SMS Template Body",
  [SmsTemplateWizardStep.REVIEW]: "Finish",
};

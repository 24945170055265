import { Record } from "immutable";

export enum RegionalTemplateMessageTypeStatus {
  NONE = "",
  DEPRECATED = "DEPRECATED",
}

export interface RegionalTemplateMessageTypeAttrs {
  description: string;
  requiredParameters: string[];
  status?: RegionalTemplateMessageTypeStatus;
}

export class RegionalTemplateMessageType extends Record({
  description: "",
  requiredParameters: [],
  status: RegionalTemplateMessageTypeStatus.NONE,
}) implements  RegionalTemplateMessageTypeAttrs {

  public static EMPTY: RegionalTemplateMessageType = new RegionalTemplateMessageType();

  public readonly description: string;
  public readonly requiredParameters: string[];
  public readonly status: RegionalTemplateMessageTypeStatus;

}

export default RegionalTemplateMessageType;

import React from "react";
import Color from "@Color";
import styles from "./styles";
import * as echarts from "echarts";
import classnames from "classnames";
import { Button } from "@components";
import { isEmptyString, noop } from "@util";
import CostHeaderCard from "./CostHeaderCard";
import { formatMetricDate } from "../helpers";
import { CostReportsActions, CostReportsModel } from "./CostReports";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

type EChartsOption = echarts.EChartOption;

const createSeriesBarChartOption = (attrs: Partial<echarts.EChartOption.SeriesBar> = {}) => ({
  ...attrs,
  type: "bar",
  stack: "total",
  label: {
    show: true,
    formatter: ({ value }: { value: number }) => (value !== 0 ? value : ""),
  },
  emphasis: {
    focus: "series"
  },
});

const createChartOptions = (yAxisLabel: string, xAxis: string[], series: EChartsOption[]): EChartsOption => ({
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow"
    }
  },
  legend: {},
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true
  },
  yAxis: {
    type: "value",
    name: yAxisLabel,
  },
  xAxis: {
    type: "category",
    data: xAxis
  },
  series
});

export interface GroupedMetricGraphModel extends CostReportsModel {
}

export interface GroupedMetricGraphActions extends CostReportsActions {
  mapMetadataKeyToLabel?: (key: string) => string;
}

type Model = GroupedMetricGraphModel;
type Actions = GroupedMetricGraphActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const GroupedMetricGraph = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showFilters,
    graphUnit,
    periodType = "",
    resourceType = "",
    metricPartition = "",
    groupedMetrics = [],
    loadingGroupedMetrics = false,
    setShowFilters = noop,
    mapMetadataKeyToLabel = () => "",
    children,
  } = props;

  const xAxis: string[] = React.useMemo(() => {

    switch (periodType) {
      case "YEARLY":
        return Array.from({ length: 5 }, (_, i) => {
          const date = new Date();
          date.setFullYear(date.getFullYear() - i);
          return formatMetricDate(periodType, date.toISOString());
        }).reverse();

      case "MONTHLY":
        return Array.from({ length: 12 }, (_, i) => {
          const date = new Date();
          date.setMonth(date.getMonth() - i);
          return formatMetricDate(periodType, date.toISOString());
        }).reverse();

      case "DAILY":
        return Array.from({ length: 31 }, (_, i) => {
          const date = new Date();
          date.setDate(date.getDate() - i);
          return formatMetricDate(periodType, date.toISOString());
        }).reverse();

      default:
        return [""];
    }
  }, [periodType]);

  const yAxisLabel: string = React.useMemo(() =>
    mapMetadataKeyToLabel(graphUnit === "COUNT" ? resourceType : "COST"),
    [graphUnit, resourceType, mapMetadataKeyToLabel]);

  const infoLabel: string = React.useMemo(() => {
    return loadingGroupedMetrics || !xAxis.length ? "Loading metric results..." :
      `Displaying metric results from ${xAxis[0]} through ${xAxis[xAxis.length - 1]}`;
  }, [loadingGroupedMetrics, periodType, xAxis]);

  const totalCost: number = React.useMemo(() => {
    return groupedMetrics.reduce((acc, metric) => acc + metric.costValue, 0);
  }, [groupedMetrics]);

  const totalCount: number = React.useMemo(() => {
    return groupedMetrics.reduce((acc, metric) => acc + metric.countValue, 0);
  }, [groupedMetrics]);

  const numUniqueGroupNames: number = React.useMemo(() => {
    return (new Set(groupedMetrics.map(metric => metric.name))).size;
  }, [groupedMetrics]);

  const avgCost: number = React.useMemo(() => {
    const avg = totalCost / xAxis.length;
    return isNaN(avg) ? 0 : avg;
  }, [totalCost, xAxis]);

  const avgCount: number = React.useMemo(() => {
    const avg = totalCount / xAxis.length;
    return isNaN(avg) ? 0 : avg;
  }, [totalCount, xAxis]);

  const series: EChartsOption[] = React.useMemo(() => {
    const dataValues: { [key: string]: number[] } = {};

    groupedMetrics.forEach(({ name, date, costValue, countValue }) => {
      if (!dataValues[name]) {
        dataValues[name] = Array(xAxis.length).fill(0);
      }
      const index = xAxis.indexOf(formatMetricDate(periodType, date));
      if (index !== -1) {
        dataValues[name][index] = graphUnit === "COST" ? costValue : countValue;
      }
    });

    return Object.entries(dataValues).map(([name, data]) =>
      createSeriesBarChartOption({ name: mapMetadataKeyToLabel(name), data }) as EChartsOption);
  }, [groupedMetrics, periodType, xAxis, graphUnit, mapMetadataKeyToLabel]);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (ref && ref.current) {
      echarts.init(ref.current);
    }
  }, [ref]);

  React.useEffect(() => {
    if (ref && ref.current) {
      const chart = echarts.getInstanceByDom(ref.current);
      if (chart) {
        chart.setOption(createChartOptions(yAxisLabel, xAxis, series));
        if (loadingGroupedMetrics) {
          chart.clear();
          chart.showLoading("default", { text: "Loading metrics...", color: Color.SIGNIFY_GREEN });
        } else {
          if (groupedMetrics.length === 0) {
            chart.showLoading("default", { text: "No cost reporting metrics found", showSpinner: false });
          } else {
            chart.hideLoading();
          }
        }
      }
    }
  }, [ref, xAxis, yAxisLabel, groupedMetrics, loadingGroupedMetrics]);

  const handleResize = React.useCallback(() => {
    if (ref && ref.current) {
      const chart = echarts.getInstanceByDom(ref.current);
      if (chart) {
        chart.resize({ width: "auto", height: "auto" });
      }
    }
  }, [ref]);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    handleResize();
  }, [showFilters, handleResize]);

  const onClickShowFilters = React.useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters, setShowFilters]);

  return (
    <React.Fragment>
      <div className={classnames("headers", classes.headers)}>
        <CostHeaderCard
          title={isEmptyString(resourceType) ? "Total Cost" : `Total ${resourceType} Cost`}
          subtitle={`$${totalCost.toFixed(2)}`}
          showLoadingIndicator={loadingGroupedMetrics}
        />
        {!isEmptyString(resourceType) && (
          <CostHeaderCard
            title={`Total ${resourceType}`}
            subtitle={`${totalCount.toFixed(2)}`}
            showLoadingIndicator={loadingGroupedMetrics}
          />
        )}
        {!isEmptyString(periodType) && (
          <CostHeaderCard
            title={isEmptyString(resourceType) ? `${periodType} Cost` : `${periodType} ${resourceType} Cost`}
            subtitle={`$${avgCost.toFixed(2)}`}
            showLoadingIndicator={loadingGroupedMetrics}
          />
        )}
        {!isEmptyString(periodType) && !isEmptyString(resourceType) && (
          <CostHeaderCard
            title={`${periodType} ${resourceType}`}
            subtitle={`${avgCount.toFixed(2)}`}
            showLoadingIndicator={loadingGroupedMetrics}
          />
        )}
        {!isEmptyString(metricPartition) && (
          <CostHeaderCard
            title={`${metricPartition}s`}
            subtitle={`${numUniqueGroupNames}`}
            showLoadingIndicator={loadingGroupedMetrics}
          />
        )}
      </div>
      <div className={classnames("infoLabelContainer", classes.infoLabelContainer)}>
        <div className={classnames("infoLabel", classes.infoLabel)}>{infoLabel}</div>
        <Button
          className={classnames("filterButton", classes.filterButton)}
          onClick={onClickShowFilters}
        >
          {`${showFilters ? "HIDE" : "SHOW"} FILTERS`}
        </Button>
      </div>
      <div className={classnames("groupedMetricGraph", className, classes.container)}>
        <div ref={ref} className={classnames("chart", classes.chart)} />
        {children}
      </div>
    </React.Fragment>
  );
});

export default GroupedMetricGraph;

import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSmsTemplateClient,
} from "@hooks";
import { ValidateSmsTemplateRequest } from "@data";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";

type SuccessResponse = void;

export interface UseValidateSmsTemplateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  templateBody: ValidateSmsTemplateRequest;
}

export interface UseValidateSmsTemplateModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseValidateSmsTemplateActions extends UseApiRequestActions<SuccessResponse> {
  validateSmsTemplate: () => void;
}

type Props = UseValidateSmsTemplateProps;
type Model = UseValidateSmsTemplateModel;
type Actions = UseValidateSmsTemplateActions;
type Result = [Model, Actions];

export const useValidateSmsTemplate = (props: Props): Result => {

  const {
    templateBody,
    defaultErrorMessage = "Failed to validate SMS template",
    ...otherProps
  } = props;

  const SmsTemplateClient = useSmsTemplateClient();

  const pendingJson = React.useMemo(() => JSON.stringify(templateBody), [templateBody]);
  const [json, setJson] = React.useState(pendingJson);

  const makeApiRequest = React.useCallback(() => {
    if (isEmptyString(templateBody.getTextBody())) {
      return Promise.reject({
        error: "SMS template cannot be validated without a valid message type, description, and message body",
        status: 400,
        message: "Bad Request",
        description: "You must provide a valid request body",
      });
    }
    return SmsTemplateClient.validateSmsTemplate(json);
    }, [SmsTemplateClient, templateBody, json]);

  const [{successResponse, showSuccessView, ...baseModel}, { refresh, ...baseActions} ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "SMS template validated" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    validateSmsTemplate: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  // Debounced validate SMS template request
  React.useEffect(() => {

    if (!equalsIgnoreCase(json, pendingJson)) {
      const timer = setTimeout(() => {
        setJson(pendingJson);
      }, 1000);

      return () => clearTimeout(timer);
    }

    return noop;

  }, [json, pendingJson, setJson]);

  // Re-validate whenever the SMS template request body changes
  React.useEffect(() => {
    refresh();
  }, [json, refresh]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useValidateSmsTemplate;

import { useApiRequestAnalytics } from "@hooks";
import { SmsTemplateAttributes } from "@data";
import {
  CreateSmsTemplateResponse,
  GetSmsTemplateLanguagesResponse,
  GetSmsTemplateParametersResponse,
  SmsTemplateClient,
} from "@network";

export type GetSmsTemplatesApi = () => Promise<SmsTemplateAttributes[]>;
export type GetSmsTemplateApi = (templateId: string) => Promise<SmsTemplateAttributes>;
export type CreateSmsTemplateApi = (template: string) => Promise<CreateSmsTemplateResponse>;
export type EditSmsTemplateApi = (templateId: string, template: string) => Promise<CreateSmsTemplateResponse>;
export type DeleteSmsTemplateApi = (templateId: string) => Promise<void>;
export type ValidateSmsTemplateApi = (templateBody: string) => Promise<void>;
export type GetSmsTemplateLanguagesApi = () => Promise<GetSmsTemplateLanguagesResponse>;
export type GetSmsTemplateParametersApi = () => Promise<GetSmsTemplateParametersResponse>;

export interface SmsTemplateClientApi {
  getSmsTemplates: GetSmsTemplatesApi;
  getSmsTemplate: GetSmsTemplateApi;
  createSmsTemplate: CreateSmsTemplateApi;
  editSmsTemplate: EditSmsTemplateApi;
  deleteSmsTemplate: DeleteSmsTemplateApi;
  validateSmsTemplate: ValidateSmsTemplateApi;
  getSmsTemplateLanguages: GetSmsTemplateLanguagesApi;
  getSmsTemplateParameters: GetSmsTemplateParametersApi;
}

enum ActionTypes {
  GET_SMS_TEMPLATES_REQUEST = "sms_template_client/get_sms_templates_request",
  GET_SMS_TEMPLATES_SUCCESS = "sms_template_client/get_sms_templates_success",
  GET_SMS_TEMPLATES_FAILED = "sms_template_client/get_sms_templates_failed",

  GET_SMS_TEMPLATE_REQUEST = "sms_template_client/get_sms_template_request",
  GET_SMS_TEMPLATE_SUCCESS = "sms_template_client/get_sms_template_success",
  GET_SMS_TEMPLATE_FAILED = "sms_template_client/get_sms_template_failed",

  CREATE_SMS_TEMPLATE_REQUEST = "sms_template_client/create_sms_template_request",
  CREATE_SMS_TEMPLATE_SUCCESS = "sms_template_client/create_sms_template_success",
  CREATE_SMS_TEMPLATE_FAILED = "sms_template_client/create_sms_template_failed",

  EDIT_SMS_TEMPLATE_REQUEST = "sms_template_client/edit_sms_template_request",
  EDIT_SMS_TEMPLATE_SUCCESS = "sms_template_client/edit_sms_template_success",
  EDIT_SMS_TEMPLATE_FAILED = "sms_template_client/edit_sms_template_failed",

  DELETE_SMS_TEMPLATE_REQUEST = "sms_template_client/delete_sms_template_request",
  DELETE_SMS_TEMPLATE_SUCCESS = "sms_template_client/delete_sms_template_success",
  DELETE_SMS_TEMPLATE_FAILED = "sms_template_client/delete_sms_template_failed",

  VALIDATE_SMS_TEMPLATE_REQUEST = "sms_template_client/validate_sms_template_request",
  VALIDATE_SMS_TEMPLATE_SUCCESS = "sms_template_client/validate_sms_template_success",
  VALIDATE_SMS_TEMPLATE_FAILED = "sms_template_client/validate_sms_template_failed",

  GET_SMS_TEMPLATE_LANGUAGES_REQUEST = "sms_template_client/get_sms_template_languages_request",
  GET_SMS_TEMPLATE_LANGUAGES_SUCCESS = "sms_template_client/get_sms_template_languages_success",
  GET_SMS_TEMPLATE_LANGUAGES_FAILED = "sms_template_client/get_sms_template_languages_failed",

  GET_SMS_TEMPLATE_PARAMETERS_REQUEST = "sms_template_client/get_sms_template_parameters_request",
  GET_SMS_TEMPLATE_PARAMETERS_SUCCESS = "sms_template_client/get_sms_template_parameters_success",
  GET_SMS_TEMPLATE_PARAMETERS_FAILED = "sms_template_client/get_sms_template_parameters_failed",
}

export const useGetSmsTemplatesApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.getSmsTemplates, {
    REQUEST_EVENT: ActionTypes.GET_SMS_TEMPLATES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SMS_TEMPLATES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SMS_TEMPLATES_FAILED,
  });

export const useGetSmsTemplateApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.getSmsTemplate, {
    REQUEST_EVENT: ActionTypes.GET_SMS_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SMS_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SMS_TEMPLATE_FAILED,
  });

export const useCreateSmsTemplateApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.createSmsTemplate, {
    REQUEST_EVENT: ActionTypes.CREATE_SMS_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.CREATE_SMS_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.CREATE_SMS_TEMPLATE_FAILED,
  });

export const useGetSmsTemplateLanguagesApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.getSmsTemplateLanguages, {
    REQUEST_EVENT: ActionTypes.GET_SMS_TEMPLATE_LANGUAGES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SMS_TEMPLATE_LANGUAGES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SMS_TEMPLATE_LANGUAGES_FAILED,
  });

export const useGetSmsTemplateParametersApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.getSmsTemplateParameters, {
    REQUEST_EVENT: ActionTypes.GET_SMS_TEMPLATE_PARAMETERS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SMS_TEMPLATE_PARAMETERS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SMS_TEMPLATE_PARAMETERS_FAILED,
  });

export const useEditSmsTemplateApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.editSmsTemplate, {
    REQUEST_EVENT: ActionTypes.EDIT_SMS_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_SMS_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_SMS_TEMPLATE_FAILED,
  });

export const useDeleteSmsTemplateApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.deleteSmsTemplate, {
    REQUEST_EVENT: ActionTypes.DELETE_SMS_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_SMS_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_SMS_TEMPLATE_FAILED,
  });

export const useValidateSmsTemplateApi = () =>
  useApiRequestAnalytics(SmsTemplateClient.validateSmsTemplate, {
    REQUEST_EVENT: ActionTypes.VALIDATE_SMS_TEMPLATE_REQUEST,
    SUCCESS_EVENT: ActionTypes.VALIDATE_SMS_TEMPLATE_SUCCESS,
    ERROR_EVENT: ActionTypes.VALIDATE_SMS_TEMPLATE_FAILED,
  });

export const useSmsTemplateClient = (): SmsTemplateClientApi => ({
  getSmsTemplates: useGetSmsTemplatesApi(),
  getSmsTemplate: useGetSmsTemplateApi(),
  createSmsTemplate: useCreateSmsTemplateApi(),
  editSmsTemplate: useEditSmsTemplateApi(),
  deleteSmsTemplate: useDeleteSmsTemplateApi(),
  validateSmsTemplate: useValidateSmsTemplateApi(),
  getSmsTemplateLanguages: useGetSmsTemplateLanguagesApi(),
  getSmsTemplateParameters: useGetSmsTemplateParametersApi(),
});

export { ActionTypes as SmsTemplateClientActionTypes };

export default useSmsTemplateClient;

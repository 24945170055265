import React from "react";
import { ContainersList } from "@components";
import { useHistory } from "react-router-dom";
import { getCreateRepositoryPath } from "@modules/containerRepositoryWizard/helpers";
import { useListRepositories } from "@hooks";
import { ContainerRepository } from "@data";
import { getPathToRepository } from "@modules/containerRepositoryDetails/helpers";

export function ContainerRepositoriesView() {

  const history = useHistory();

  const createRepositoryPath = React.useCallback(() =>
    history.push(getCreateRepositoryPath()), [history]);

  const showDetails = React.useCallback((repository: ContainerRepository) =>
    history.push(getPathToRepository(repository)), [history]);

  const [model, actions] = useListRepositories({});

  return (
    <ContainersList
      {...model}
      {...actions}
      createButtonLabel="Create Repository"
      showCreateButton={true}
      showActions={true}
      onClickCreateButton={createRepositoryPath}
      onClickItem={showDetails}
    />
  );
}

export default ContainerRepositoriesView;

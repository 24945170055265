import React, { useState } from "react";
import classnames from "classnames";
import { ContinueButton } from "@components/continue-button";
import { Alert, AlertTitle } from "@components/alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@components/text-field";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { formEventHandler, getMenuItems, noop } from "@util";
import EmailTemplateLang from "@data/EmailTemplateLanguages";
import EmailTemplateReqs from "@data/EmailTemplateRequirements";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { EmailTemplateLanguage, EmailTemplateMessageType } from "@data";
import { templateIdTextField, templateView as styles } from "./styles";
import { GetEmailLanguagesResponse, GetEmailRequirementsResponse } from "@network";
import { RegionalTemplateMessageTypeStatus } from "@data/RegionalTemplateMessageType";

const TemplateIdTextField = withStyles(templateIdTextField)(TextField);

const menuItemFactory = (value: string, func: any) => {
  if (process.env.NODE_ENV === "test") {
    return (
      <option
        key={value}
        className="messageTypeMenuItem"
      >
        {value}
      </option>
    );
  }
  return (
    <MenuItem
      className="messageTypeMenuItem"
      key={value}
      value={value}
    >
      {func(value)}
    </MenuItem>
  );
};

export const mapMessageTypeToOptionName = (value: EmailTemplateMessageType) => {
  switch (value) {
    case EmailTemplateMessageType.MESSAGE_SIGNUP:
      return "New User - Signify Identity - Initial Message";
    case EmailTemplateMessageType.MESSAGE_SIGNUP_FEDERATION:
      return "New User - Federated Identity - Initial Message";
    case EmailTemplateMessageType.MESSAGE_SIGNUP_CONFIRMATION:
      return "New User - Account Terms & Conditions Confirmed";
    case EmailTemplateMessageType.MESSAGE_RESEND_CODE:
      return "New User - Signify Identity - Retry Message";
    case EmailTemplateMessageType.MESSAGE_RESEND_CODE_FEDERATION:
      return "New User - Federated Identity - Retry Message";
    case EmailTemplateMessageType.MESSAGE_FORGET_PASSWORD:
      return "Existing User - Password Recovery Message";
    case EmailTemplateMessageType.MESSAGE_INVITE_EXISTING_USER:
      return "Existing User - Welcome Message";
    case EmailTemplateMessageType.MESSAGE_USER_ALREADY_CONFIRMED:
      return "Existing User - Already Confirmed Message";
    case EmailTemplateMessageType.MESSAGE_ACCOUNT_CLOSED:
      return "Existing User - Close Account Confirmation Message";
    case EmailTemplateMessageType.MESSAGE_ACCOUNT_LOCKED:
      return "Existing User - Account Locked Message";
    case EmailTemplateMessageType.MESSAGE_ACCOUNT_UNLOCKED:
      return "Existing User - Account Unlocked Message";
    case EmailTemplateMessageType.MESSAGE_CUSTOM_TEMPLATE:
      return "Custom Message";
    case EmailTemplateMessageType.NONE:
      return null;
    default:
      // If a new type was introduced, we do not want to block it from being used,
      // so just render the value as a string until a proper label is introduced.
      return value + "";
  }
};

export interface Model {
  templateId?: string;
  templateIdError?: string;
  templateIdDisabled?: boolean;
  language?: EmailTemplateLanguage;
  messageType?: EmailTemplateMessageType;
  emailLanguages?: GetEmailLanguagesResponse;
  emailRequirements?: GetEmailRequirementsResponse;
  children?: React.ReactNode;
}

export interface Actions {
  setTemplateId?: (templateId: string) => void;
  setLanguage?: (language: EmailTemplateLanguage) => void;
  setMessageType?: (messageType: EmailTemplateMessageType) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const TemplateView = withStyles(styles)((props: Props) => {

  const {
    classes,
    templateId = "",
    templateIdError = "",
    templateIdDisabled = false,
    language = EmailTemplateLanguage.EN_US,
    messageType = EmailTemplateMessageType.MESSAGE_SIGNUP,
    emailLanguages = EmailTemplateLang.EMPTY.toJS(),
    emailRequirements = EmailTemplateReqs.EMPTY.toJS(),
    children,
    setTemplateId,
    setLanguage,
    setMessageType = noop,
  } = props;

  const [showOptionalParams, setShowOptionalParams] = useState(false);

  const messageTypes = React.useMemo<EmailTemplateMessageType[]>(() => {
    const { messageTypes: availableMessageTypes = {} } = emailRequirements || {};
    const values = Object.keys(availableMessageTypes).map(key => key as EmailTemplateMessageType);
    return values.length === 0 ? [messageType] : values;
  }, [messageType, emailRequirements]);

  const inputLabelProps = {
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const sortedLanguages = React.useMemo(() => emailLanguages.languages.sort(), [emailLanguages]);

  const languageMenuItems = React.useMemo(() =>
      getMenuItems({...sortedLanguages}, (s: string) => s, menuItemFactory), [sortedLanguages]);

  const messageTypeMenuItems = React.useMemo(() =>
    messageTypes.map(type => menuItemFactory(type, mapMessageTypeToOptionName)),
  [messageTypes]);

  React.useEffect(() => {
    setMessageType(messageType);
  }, [emailRequirements]);

  const messageTypeDOM = (key: string) => React.useMemo(() => [(
      <FormControl key={key} className={classnames("formControl", classes.formControl, classes.messageTypeFormControl)}>
        <InputLabel
            className="messageType"
            variant="standard"
        >
          Message Type
        </InputLabel>
        <Select
          className="messageTypeDropdown"
          native={process.env.NODE_ENV === "test"}
          value={messageType}
          onChange={formEventHandler(setMessageType)}
        >
          {messageTypeMenuItems}
        </Select>
      </FormControl>
  )], [messageType, messageTypeMenuItems]);

  const allParams = React.useMemo(
      () => Object.keys(emailRequirements.parameters), [emailRequirements]);

  const requiredParams = React.useMemo(() => {
    if (emailRequirements.messageTypes[messageType] == null) { return []; }
    return emailRequirements.messageTypes[messageType].requiredParameters;
  }, [messageType, emailRequirements]);

  const paramsList = (reqParams: string[], filter: string[] = []) => {
    reqParams = reqParams.filter((n) => !filter.includes(n));
    reqParams = reqParams.sort();

    let reqParamsList = [];
    if (emailRequirements.messageTypes[messageType] != null) {
      let deprecated = {};
      for (let i = 0; i < allParams.length; i++) {
        if (emailRequirements.parameters[allParams[i]].status
            === RegionalTemplateMessageTypeStatus.DEPRECATED) {

          const depKey = emailRequirements.parameters[allParams[i]].replacement;

          if (deprecated[depKey] == null) { deprecated[depKey] = []; }

          let depArr = deprecated[depKey];
          depArr.push(allParams[i]);
          deprecated[depKey] = depArr;
        }
      }

      reqParams = reqParams.filter((n) => emailRequirements.parameters[n].status
          !== RegionalTemplateMessageTypeStatus.DEPRECATED);

      for (let i = 0; i < reqParams.length; i++) {
        reqParamsList.push((
            <ul
                key={"reqParam-" + reqParams[i] + "-" + i}
                className={classnames("alertDescription", classes.alertDescription)}
            >
              <li>
                <strong>{reqParams[i]} - </strong>
                {emailRequirements.parameters[reqParams[i]].description}
                <ul>
                  <li>
                    <strong>Syntax - </strong>
                    {emailRequirements.parameters[reqParams[i]].syntax}
                  </li>
                  {deprecated[reqParams[i]] != null && (
                      <li>
                        <strong>Deprecated - </strong>
                        {deprecated[reqParams[i]].join(", ")}
                      </li>
                  )}
                </ul>
              </li>
            </ul>
        ));
      }
    }
    return reqParamsList;
  };

  const requiredParamsList = React.useMemo(() => paramsList(requiredParams), [requiredParams]);
  const optionalParamsList = React.useMemo(() => paramsList(allParams, requiredParams), [allParams, requiredParams]);

  return (
    <div className={classnames("templateView", classes.container)}>
      <label className={classes.title}>
        {templateIdDisabled ? "Email Template Id" : "Set Email Template Id"}
      </label>
      <TemplateIdTextField
        className={classnames("templateId", classes.templateId)}
        autoComplete="off"
        label="Email Template Id"
        name="templateId"
        value={templateId}
        fullWidth={true}
        autoFocus={!templateIdDisabled}
        disabled={templateIdDisabled}
        variant="outlined"
        margin="none"
        required={true}
        helperText={templateIdError}
        error={templateIdError.length > 0}
        InputLabelProps={inputLabelProps}
        onChange={formEventHandler(setTemplateId)}
      />
      <FormControl className={classnames("formControl", classes.formControl)}>
        <InputLabel
          className="language"
          variant="standard"
          htmlFor="outlined-select-box-language"
        >
          Language
        </InputLabel>
          <Select
            className="languageDropdown"
            native={process.env.NODE_ENV === "test"}
            value={language}
            onChange={formEventHandler(setLanguage)}
          >
            {languageMenuItems}
          </Select>
        </FormControl>
      {emailRequirements.messageTypes[messageType] == null && (
           messageTypeDOM("reqsPending")
      )}
      {emailRequirements.messageTypes[messageType] != null && (
        <div className={classnames("messageTypeContainer", classes.messageTypeContainer)}>
          { messageTypeDOM("reqsReceived") }
          <Alert severity="info" className={classnames("messageTypeInfo", classes.alert)}>
            <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
              <strong>
                {mapMessageTypeToOptionName(messageType)?.toUpperCase()}
              </strong>
            </AlertTitle>
            <ul id="msgTypeDesc" className={classnames("alertDescription", classes.alertDescription)}>
              <li>
                <strong>Description - </strong>
                {emailRequirements.messageTypes[messageType].description}
              </li>
            </ul>
            {requiredParamsList.length > 0 && (
                <ul id="requiredParams" className={classnames("alertDescription", classes.alertDescription)}>
                  <li>
                    <strong>Required Parameters - </strong>
                    These parameters are required for this message type:
                  </li>
                  {requiredParamsList}
                </ul>
            )}
            {showOptionalParams && optionalParamsList.length > 0 && (
                <ul id="optionalParams" className={classnames("alertDescription", classes.alertDescription)}>
                  <li>
                    <strong>Optional Parameters - </strong>
                    These parameters are optional for this message type:
                  </li>
                  {optionalParamsList}
                </ul>
            )}
            <br/>
            {optionalParamsList.length > 0 && (
                <ContinueButton
                    className={classnames("showParamsButton", classes.button)}
                    primary={true}
                    onClick={() => setShowOptionalParams(!showOptionalParams)}
                >
                  {showOptionalParams ? "Hide" : "Show"} optional parameters
                </ContinueButton>
            )}
          </Alert>
        </div>
      )}
      {children}
    </div>
  );
});

export default TemplateView;

import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue } from "@util";
import { SmsBody, SmsBodyAttributes } from "./SmsBody";
import { SmsMessageType } from "@data";

export enum SmsTemplateLanguage {
  NONE = "",
  BG_BG = "bg-BG",
  CS_CZ = "cs-CZ",
  DA_DK = "da-DK",
  DE_DE = "de-DE",
  EN_US = "en-US",
  ES_ES = "es-ES",
  FI_FI = "fi-FI",
  FR_FR = "fr-FR",
  HU_HU = "hu-HU",
  IT_IT = "it-IT",
  NL_NL = "nl-NL",
  NO_NO = "no-NO",
  PL_PL = "pl-PL",
  PT_PT = "pt-PT",
  SK_SK = "sk-SK",
  SV_SE = "sv-SE",
  ZH_CN = "zh-CN",
  ZH_TW = "zh-TW",
}

export interface SmsTemplateAttributes {
  id: string;
  messageType: SmsMessageType;
  language: SmsTemplateLanguage;
  body: SmsBodyAttributes;
  applicationId?: string;
}

export class SmsTemplate extends Record({
  id: "",
  messageType: "",
  language: SmsTemplateLanguage.EN_US,
  body: SmsBody.EMPTY.toJS(),
  applicationId: "",
}) implements SmsTemplateAttributes {

  public static EMPTY: SmsTemplate = new SmsTemplate();

  public readonly id: string;
  public readonly messageType: SmsMessageType;
  public readonly language: SmsTemplateLanguage;
  public readonly body: SmsBodyAttributes;
  public readonly applicationId: string;

  public equals(other: SmsTemplate): boolean {
    return equalsIgnoreCase(this.getId(), other.getId()) &&
      equalsIgnoreCase(this.getMessageType(), other.getMessageType()) &&
      equalsIgnoreCase(this.getLanguage(), other.getLanguage()) &&
      equalsIgnoreCase(this.getApplicationId(), other.getApplicationId()) &&
      this.getBody().equals(other.getBody());
  }

  public getId(): string {
    return getStringValue(this.id);
  }

  public getMessageType(): string {
    return getStringValue(this.messageType);
  }

  public getLanguage(): string {
    return getStringValue(this.language);
  }

  public getBody(): SmsBody {
    return new SmsBody(this.body);
  }

  public getTextBody(): string {
    return this.getBody().getTextBody();
  }

  public getDescription(): string {
    return this.getBody().getDescription();
  }

  public getApplicationId(): string {
    return getStringValue(this.applicationId);
  }
}

export default SmsTemplate;

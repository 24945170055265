import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useDeleteContainerRepository from "@hooks/containerRepository/use-delete-container-repository";

export interface DeleteContainerRepositoryDialogModel extends PortalModuleDialogModel<string> {
  alias?: string;
}

export interface DeleteContainerRepositoryDialogActions extends PortalModuleDialogActions<string> {
  onSuccess?: () => void;
  cancel?: () => void;
}

type Props = WithStyles<typeof styles> & DeleteContainerRepositoryDialogModel & DeleteContainerRepositoryDialogActions;

export const DeleteContainerRepositoryDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    alias = "",
    onSuccess = noop,
    cancel = noop,
    ...otherProps
  } = props;

  const [model, { refresh: deleteRepo }] =
    useDeleteContainerRepository({ repoId: alias });

  if (isEmptyString(alias)) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...model}
      className={classnames("deleteContainerRepositoryDialog")}
      title="Delete Container Repository"
      continueButtonLabel="Delete"
      confirm={deleteRepo}
      onSuccessMessageShown={onSuccess}
      cancel={cancel}
    >
      <div className={classnames("deleteContainerRepository", classes.container)}>
          <React.Fragment>
            <label className={classnames("title", classes.title)}>
              Are you sure you want to delete this container repository?
            </label>
            <label className={classnames("subtitle", classes.subtitle)}>
              <span className={classes.warning}>WARNING:</span>This action cannot be undone!
            </label>
            <label className={classnames("alias", classes.alias)}>
              {alias}
            </label>
          </React.Fragment>
      </div>
    </PortalModuleDialog>
  );
});

export default DeleteContainerRepositoryDialog;

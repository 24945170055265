import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSmsTemplateClient,
} from "@hooks";
import { SmsTemplateLanguage } from "@data";
import { GetSmsTemplateLanguagesResponse } from "@network";

type SuccessResponse = GetSmsTemplateLanguagesResponse;

export interface UseGetSmsTemplateLanguagesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetSmsTemplateLanguagesModel extends UseApiRequestModel<SuccessResponse> {
  languages: SmsTemplateLanguage[];
}

export interface UseGetSmsTemplateLanguagesActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetSmsTemplateLanguagesProps;
type Model = UseGetSmsTemplateLanguagesModel;
type Actions = UseGetSmsTemplateLanguagesActions;
type Result = [Model, Actions];

export const useGetSmsTemplateLanguages = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get supported SMS template languages",
    ...otherProps
  } = props;

  const SmsTemplateClient = useSmsTemplateClient();

  const makeApiRequest = React.useCallback(
    () => SmsTemplateClient.getSmsTemplateLanguages(),
    [SmsTemplateClient]);

  const [{successResponse, ...baseModel}, { ...baseActions} ] =
    useApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const languages = React.useMemo(() => {
    return successResponse ? successResponse.languages : [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    languages,
  }), [
    baseModel,
    languages,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetSmsTemplateLanguages;

import { Record } from "immutable";
import { getStringValue } from "@util";
import { SmsBody, SmsBodyAttributes, SmsMessageType, SmsTemplate } from "@data";

export interface ValidateSmsTemplateRequestAttributes {
  messageType: SmsMessageType;
  body: SmsBodyAttributes;
}

export class ValidateSmsTemplateRequest extends Record({
  messageType: SmsMessageType.NONE,
  body: {
    description: "",
    textBody: "",
  },
}) implements ValidateSmsTemplateRequestAttributes {

  public static EMPTY: ValidateSmsTemplateRequest = new ValidateSmsTemplateRequest();

  public readonly messageType: SmsMessageType;
  public readonly body: SmsBodyAttributes;

  public static from(smsTemplate: SmsTemplate): ValidateSmsTemplateRequest {
    return new ValidateSmsTemplateRequest({
      ...smsTemplate.toJS()
    });
  }

  public getMessageType(): string {
    return getStringValue(this.messageType);
  }

  public getBody(): SmsBody {
    return new SmsBody(this.body);
  }

  public getTextBody(): string {
    return this.getBody().getTextBody();
  }

  public getDescription(): string {
    return this.getBody().getDescription();
  }
}

export default ValidateSmsTemplateRequest;

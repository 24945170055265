import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    list: {
      marginTop: 0,
    },
  });

export default styles;

import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import { User, UserAttributes, UserIdentityType, UserProfileAttributeAttributes } from "@data";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleReducers,
  DEFAULT_STATE as BASE_DEFAULT_STATE,
  PortalModuleActionTypes,
} from "@base/createReducers";

export type UserProfileAttributeItems = {
  [key: string]: UserProfileAttributeAttributes;
};

export interface CreateUserSchema extends BasePortalModuleSchema {
  identityType: UserIdentityType;
  userId: string;
  userIdErrorMessage: string;
  password: string;
  passwordErrorMessage: string;
  showPassword: boolean;
  mfaEnabled: boolean;
  mfaSecret: string;
  mfaSecretErrorMessage: string;
  user: UserAttributes;
  profile: UserProfileAttributeItems;
  isPortalUser: boolean;
  isFederatedUser: boolean;
  federationProviders: string[];
  selectedFederationProvider: string;
}

export const DEFAULT_STATE: CreateUserSchema = Object.freeze({
  ...BASE_DEFAULT_STATE,
  showEmptyView: false,
  identityType: UserIdentityType.NONE,
  userId: "",
  userIdErrorMessage: "",
  password: "",
  passwordErrorMessage: "",
  showPassword: false,
  mfaEnabled: false,
  mfaSecret: "",
  mfaSecretErrorMessage: "",
  user: Object.freeze({ ...User.EMPTY.toJS() }),
  profile: Object.freeze({}),
  isPortalUser: true,
  isFederatedUser: true,
  federationProviders: [],
  selectedFederationProvider: "",
});

export type CreateUserActionTypes = PortalModuleActionTypes<CreateUserSchema> & {
  CREATE_USER_REQUEST: string;
  CREATE_USER_SUCCESS: string;
  CREATE_USER_FAILED: string;
  CREATE_PORTAL_USER_REQUEST: string;
  CREATE_PORTAL_USER_SUCCESS: string;
  CREATE_PORTAL_USER_FAILED: string;
  CREATE_FEDERATED_USER_REQUEST: string;
  CREATE_FEDERATED_USER_SUCCESS: string;
  CREATE_FEDERATED_USER_FAILED: string;
};

export type CreateUserActionType = CreateUserActionTypes[keyof CreateUserActionTypes];

export const ACTION_TYPES: CreateUserActionTypes = {
  ...createPortalModuleActionTypes<CreateUserSchema>(MODULE_ID, DEFAULT_STATE),
  ...{
    CREATE_USER_REQUEST: `${MODULE_ID}/create_user_request`,
    CREATE_USER_SUCCESS: `${MODULE_ID}/create_user_success`,
    CREATE_USER_FAILED: `${MODULE_ID}/create_user_failed`,
    CREATE_PORTAL_USER_REQUEST: `${MODULE_ID}/create_portal_user_request`,
    CREATE_PORTAL_USER_SUCCESS: `${MODULE_ID}/create_portal_user_success`,
    CREATE_PORTAL_USER_FAILED: `${MODULE_ID}/create_portal_user_failed`,
    CREATE_FEDERATED_USER_REQUEST: `${MODULE_ID}/create_federated_user_request`,
    CREATE_FEDERATED_USER_SUCCESS: `${MODULE_ID}/create_federated_user_success`,
    CREATE_FEDERATED_USER_FAILED: `${MODULE_ID}/create_federated_user_failed`,
  },
};

export const CreateUserReducer = combineReducers<CreateUserSchema>(
  createPortalModuleReducers<CreateUserSchema>(ACTION_TYPES, DEFAULT_STATE));

export default CreateUserReducer;

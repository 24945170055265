import React from "react";
import { isEmptyString, noop } from "@util";
import { User, UserIdentityType, UserProfileAttributesMap } from "@data";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  getErrorMessage,
  getIdentityType,
  getPassword,
  getSelectedFederationProvider,
  getSuccessMessage,
  getUser,
  getUserId,
  getUserProfileAttributesMap,
  isFederatedUser,
  isLoadingIndicatorVisible,
  isPortalUser,
  isSuccessMessageVisible,
} from "../selectors";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import { createUser } from "../actions";
import CreateUserState from "../models/CreateUserState";
import EditProfileAttributes from "./EditProfileAttributes";
import UserInfo from "./UserInfo";

interface ContainerModel extends SubmitApiRequestViewModel<User> {
  identityType?: UserIdentityType;
  isPortalUser?: boolean;
  isFederatedUser?: boolean;
  selectedFederationProvider?: string;
  userId?: string;
  password?: string;
  profileAttributes?: UserProfileAttributesMap;
  createdUser?: User;
}

interface ContainerActions extends SubmitApiRequestViewActions<User> {
  showUserDetails?: (user: User) => void;
}

type Props = ContainerModel & ContainerActions;

const CreateUserContainer = (props: Props) => {

  const {
    identityType = UserIdentityType.EMAIL,
    isPortalUser: portalUser = identityType === UserIdentityType.EMAIL,
    isFederatedUser: federatedUser = identityType === UserIdentityType.EMAIL,
    selectedFederationProvider = "",
    userId = "",
    password = "",
    profileAttributes = {},
    createdUser = User.EMPTY,
    defaultState = CreateUserState.EMPTY,
    showUserDetails: showUserDetailsPage = noop,
    ...otherProps
  } = props;

  const showEditProfileAttributes = React.useMemo(() => {

    if (portalUser) {
      return true;
    }

    if (federatedUser) {
      return !isEmptyString(selectedFederationProvider);
    }

    return true;

  }, [portalUser, federatedUser, selectedFederationProvider]);

  const profileValueUpdated = React.useMemo(() =>
      Object.keys(profileAttributes)
        .map(key => profileAttributes[key])
        .some(attribute => !isEmptyString(attribute.value)),
    [profileAttributes]);

  const currentState = React.useMemo(() => new CreateUserState({
    identityType,
    portalUser,
    federatedUser,
    federationProvider: selectedFederationProvider,
    userId,
    password,
    profileValueUpdated,
  }), [
    identityType,
    portalUser,
    federatedUser,
    selectedFederationProvider,
    userId,
    password,
    profileValueUpdated,
  ]);

  const requiredInputMissing = React.useMemo(() => {
    if (identityType === UserIdentityType.EMAIL) {
      if (portalUser) {
        return isEmptyString(userId);
      } else if (federatedUser) {
        return isEmptyString(selectedFederationProvider) || isEmptyString(userId);
      } else {
        return isEmptyString(userId) || isEmptyString(password);
      }
    } else {
      return isEmptyString(userId) || isEmptyString(password);
    }
  }, [identityType, portalUser, federatedUser, selectedFederationProvider, userId, password]);

  const saveButtonDisabled = React.useMemo(() =>
    requiredInputMissing || !showEditProfileAttributes,
    [requiredInputMissing, showEditProfileAttributes]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      snackbarId="create-user"
      errorTitle="Create User Failed"
      saveButtonLabel="Create User"
      defaultState={defaultState}
      currentState={currentState}
      saveButtonDisabled={saveButtonDisabled}
      onSuccess={() => showUserDetailsPage(createdUser)}
    >
      <UserInfo />
      {showEditProfileAttributes && (
        <EditProfileAttributes />
      )}
    </SubmitApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  identityType: getIdentityType(state),
  isPortalUser: isPortalUser(state),
  isFederatedUser: isFederatedUser(state),
  selectedFederationProvider: getSelectedFederationProvider(state),
  userId: getUserId(state),
  password: getPassword(state),
  profileAttributes: getUserProfileAttributesMap(state),
  createdUser: getUser(state),
  successMessage: getSuccessMessage(state),
  errorMessage: getErrorMessage(state),
  showLoadingIndicator: isLoadingIndicatorVisible(state),
  showSuccessIndicator: isSuccessMessageVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  save: () => dispatch(createUser()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUserContainer);

import React from "react";
import useContainerRepositoryClient from "../use-container-repository-client";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks";
import { SimpleCredentials } from "@data";
import { isEmptyString } from "@util";

type SuccessResponse = void;

export interface UseUpdateRepositoryCredentialsProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  id: string;
  credentials: SimpleCredentials;
}

export interface UseUpdateRepositoryCredentialsModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseUpdateRepositoryCredentialsActions extends UseApiRequestActions<SuccessResponse> {
  updateCredentials: () => void;
}

type Props = UseUpdateRepositoryCredentialsProps;
type Model = UseUpdateRepositoryCredentialsModel;
type Actions = UseUpdateRepositoryCredentialsActions;
type Result = [Model, Actions];

export const useUpdateContainerRepositoryCredentials = (props: Props): Result => {

  const {
    id,
    credentials,
    defaultErrorMessage = "Failed to update container repository",
    ...otherProps
  } = props;

  const ContainerRepositoryManagerClient = useContainerRepositoryClient();

  let body = credentials;

  if (isEmptyString(credentials.username)) {
    delete body.username;
  }

  if (isEmptyString(credentials.password)) {
    delete body.password;
  }

  const makeApiRequest = React.useCallback(() => {
    return ContainerRepositoryManagerClient.updateContainerRepository(id,
      JSON.stringify({security: {authentication: {simpleCredentials: body}}}));
  }, [ContainerRepositoryManagerClient, id, body]);

  const [{showSuccessView, ...baseModel}, {
    refresh,
    ...baseActions
  }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      deferRequest: true,
      defaultErrorMessage,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Repository updated successfully" : ""
    , [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    updateCredentials: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateContainerRepositoryCredentials;

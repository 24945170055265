import React from "react";
import {
  ContainerRepositoryOperation,
  ContinueButton,
  DeleteContainerRepositoryDialog,
  DetailsView,
  SummaryViewWithActions,
  UpdateContainerRepositoryDescriptionDialog,
  UpdateContainerRepositoryDialog,
} from "@components";
import { useHistory, useParams } from "react-router-dom";
import { useGetRepository } from "@hooks";
import { SummaryViewData } from "@data";
import { PrimaryIcon as icon } from "@modules/containerRepositories/styles";
import { getPathToRepositories } from "@modules/containerRepositories/helpers";
import { isEmptyString } from "@util";

export function ContainerRepositoryDetailsView() {

  const { id: repoId } = useParams<{ id: string }>();

  const history = useHistory();

  const showListView = React.useCallback(() => {
    history.push(getPathToRepositories());
  }, [history]);

  const [{ repository, showLoadingIndicator, ...otherModel}, { refresh: getRepository, ...otherActions }] =
    useGetRepository({ repoId });

  const [showEditDialog, setShowEditDialog] = React.useState<boolean>(false);
  const [editOperation, setEditOperation] = React.useState(ContainerRepositoryOperation.EDIT_USERNAME);

  const onEditSuccess = React.useCallback(() => {
    setShowEditDialog(false);
    getRepository();
  }, [setShowEditDialog, getRepository]);

  const editRepository = React.useCallback((operation: ContainerRepositoryOperation) => {
    setShowEditDialog(true);
    setEditOperation(operation);
  }, [setShowEditDialog, setEditOperation]);

  const [showEditDescription, setShowEditDescription] = React.useState(false);

  const onEditDescriptionSuccess = React.useCallback(() => {
    setShowEditDescription(false);
    getRepository();
  }, [setShowEditDescription, getRepository]);

  const summaryViewItems = React.useMemo(() =>
    [
      {
        showEditIcon: true,
        onClickEditIcon: () => setShowEditDescription(true),
        summaryViewItem:
          new SummaryViewData({
            className: "description",
            name: "Description",
            value: repository.getDescription(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "type",
            name: "Type",
            value: repository.getType(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "url",
            name: "URL",
            value: repository.getUrl(),
          }),
      },
      {
        showEditIcon: true,
        onClickEditIcon: () => editRepository(ContainerRepositoryOperation.EDIT_USERNAME),
        summaryViewItem:
          new SummaryViewData({
            className: "username",
            name: "Username",
            value: repository.getUsername(),
          }),
      },
      {
        showEditIcon: true,
        onClickEditIcon: () => editRepository(ContainerRepositoryOperation.EDIT_PASSWORD),
        summaryViewItem:
          new SummaryViewData({
            className: "password",
            name: "Password",
            value: isEmptyString(repository.getUsername()) ? "" : "AVAILABLE",
          }),
      },
  ], [repository, setShowEditDescription, editRepository]);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

  const onDeleteSuccess = React.useCallback(() => {
    setOpenDeleteDialog(false);
    showListView();
  }, [setOpenDeleteDialog, showListView]);

  const deleteButton = React.useMemo(() => (
    <ContinueButton
      onClick={() => setOpenDeleteDialog(true)}
      label="Delete"
    />
  ), [setOpenDeleteDialog]);

  return (
    <DetailsView
      {...otherModel}
      {...otherActions}
      title={repository.getAlias()}
      icon={icon}
      showLoadingIndicator={showLoadingIndicator}
      headerButtons={deleteButton}
      refresh={getRepository}
    >
      {!showLoadingIndicator && (
        <SummaryViewWithActions
          items={summaryViewItems}
        />
      )}
      {openDeleteDialog && (
        <DeleteContainerRepositoryDialog
          alias={repository.getAlias()}
          open={openDeleteDialog}
          onSuccess={onDeleteSuccess}
          cancel={() => setOpenDeleteDialog(false)}
        />
      )}
      {showEditDialog && (
        <UpdateContainerRepositoryDialog
          alias={repository.getAlias()}
          username={repository.getUsername()}
          operation={editOperation}
          open={showEditDialog}
          onSuccess={onEditSuccess}
          cancel={() => setShowEditDialog(false)}
        />
      )}
      {showEditDescription && (
        <UpdateContainerRepositoryDescriptionDialog
          alias={repository.getAlias()}
          description={repository.getDescription()}
          open={showEditDescription}
          onSuccess={onEditDescriptionSuccess}
          cancel={() => setShowEditDescription(false)}
        />
      )}
    </DetailsView>
  );
}

export default ContainerRepositoryDetailsView;

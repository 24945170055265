import Color from "@Color";
import ModuleIcon from "@material-ui/icons/Work";
import { createStyles, StyleRules } from "@material-ui/core/styles";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const formControlLabel: StyleRules = {
  root: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    marginLeft: 8,
    color: Color.TEXT,
  },
};

export const workloadInfo = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    marginBottom: {
      marginBottom: 25,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
      marginBottom: 25,
      letterSpacing: 0.001,
    },
    subtitle: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      marginBottom: 25,
    },
    dropdown: {
      alignItems: "stretch",
      marginBottom: 25,
      width: 400,
      "& .MuiOutlinedInput-input": {
        paddingBottom: 12,
        paddingLeft: 10,
      },
      "& .dropdownMenuHelperText": {
        marginLeft: 4,
      },
    },
    dropdownMenuEmptyValueLabel: {
      color: Color.GREY3,
      fontStyle: "normal",
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      lineHeight: "12px",
    },
    codeOptionContainer: {
      marginTop: 20,
      display: "flex",
      flexFlow: "row nowrap",
      width: 1300,
    },
    codeOption: {
    },
    codeOptionDescription: {
      marginLeft: 10,
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "35px",
    },
    codeOptionTitle: {
      fontWeight: 200
    },
    language: {
      flex: "1 1 auto",
    },
    languageVersion: {
      flex: "0 0 auto",
    },
    configFile: {
      flex: "0 0 auto",
    },
    entryPoint: {
      minWidth: 350,
      marginBottom: 25,
      "& label": {
        marginLeft: -9,
        background: Color.WHITE,
        padding: "0px 4px",
      },
      "& input": {
        paddingLeft: 10,
      },
      "& p": {
        marginLeft: 4,
      },
    },
    typeContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      marginBottom: 25,
      marginRight: 20,
    },
    pyConfiguration: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "baseline",
      justifyContent: "flex-start",
      marginTop: 25
    },
    selectType: {
      marginTop: -5,
    },
    radioGroup: {
    },
    radio: {
    },
    reqTxtFlag: {
      marginLeft: 50,
    },
    depTxtFlag: {
      marginLeft: 0,
      marginTop: 10,
    },
    checkbox: {
      color: primaryColor,
      padding: 0,
    },
    alert: {
      marginLeft: 30,
    },
    alertTitle: {
      fontSize: 14,
      marginTop: 2,
    },
    alertDescription: {
      fontSize: 14,
      lineHeight: "21px",
      marginTop: 10,
      marginBottom: 0,
      paddingLeft: 20,
    },
    containersList: {
      "& div.summary label": {
        fontSize: 18,
      },
      marginBottom: 36,
    },
  });

export const dataSetsView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 300,
    },
    dataSetContainer: {
      marginTop: 8,
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      "& .dataSetsList .summary > label": {
        margin: 0,
        fontSize: 18,
        fontWeight: 400,
      },
      "& .dataSetsList .account div label": {
        wordBreak: "normal",
      },
      "& .dataSetsList .type label": {
        fontSize: 13,
      },
    },
  });

export const advancedSettings = () =>
  createStyles({
    container: {
      paddingTop: 10,
    },
    noLine: {
      "& .MuiAccordion-root:before": {
        display: "none",
      },
    },
    summary: {
      width: "fit-content",
      minHeight: 64,
    },
    noPadding: {
      padding: 0,
    },
    label: {
      fontSize: 18,
      fontWeight: 300,
    },
    dropdown: {
      marginBottom: 25,
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
      "& .MuiSelect-select": {
        paddingBottom: 12,
      }
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      lineHeight: "12px",
    },
    details: {
      flexDirection: "column",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    customMemory: {
      width: 110,
      "& p": {
        marginLeft: 0,
        width: 500,
      }
    },
  });

export const internetConfig = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    marginBottom: {
      marginBottom: 25,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    title: {
      color: Color.TEXT,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 28,
      lineHeight: "32px",
      marginTop: 8,
      marginBottom: 25,
    },
    dnsEditor: {
      marginTop: 25,
      "& .tagInput": {
        minWidth: 300,
      }
    },
    nameIcon: {
      color: Color.WHITE,
      width: 14,
      height: 14,
      marginLeft: 2,
      marginRight: 2,
      "&:first-child": {
        marginRight: 0,
      },
    },
  });

export default workloadInfo;

import { isEmptyString } from "@util";
import { MODULE_PATH } from "./constants";

// Regex to ensure a phone number is in a valid format
const VALIDATE_PHONE_REGEX = /^\+[1-9]{1,2}(?:[0-9\-()\/.]\s?){3,14}$/;

export const getCreateUserPath = () => MODULE_PATH;

export const isValidPhoneNumber = (phoneNumber: string = "") =>
  !isEmptyString(phoneNumber) && VALIDATE_PHONE_REGEX.test(phoneNumber);

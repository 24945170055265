import React from "react";
import { Location } from "history";
import classnames from "classnames";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { match as Match, NavLink } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isEmptyString } from "@util/Functions";
import styles from "./styles";

export enum ListItemType {
  NONE = "",
  TITLE = "title",
  SETTINGS = "settings",
  FEEDBACK = "feedback",
  HEADER = "header",
  SUB_HEADER = "sub-header",
  LINK = "link",
  DIVIDER = "divider",
  RELEASE_NOTES = "release_notes",
  COST_REPORTS = "cost_reports",
}

export interface ListItemAttributes {
  className?: string;
  type?: ListItemType;
  id?: string;
  path?: string;
  exactPath?: boolean;
  strictPath?: boolean;
  name?: string | React.ReactNode;
  icon?: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
  selected?: boolean;
  outlined?: boolean;
  clickable?: boolean;
  onClick?: (event: React.MouseEvent<{}>) => void;
  isActive?: (match: Match<any>, location: Location) => boolean;
}

type Props = WithStyles<typeof styles> & ListItemAttributes & {
  children?: React.ReactNode;
};

export const NavigationListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    type = ListItemType.NONE,
    id = "",
    name,
    icon: Icon,
    selected,
    outlined,
    clickable,
    path = "",
    exactPath,
    strictPath,
    onClick,
    isActive = () => false,
    children,
  } = props;

  const hasName = React.useMemo(() => name != null, [name]);

  const hasPath = React.useMemo(() => !isEmptyString(path), [path]);

  const listItemProps = React.useMemo(() => !hasName ? {} : ({
    className: classnames("listItem", className, {
        [id]: type === ListItemType.LINK || type === ListItemType.SETTINGS || type === ListItemType.FEEDBACK,
        [classes.listItemTitle]: type === ListItemType.TITLE,
        [classes.listItemSettings]: type === ListItemType.SETTINGS,
        [classes.listItemFeedback]: type === ListItemType.FEEDBACK,
        [classes.listItemReleaseNotes]: type === ListItemType.RELEASE_NOTES,
        [classes.listItemCostReports]: type === ListItemType.COST_REPORTS,
        [classes.listItemHeader]: type === ListItemType.HEADER,
        [classnames("listItemSubHeader", classes.listItemSubHeader)]: type === ListItemType.SUB_HEADER,
        [classes.listItemLink]: type === ListItemType.LINK,
        [classes.listItemActive]: selected,
        [classes.listItemOutlined]: outlined,
        [classes.listItemClickable]: clickable,
      },
    ),
  }), [classes, className, hasName, id, type, selected, outlined, clickable]);

  const icon = React.useMemo(() => !Icon || !hasName ? null : (
    <Icon
      className={classnames("icon", {
        [classes.listItemTitleIcon]: type === ListItemType.TITLE,
        [classes.listItemSettingsIcon]: type === ListItemType.SETTINGS,
        [classes.listItemFeedbackIcon]: type === ListItemType.FEEDBACK,
        [classes.listItemReleaseNotesIcon]: type === ListItemType.RELEASE_NOTES,
        [classes.listItemCostReportsIcon]: type === ListItemType.COST_REPORTS,
        [classes.listItemHeaderIcon]: type === ListItemType.HEADER,
        [classes.listItemSubHeaderIcon]: type === ListItemType.SUB_HEADER,
        [classes.listItemLinkIcon]: type === ListItemType.LINK,
      })}
    />
  ), [hasName, type, classes, Icon]);

  const content = React.useMemo(() => !hasName ? null : (
    <React.Fragment>
      {icon}
      {name}
    </React.Fragment>
  ), [icon, name, hasName]);

  const view = React.useMemo(() => !hasPath ? content : (
    <NavLink
      className={classnames("link", classes.link)}
      activeClassName={classnames("active", classes.listItemActive)}
      exact={exactPath}
      strict={strictPath}
      to={path}
      isActive={isActive}
    >
      {content}
    </NavLink>
  ), [classes, content, path, isActive, exactPath, strictPath, hasPath]);

  return React.useMemo(() => {

    if (type === ListItemType.DIVIDER) {
      return <Divider className={classes.listItemDivider} />;
    }

    if (!hasName || type === ListItemType.NONE) {
      return null;
    }

    if (clickable) {
      return (
        <ListItem {...listItemProps} button={true} onClick={onClick}>
          {view}
          {children}
        </ListItem>
      );
    }

    return (
      <ListItem {...listItemProps}>
        {view}
        {children}
      </ListItem>
    );

  }, [type, hasName, clickable, onClick, listItemProps, view, children]);
});

export default NavigationListItem;

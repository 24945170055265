import React from "react";
import classnames from "classnames";
import NavigationListItem, { ListItemType } from "./NavigationListItem";
import { MODULE_ID, MODULE_PATH, MODULE_TITLE } from "@modules/costReports/constants";
import { PrimaryIcon as CostReportsIcon } from "@modules/costReports/components/styles";

export const CostReportsListItem = (props: {
  className?: string;
  showCostReports?: boolean;
}) => {

  const {
    className,
    showCostReports = false,
  } = props;

  if (!showCostReports) {
    return null;
  }

  return (
    <NavigationListItem
      className={classnames("costReportsListItem", className)}
      id={MODULE_ID}
      path={MODULE_PATH}
      name={MODULE_TITLE}
      type={ListItemType.COST_REPORTS}
      outlined={true}
      clickable={true}
      exactPath={true}
      strictPath={true}
      icon={CostReportsIcon}
      isActive={(match, { pathname }) => pathname.indexOf(MODULE_PATH) >= 0}
    />
  );
};

export default CostReportsListItem;

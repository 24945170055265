import React from "react";
import { connect, useDispatch } from "react-redux";
import { AppSchema } from "@main/schemas";
import { toggleShowFeedbackDialog, updateFavoriteModules } from "@main/actions";
import {
  getCurrentAccountId,
  isInformationBannerVisible,
  isMaintenanceReminderVisible,
  isQualityReportsEnabled,
  isCostReportsEnabled,
  isUserPrincipal,
  getUserFavorites,
  getFavoriteModules,
  getAccountEnabledModules,
} from "@main/selectors";
import Navigation, { Actions, Model } from "../components/Navigation";
import getNavigationModules from "@main/selectors/getNavigationModules";
import { useGetFavorites } from "@hooks/uiPersonalization/use-get-favorites";
import { isUiPersonalizationEnabled, openBroadcastChannel } from "@util";
import { BROADCAST_CHANNEL_UPDATE_FAVORITES } from "@store/constants";

interface ContainerModel extends Model {
  favoriteModulesList?: string[];
}

interface ContainerActions extends Actions {
}

type Props = ContainerActions & ContainerModel;

const NavigationContainer = (props: Props) => {
  const {
    isUserPrincipal: isUser,
    favoriteModulesList = [],
    favoritesList = [],
    modulesList = [],
    ...otherProps
  } = props;

  // on first load, call get favorites API and update the favorites list
  const getFavorites = useGetFavorites();

  const filterList = React.useMemo(() => isUser ?
      isUiPersonalizationEnabled() ? favoriteModulesList : favoritesList : []
    , [isUser, favoriteModulesList, favoritesList]);

  const filteredFavorites = React.useMemo(() => modulesList.filter(module => filterList.indexOf(module.name) >= 0)
    , [modulesList, filterList]);

  React.useEffect(() => {
    if (isUser && isUiPersonalizationEnabled()) {
        getFavorites();
    }
  }, [isUser]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    // listen for changes to favorites from other tabs
    const listener = (broadcastEvent: MessageEvent) => {
      if (broadcastEvent.data.favorites) {
        dispatch(updateFavoriteModules(broadcastEvent.data.favorites));
      }
    };

    const { unsubscribe } = openBroadcastChannel(BROADCAST_CHANNEL_UPDATE_FAVORITES, listener);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Navigation
      {...otherProps}
      isUserPrincipal={isUser}
      favoriteModules={filteredFavorites}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): ContainerModel => ({
  accountId: getCurrentAccountId(state),
  showMaintenanceReminder: isMaintenanceReminderVisible(state),
  informationBannerVisible: isInformationBannerVisible(state),
  isUserPrincipal: isUserPrincipal(state),
  favoriteModulesList: getFavoriteModules(state),
  favoritesList: getUserFavorites(state),
  modules: getNavigationModules(state),
  modulesList: getAccountEnabledModules(state),
  showQualityReportsIconButton: isQualityReportsEnabled(state),
  showCostReports: isCostReportsEnabled(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  showFeedbackDialog: (value: boolean) => dispatch(toggleShowFeedbackDialog(value)),
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationContainer);

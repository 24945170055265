import { Record } from "immutable";
import { GetSmsTemplateParametersResponse, SmsParametersResponse, SmsRequirementsResponse } from "@network";
import SmsMessageType from "@data/SmsMessageType";
import { RegionalTemplateParameterInfoStatus } from "@data/RegionalTemplateParameterInfo";

export class SmsTemplateRequirements extends Record({
    messageTypes: {},
    parameters: {},
}) implements GetSmsTemplateParametersResponse {

    public static EMPTY: SmsTemplateRequirements = new SmsTemplateRequirements();

    public readonly messageTypes: SmsRequirementsResponse;
    public readonly parameters: SmsParametersResponse;

    public getParameterInfo(): SmsParametersResponse {
        return this.parameters;
    }

    public getAvailableMessageTypes(): SmsMessageType[] {
        const availableMessageTypes = this.messageTypes || {};
        return Object.keys(availableMessageTypes).map((key) => key as SmsMessageType);
    }

    public getDescriptionByMessageType(messageType: SmsMessageType): string {
        const info = this.messageTypes[messageType as string];
        return info ? info.description : "";
    }

    public getReqParamsByMessageType(messageType: SmsMessageType): string[] {
        const info = this.messageTypes[messageType as string];
        return info ? info.requiredParameters : [];
    }

    public getDescriptionByParameter(parameter: string): string {
        const info = this.parameters[parameter];
        return info ? info.description : "";
    }

    public getSyntaxByParameter(parameter: string): string {
        const info = this.parameters[parameter];
        return info ? info.syntax : "";
    }

    public getStatusByParameter(parameter: string): RegionalTemplateParameterInfoStatus {
        const info = this.parameters[parameter];
        return info ? info.status : RegionalTemplateParameterInfoStatus.NONE;
    }

    public getReplacementByParameter(parameter: string): string {
        const info = this.parameters[parameter];
        return info ? info.replacement : "";
    }
}

export default SmsTemplateRequirements;

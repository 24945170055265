import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSmsTemplateClient,
} from "@hooks";
import { GetSmsTemplateParametersResponse } from "@network";
import SmsTemplateRequirements from "@data/SmsTemplateRequirements";

type SuccessResponse = GetSmsTemplateParametersResponse;

export interface UseGetSmsTemplateParametersProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetSmsTemplateParametersModel extends UseApiRequestModel<SuccessResponse> {
  requirements: SmsTemplateRequirements;
}

export interface UseGetSmsTemplateParametersActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetSmsTemplateParametersProps;
type Model = UseGetSmsTemplateParametersModel;
type Actions = UseGetSmsTemplateParametersActions;
type Result = [Model, Actions];

export const useGetSmsTemplateParameters = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get SMS template requirements",
    ...otherProps
  } = props;

  const SmsTemplateClient = useSmsTemplateClient();

  const makeApiRequest = React.useCallback(
    () => SmsTemplateClient.getSmsTemplateParameters(),
    [SmsTemplateClient]);

  const [{successResponse, ...baseModel}, { ...baseActions} ] =
    useApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const requirements = React.useMemo(() => {
    const attrs = successResponse || {};
    return new SmsTemplateRequirements(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    requirements,
  }), [
    baseModel,
    requirements,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetSmsTemplateParameters;

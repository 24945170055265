import isPhoneNumberUserSupportEnabled from "@util/isPhoneNumberUserSupportEnabled";
import getPhoneNumberUserWhitelistedAccounts from "@util/getPhoneNumberUserWhitelistedAccounts";
import isPhoneNumberUserAccountWhitelistingEnabled from "@util/isPhoneNumberUserAccountWhitelistingEnabled";

export const SMS_TEMPLATES_REQUIREMENTS = {
  disabled: !isPhoneNumberUserSupportEnabled(),
  ...(!isPhoneNumberUserAccountWhitelistingEnabled() ? ({}) : ({
    whitelistedAccounts: getPhoneNumberUserWhitelistedAccounts(),
  })),
};

export default SMS_TEMPLATES_REQUIREMENTS;

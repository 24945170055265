import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps } from "@hooks";
import { WorkloadBatchPropertiesAttributes } from "@data";

type SuccessResponse = void;

export interface UseEditBatchPropertiesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  batchProperties: WorkloadBatchPropertiesAttributes;
  etag: string;
}

export interface UseEditBatchPropertiesModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseEditBatchPropertiesActions {
  editBatchProperties: () => void;
  reset: () => void;
}

type Props = UseEditBatchPropertiesProps;
type Model = UseEditBatchPropertiesModel;
type Actions = UseEditBatchPropertiesActions;
type Result = [Model, Actions];

export const useEditBatchProperties = (props: Props): Result => {

  const {
    name,
    version,
    batchProperties,
    etag,
    defaultErrorMessage = "Failed to edit workload Batch Properties",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const json = React.useMemo(() => JSON.stringify({
    batchProperties: {
      repositoryAlias: batchProperties.repositoryAlias,
      containerImageTag: batchProperties.containerImageTag,
      command: batchProperties.command,
      roles: batchProperties.roles,
      timeout: batchProperties.timeout,
      vCpu: batchProperties.runtimePlatform.vCpu,
      memory: batchProperties.runtimePlatform.memory,
    }
  }), [batchProperties]);

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.updateWorkloadPropertiesApi(name, version, etag, json),
    [WorkloadManagerClient, name, version, etag, json]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: editBatchProperties, reset }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Edit Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    editBatchProperties,
    reset
  }), [
    editBatchProperties,
    reset
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEditBatchProperties;

import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import CostHeaderCard from "./CostHeaderCard";
import { useMediaQuery } from "@material-ui/core";
import GroupedMetricGraph from "./GroupedMetricGraph";
import MetadataAutocomplete from "./MetadataAutocomplete";
import { CostReportingList, DropdownMenu } from "@components";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { CostReportingGroupedMetric, CostReportingMetadata } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UseGetCostReportingMetricsActions, UseGetCostReportingMetricsModel } from "@hooks/costReporting";

export interface CostReportsModel {
  className?: string;
  title?: string;
  showFilters?: boolean;
  graphUnit?: string;
  periodType?: string;
  resourceType?: string;
  metricPartition?: string;
  domainType?: string;
  groupType?: string;
  categoryType?: string;
  metricType?: string;
  metricModel?: UseGetCostReportingMetricsModel;
  groupedMetrics?: CostReportingGroupedMetric[];
  loadingGroupedMetrics?: boolean;
  metadata?: CostReportingMetadata[];
  loadingMetadata?: boolean;
}

export interface CostReportsActions {
  metricActions?: UseGetCostReportingMetricsActions;
  setShowFilters?: (value: boolean) => void;
  setGraphUnit?: (unit: string) => void;
  setPeriodType?: (periodType: string) => void;
  setResourceType?: (resourceType: string) => void;
  setMetricPartition?: (metricPartition: string) => void;
  setDomainType?: (domainType: string) => void;
  setGroupType?: (groupType: string) => void;
  setCategoryType?: (categoryType: string) => void;
  setMetricType?: (metricType: string) => void;
}

type Model = CostReportsModel;
type Actions = CostReportsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CostReports = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showFilters,
    graphUnit,
    periodType,
    resourceType,
    metricPartition,
    domainType,
    groupType,
    categoryType,
    metricType,
    metricModel,
    metricActions,
    metadata = [],
    groupedMetrics,
    loadingGroupedMetrics = false,
    loadingMetadata = false,
    setShowFilters = noop,
    setGraphUnit = noop,
    setPeriodType = noop,
    setResourceType = noop,
    setMetricPartition = noop,
    setDomainType = noop,
    setGroupType = noop,
    setCategoryType = noop,
    setMetricType = noop,
    children,
  } = props;

  const metadataValues = React.useMemo(() => {
    return metadata.reduce((obj, item) => {
      obj[item.getMetadataKey()] = item.getMetadataValue();
      return obj;
    }, {} as Record<string, string>);
  }, [metadata]);

  const mapMetadataKeyToLabel = React.useCallback((key: string = "") => {
    return metadataValues[key] || key;
  }, [metadataValues]);

  const setMetadataValue = (option: any, func: (value: any) => void) => {
    func(option instanceof CostReportingMetadata ? option.getMetadataKey() : "");
  };

  const periodValues = React.useMemo(() => {
    return !metadata.length ? ["MONTHLY"] : metadata.filter(
      item => item.metadataGroupKey === "PERIOD").map(i => i.getMetadataKey());
  }, [metadata]);

  const partitionValues = React.useMemo(() => {
    return metadata.filter(item => item.metadataGroupKey === "PARTITION");
  }, [metadata]);

  const resourceValues = React.useMemo(() => {
    return metadata.filter(item => item.metadataGroupKey === "RESOURCE");
  }, [metadata]);

  const domainValues = React.useMemo(() => {
    return metadata.filter(item => item.metadataGroupKey === "DOMAIN");
  }, [metadata]);

  const groupValues = React.useMemo(() => {
    return metadata.filter(item => item.metadataGroupKey === "GROUP");
  }, [metadata]);

  const categoryValues = React.useMemo(() => {
    return metadata.filter(item => item.metadataGroupKey === "CATEGORY");
  }, [metadata]);

  const metricValues = React.useMemo(() => {
    return metadata.filter(item => item.metadataGroupKey === "METRIC");
  }, [metadata]);

  const dropdownDisabled = React.useMemo(() => {
    return loadingMetadata || loadingGroupedMetrics;
  }, [loadingMetadata, loadingGroupedMetrics]);

  const activeFilters = React.useMemo(() => {
    return [
      periodType,
      resourceType,
      metricPartition,
      domainType,
      groupType,
      categoryType,
      metricType,
    ].filter(value => !isEmptyString(value)).length;
  }, [periodType, resourceType, metricPartition, domainType, groupType, categoryType, metricType]);

  const smallViewport = useMediaQuery("(max-width:1300px)");

  return (
    <div
      className={classnames("costReports", className, classes.container,
        { [classes.columnContainer]: smallViewport }
      )}
    >
      <div
        className={classnames(
          { [classes.leftContainer]: showFilters },
          { [classes.fullLeftContainer]: !showFilters || smallViewport }
        )}
      >
        <GroupedMetricGraph
          showFilters={showFilters}
          graphUnit={graphUnit}
          periodType={periodType}
          resourceType={resourceType}
          metricPartition={metricPartition}
          groupedMetrics={groupedMetrics}
          loadingGroupedMetrics={loadingGroupedMetrics}
          setShowFilters={setShowFilters}
          mapMetadataKeyToLabel={mapMetadataKeyToLabel}
        />
        {!isEmptyString(resourceType) && !loadingGroupedMetrics && (
          <div className={classnames("graphUnit", classes.graphUnit)}>
            <ToggleButtonGroup
              value={graphUnit}
              exclusive={true}
              onChange={(_, unit) => setGraphUnit(unit)}
              aria-label="Graph Unit"
            >
              <ToggleButton value="COST">{resourceType} Cost</ToggleButton>
              <ToggleButton value="COUNT">{resourceType} Count</ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        <CostReportingList {...metricModel} {...metricActions} mapMetadataKeyToLabel={mapMetadataKeyToLabel} />
      </div>
      {showFilters && (
        <div
          className={classnames("rightContainer", classes.rightContainer,
            { [classes.fullRightContainer]: smallViewport }
          )}
        >
          <div className={classnames("headers", classes.headers)}>
            <CostHeaderCard
              title="Active Filters"
              subtitle={`${activeFilters}`}
              showLoadingIndicator={loadingGroupedMetrics}
            />
          </div>
          <div className={classnames("headerFilterCard", classes.headerCard, classes.headerFilterCard)}>
            <DropdownMenu
              className={classnames("periodType", classes.dropdown)}
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              disabled={dropdownDisabled}
              fullWidth={true}
              hideEmptyValue={true}
              variant="outlined"
              values={periodValues}
              dropdownMenuLabel="Granularity"
              dropdownMenuHint="Filter by a specific granularity"
              selectedValue={periodType}
              setSelectedValue={setPeriodType}
              mapValueToLabel={mapMetadataKeyToLabel}
            />
            <MetadataAutocomplete
              className={classnames("filterByPartition", classes.autocomplete)}
              label="Partition By"
              placeholder="No partition selected"
              helperText="Select how to partition the graph"
              options={partitionValues}
              disabled={dropdownDisabled}
              value={mapMetadataKeyToLabel(metricPartition)}
              setValue={(option) => setMetadataValue(option, setMetricPartition)}
            />
            <MetadataAutocomplete
              className={classnames("filterByResource", classes.autocomplete)}
              label="Resource"
              placeholder="No resource selected"
              helperText="Filter by a specific resource"
              options={resourceValues}
              disabled={dropdownDisabled}
              value={mapMetadataKeyToLabel(resourceType)}
              setValue={(option) => setMetadataValue(option, setResourceType)}
            />
            <MetadataAutocomplete
              className={classnames("filterByDomain", classes.autocomplete)}
              label="Domain"
              placeholder="No domain selected"
              helperText="Filter by a specific domain"
              options={domainValues}
              disabled={dropdownDisabled}
              value={mapMetadataKeyToLabel(domainType)}
              setValue={(option) => setMetadataValue(option, setDomainType)}
            />
            <MetadataAutocomplete
              className={classnames("filterByGroup", classes.autocomplete)}
              label="Group"
              placeholder="No group selected"
              helperText="Filter by a specific group"
              options={groupValues}
              disabled={dropdownDisabled}
              value={mapMetadataKeyToLabel(groupType)}
              setValue={(option) => setMetadataValue(option, setGroupType)}
            />
            <MetadataAutocomplete
              className={classnames("filterByCategory", classes.autocomplete)}
              label="Category"
              placeholder="No category selected"
              helperText="Filter by a specific category"
              options={categoryValues}
              disabled={dropdownDisabled}
              value={mapMetadataKeyToLabel(categoryType)}
              setValue={(option) => setMetadataValue(option, setCategoryType)}
            />
            <MetadataAutocomplete
              className={classnames("filterByMetric", classes.autocomplete)}
              label="Metric"
              placeholder="No metric selected"
              helperText="Filter by a specific metric"
              options={metricValues}
              disabled={dropdownDisabled}
              value={mapMetadataKeyToLabel(metricType)}
              setValue={(option) => setMetadataValue(option, setMetricType)}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

export default CostReports;

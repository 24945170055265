import { Record } from "immutable";

export enum RegionalTemplateParameterInfoStatus {
    NONE = "",
    DEPRECATED = "DEPRECATED",
}

export interface RegionalTemplateParameterInfoAttrs {
    description: string;
    replacement: string;
    syntax: string;
    status: RegionalTemplateParameterInfoStatus;
}

export class RegionalTemplateParameterInfo extends Record({
    description: "",
    replacement: "",
    syntax: "",
    status: RegionalTemplateParameterInfoStatus.NONE,

}) implements RegionalTemplateParameterInfoAttrs {

    public static EMPTY: RegionalTemplateParameterInfo = new RegionalTemplateParameterInfo();

    public readonly description: string;
    public readonly replacement: string;
    public readonly syntax: string;
    public readonly status: RegionalTemplateParameterInfoStatus;

}

export default RegionalTemplateParameterInfo;

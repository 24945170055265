import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, isEmptyString, noop } from "@util";
import { templateBody as styles } from "./styles";
import { TextField } from "@components";
import { RegionalTemplateParameterInfoStatus, SmsMessageType, SmsTemplateRequirements, ValidateSmsTemplateRequest } from "@data";
import VerifyFieldsView, { VerifyAndCheck } from "@modules/emailTemplateWizard/components/VerifyFieldsView";
import { useValidateSmsTemplate } from "@hooks";

export interface Model {
  body?: string;
  description?: string;
  messageType?: SmsMessageType;
  requirements: SmsTemplateRequirements;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  setMessageType?: (type: SmsMessageType) => void;
  setBody?: (body: string) => void;
  setValidated?: (validated: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SmsTemplateBody = withStyles(styles)((props: Props) => {

  const {
    classes,
    body = "",
    description = "",
    messageType = SmsMessageType.MESSAGE_SIGNUP,
    requirements,
    showLoadingIndicator = false,
    setBody = noop,
    setValidated = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  const reqParams = requirements?.getReqParamsByMessageType(messageType);

  const deprecatedMap = React.useMemo(() => {
    let map = {};
    const allParams = Object.keys(requirements.getParameterInfo());
    allParams.map((param) => {
      if (requirements.getStatusByParameter(param) === RegionalTemplateParameterInfoStatus.DEPRECATED) {
        const replacement = requirements.getReplacementByParameter(param);
        if (reqParams.includes(replacement)) { // only interested in required parameters
          map[replacement] = map[replacement] ? map[replacement].concat(param) : [param];
        }
      }
    });
    return map;
  }, [reqParams, requirements]);

  const [validateMessage, setValidateMessage] = React.useState("");

  const templateBody = React.useMemo(() => new ValidateSmsTemplateRequest({
    messageType,
    body: {
      description,
      textBody: body
    }}), [messageType, description, body]);

  const [
    { successMessage, errorMessage, showSuccessView, showLoadingIndicator: loading }
  ] = useValidateSmsTemplate({ templateBody });

  React.useEffect(() => {
    if (!isEmptyString(successMessage)) {
      setValidateMessage(successMessage);
    }
    if (!isEmptyString(errorMessage)) {
      setValidateMessage(errorMessage);
    }
  }, [successMessage, errorMessage, setValidateMessage]);

  React.useEffect(() => {
    setValidated(showSuccessView);
  }, [showSuccessView, setValidated]);

  return (
    <div className={classnames("smsTemplateBody", classes.container)}>
      <div className={classnames("requiredParameters", classes.column, classes.parameters)}>
        <label className={classes.title}>
          Requirements
        </label>
        {reqParams.map((param) => {
          const list = [param].concat(deprecatedMap[param]);
          return (
            <VerifyFieldsView
              key={param}
              name="SMS Message Body"
              value={body}
              check={{ [param]: list}}
              condition={VerifyAndCheck.EXISTS}
            />
          );
        })
      }
      {!loading && (
        <VerifyFieldsView
          key="validate"
          name="SMS Message Body"
          value={validateMessage}
          check={showSuccessView}
          condition={VerifyAndCheck.TRUTH}
        />
      )}
      </div>
      <div className={classnames("messageContainer", classes.column, classes.messageContainer)}>
        <label className={classes.title}>
          SMS Message Body
        </label>
        <TextField
          className={classnames("body", classes.textField)}
          type="text"
          variant="outlined"
          autoComplete="off"
          margin="none"
          fullWidth={true}
          name="body"
          value={body}
          inputProps={inputProps}
          required={true}
          disabled={showLoadingIndicator}
          placeholder="SMS template message body"
          onChange={formEventHandler(setBody)}
          FormHelperTextProps={formHelperTextProps}
          multiline={true}
          minRows={3}
          maxRows={3}
        />
      </div>
    </div>
  );
});

export default SmsTemplateBody;

import React from "react";
import classnames from "classnames";
import { AlertSeverity, MarkdownView, MissingAlertView } from "@components";
import SummaryView from "@components/summary-view";
import Typography from "@material-ui/core/Typography";
import ReleaseNoteIssuesList from "./ReleaseNoteIssuesList";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ReleaseNoteIssue, ReleaseNoteIssueType, SummaryViewData } from "@data";
import DetailsView, { DetailsViewActions, DetailsViewModel } from "@components/details-view";
import styles, { PrimaryIcon } from "./styles";
import { ReleaseNoteIssueStatusKey } from "./ReleaseNoteIssueStatusKey";

export interface ReleaseNoteDetailsModel extends DetailsViewModel {
  className?: string;
  loadingTitle?: string;
  introduction?: string;
  description?: string;
  summaryViewClassName?: string;
  summaryViewItems?: SummaryViewData[];
  issues?: ReleaseNoteIssue[];
  downtime?: boolean;
  downtimeDescription?: string;
}

export interface ReleaseNoteDetailsActions extends DetailsViewActions {
}

type Model = ReleaseNoteDetailsModel;
type Actions = ReleaseNoteDetailsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReleaseNoteDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    icon = PrimaryIcon,
    showLoadingIndicator,
    introduction,
    description,
    loadingTitle = "Loading Release Note Details...",
    title: normalTitle = "Release Note Details",
    summaryViewClassName,
    summaryViewItems = [],
    issues = [],
    downtime = false,
    downtimeDescription = "",
    children,
    ...otherProps
  } = props;

  const title = React.useMemo(() =>
      showLoadingIndicator ? loadingTitle : normalTitle,
    [showLoadingIndicator, loadingTitle, normalTitle]);

  const newFeatures = React.useMemo(() =>
      issues.filter(issue => issue.getIssueType() === ReleaseNoteIssueType.NEW_FEATURES),
    [issues]);

  const defectFixes = React.useMemo(() =>
      issues.filter(issue => issue.getIssueType() === ReleaseNoteIssueType.DEFECT_FIXES),
    [issues]);

  const otherEnhancements = React.useMemo(() =>
      issues.filter(issue => issue.getIssueType() === ReleaseNoteIssueType.OTHER_ENHANCEMENTS),
    [issues]);

  const knownIssues = React.useMemo(() =>
      issues.filter(issue => issue.getIssueType() === ReleaseNoteIssueType.KNOWN_ISSUES),
    [issues]);

  const changeRequests = React.useMemo(() =>
      issues.filter(issue => issue.getIssueType() === ReleaseNoteIssueType.CHANGE_REQUEST),
    [issues]);

  const breakingChanges = React.useMemo(() =>
      issues.filter(issue => issue.getIssueType() === ReleaseNoteIssueType.BREAKING_CHANGES),
    [issues]);

  return (
    <DetailsView
      {...otherProps}
      className={classnames("releaseNoteDetails", className, classes.container)}
      icon={icon}
      title={title}
      showLoadingIndicator={showLoadingIndicator}
    >
      {downtime && (
        <MissingAlertView
          className={classnames("downtimeAlert", classes.downtimeAlert)}
          severity={AlertSeverity.ERROR}
          title={"Downtime Alert"}
          message={(
            <React.Fragment>
              <Typography>
                {downtimeDescription}
              </Typography>
            </React.Fragment>
          )}
        />
      )}
      <SummaryView
        className={classnames("summaryView", summaryViewClassName, classes.summaryView)}
        items={summaryViewItems}
      >
        {introduction && (
          <div className={classnames("introduction", classes.introduction)}>
            <Typography
              className={classnames("introductionTitle", classes.introductionTitle)}
              variant="h3"
            >
              Introduction
            </Typography>
            <p className={classnames("introductionBody", classes.introductionBody)}>
              {introduction}
            </p>
            {description && (
              <MarkdownView className={classes.introDescription} markdown={description}/>
            )}
          </div>
        )}
        {!showLoadingIndicator && (
          <React.Fragment>
            <ReleaseNoteIssueStatusKey/>
            {breakingChanges.length > 0 && (
              <ReleaseNoteIssuesList
                className={classnames("breakingChanges", classes.releaseNoteIssues, classes.breakingChanges)}
                listClassName={classes.releaseNoteIssuesList}
                title="Breaking Changes"
                noResultsLabel="There are no breaking changes in this release"
                issues={breakingChanges}
              />
            )}
            {newFeatures.length > 0 && (
              <ReleaseNoteIssuesList
                className={classnames("newFeatures", classes.releaseNoteIssues, classes.newFeatures)}
                listClassName={classes.releaseNoteIssuesList}
                title="New Features"
                noResultsLabel="There are no new features in this release"
                issues={newFeatures}
              />
            )}
            {changeRequests.length > 0 && (
              <ReleaseNoteIssuesList
                className={classnames("changeRequests", classes.releaseNoteIssues, classes.changeRequests)}
                listClassName={classes.releaseNoteIssuesList}
                title="Change Requests"
                noResultsLabel="There are no change requests in this release"
                issues={changeRequests}
              />
            )}
            {otherEnhancements.length > 0 && (
              <ReleaseNoteIssuesList
                className={classnames("otherEnhancements", classes.releaseNoteIssues, classes.otherEnhancements)}
                listClassName={classes.releaseNoteIssuesList}
                title="Other Enhancements"
                noResultsLabel="There are no other enhancements in this release"
                issues={otherEnhancements}
              />
            )}
            {defectFixes.length > 0 && (
              <ReleaseNoteIssuesList
                className={classnames("defectFixes", classes.releaseNoteIssues, classes.defectFixes)}
                listClassName={classes.releaseNoteIssuesList}
                title="Defect Fixes"
                noResultsLabel="There are no defect fixes in this release"
                issues={defectFixes}
              />
            )}
            {knownIssues.length > 0 && (
              <ReleaseNoteIssuesList
                className={classnames("knownIssues", classes.releaseNoteIssues, classes.knownIssues)}
                listClassName={classes.releaseNoteIssuesList}
                title="Known Issues"
                noResultsLabel="There are no known issues in this release"
                issues={knownIssues}
              />
            )}
          </React.Fragment>
        )}
        {children}
      </SummaryView>
    </DetailsView>
  );
});

export default ReleaseNoteDetails;

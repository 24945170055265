const WHITELISTED_ACCOUNTS = process.env.REACT_APP_PHONE_NUMBER_USER_WHITELISTED_ACCOUNTS || "[]";

export const getPhoneNumberUserWhitelistedAccounts = (): string[] => {
  try {
    const whitelistedAccounts = JSON.parse(WHITELISTED_ACCOUNTS);
    return Array.isArray(whitelistedAccounts) ? whitelistedAccounts : [];
  } catch {
    return [];
  }
};

export default getPhoneNumberUserWhitelistedAccounts;

import React from "react";
import styles from "./styles";
import classnames from "classnames";
import Link from "@components/link";
import IconButton from "@components/icon-button";
import Collapse from "@material-ui/core/Collapse";
import LoadingView from "@components/loading-view";
import { CostReportingGroupedMetric } from "@data";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TrendingUp from "@material-ui/icons/TrendingUp";
import TrendingDown from "@material-ui/icons/TrendingDown";
import CostReportsIcon from "@material-ui/icons/MonetizationOn";
import { formatMetricDate } from "@modules/costReports/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { MODULE_PATH as COST_REPORTS_NOTES_PATH } from "@modules/costReports/constants";

export interface CostReportsModel {
  className?: string;
  title?: string;
  emptyViewTitle?: string;
  showErrorView?: boolean;
  showLoadingIndicator?: boolean;
  metrics?: CostReportingGroupedMetric[];
}

export interface CostReportsActions {
  refresh?: () => void;
}

type Model = CostReportsModel;
type Actions = CostReportsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CostReports = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Cost Reports",
    metrics = [],
    showErrorView,
    showLoadingIndicator,
    children,
  } = props;

  const [collapsed, setCollapsed] = React.useState(false);

  const showEmptyView = React.useMemo(() =>
    !showLoadingIndicator && (showErrorView || (metrics.length === 0)),
    [showLoadingIndicator, showErrorView, metrics]);

  const showLoadedView = React.useMemo(() =>
    !showLoadingIndicator && !showEmptyView,
    [showLoadingIndicator, showEmptyView]);

  const items = React.useMemo(() => [...metrics].reverse(), [metrics]);

  return (
    <div className={classnames("costReports", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <CostReportsIcon className={classnames("headerIcon", classes.headerIcon)} />
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
        <IconButton
          className={classnames("toggleCollapsedButton", classes.toggleCollapsedButton)}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed && <ExpandMore className={classnames("toggleCollapsedIcon", classes.toggleCollapsedIcon)} />}
          {!collapsed && <ExpandLess className={classnames("toggleCollapsedIcon", classes.toggleCollapsedIcon)} />}
        </IconButton>
      </div>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit={true}>
        <div className={classnames("content", classes.content)}>
          {showLoadingIndicator && (
            <LoadingView
              className={classnames("loadingView", classes.loadingView)}
              size={28}
            />
          )}
          {showEmptyView && (
            <div className={classnames("emptyView", classes.emptyView)}>
              <div className={classnames("emptyViewText", classes.emptyViewText)}>
                No monthly metric data could be found at this time
              </div>
            </div>
          )}
          {showLoadedView && (
            <React.Fragment>
              <div className={classnames("metricContain</div>er", classes.metricContainer)}>
                {items.map((metric, index) => {
                  const percent = index > 0 ? Math.round((items[index].getCostValue() -
                    items[index - 1].getCostValue()) / items[index - 1].getCostValue() * 100) : 0;
                  return (
                    <div key={index} className={classnames("metric", classes.metric)}>
                      <div className={classnames("metricTitle", classes.metricTitle)}>
                        {formatMetricDate("MONTHLY", metric.getDate())}
                      </div>
                      <div className={classnames("metricValue", classes.metricValue)}>
                        ${metric.getCostValue()}
                      </div>
                      <div className={classnames("metricPercent", classes.metricPercent)}>
                        {index > 0 && (
                          <React.Fragment>
                            {percent >= 0 && (
                              <TrendingUp className={classnames("percentIconUp", classes.percentIconUp)} />
                            )}
                            {percent < 0 && (
                              <TrendingDown className={classnames("percentIconDown", classes.percentIconDown)} />
                            )}
                            <div>{percent}% {percent >= 0 ? "increase" : "decrease"} from last period</div>
                          </React.Fragment>
                        )}
                        {index === 0 && <span>Total monthly cost</span>}
                      </div>
                    </div>
                  );
                }).slice(-3)}
              </div>
              <div className={classnames("linkContainer", classes.linkContainer)}>
                <Link
                  className={classnames("viewMoreLink", classes.viewMoreLink)}
                  href={COST_REPORTS_NOTES_PATH}
                >
                  View More
                </Link>
              </div>
            </React.Fragment>
          )}
        </div>
      </Collapse>
      {children}
    </div>
  );
});

export default CostReports;

import React from "react";
import useContainerRepositoryClient from "../use-container-repository-client";
import { ContainerRepository } from "@data";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseCreateRepositoryProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  repository: ContainerRepository;
}

export interface UseCreateRepositoryModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseCreateRepositoryActions extends UseApiRequestActions<SuccessResponse> {
  createRepository: () => void;
}

type Props = UseCreateRepositoryProps;
type Model = UseCreateRepositoryModel;
type Actions = UseCreateRepositoryActions;
type Result = [Model, Actions];

export const useCreateContainerRepository = (props: Props): Result => {

  const {
    defaultErrorMessage = "Failed to create container repository",
    repository = ContainerRepository.EMPTY,
    ...otherProps
  } = props;

  const ContainerRepositoryManagerClient = useContainerRepositoryClient();

  const jsonRepository = repository.toJS();

  if (!repository.isGeneric()) {
    delete jsonRepository.security;
  }

  const makeApiRequest = React.useCallback(() => {
    return ContainerRepositoryManagerClient.createContainerRepository(JSON.stringify(jsonRepository));
  }, [ContainerRepositoryManagerClient, jsonRepository]);

  const [{showSuccessView, ...baseModel}, {
    refresh,
    ...baseActions
  }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      deferRequest: true,
      defaultErrorMessage,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Repository created successfully" : ""
    , [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    createRepository: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateContainerRepository;

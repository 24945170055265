import { Primitive } from "@util";
import { User, UserProfileAttribute, UserProfileAttributeLanguage } from "@data";
import { UserProfileAttributesListColumn } from "./UserProfileAttributesListColumn";

export const filterResultsByNameFilter = (item: UserProfileAttribute,
                                          nameFilter: string) =>
  item.isNameFilterMatch(nameFilter);

export const mapItemToColumnValue = (item: UserProfileAttribute,
                                     column: UserProfileAttributesListColumn): Primitive => {

  switch (column) {
    case UserProfileAttributesListColumn.NAME:
      return item.getName();
    case UserProfileAttributesListColumn.VALUE:
      return item.getValueAsString();
    case UserProfileAttributesListColumn.DATA_TYPE:
      return item.getDataType();
    default:
      return "";
  }
};

export const mapUserToUserProfileAttribute =
  (user: User): UserProfileAttribute[] => {

    const schema = user.getSchema();

    return Object.keys(schema).map((name: string) => new UserProfileAttribute({
      name,
      dataType: schema[name],
      value: user.getProfileAttribute(name),
    }));
  };

export const mapLanguageToOptionName = (value: UserProfileAttributeLanguage) => {
  switch (value) {
    case UserProfileAttributeLanguage.BG_BG:
      return "Bulgarian (bg-BG)";
    case UserProfileAttributeLanguage.CS_CZ:
      return "Czech (cs-CZ)";
    case UserProfileAttributeLanguage.DA_DK:
      return "Danish (da-DK)";
    case UserProfileAttributeLanguage.DE_DE:
      return "German-DE (de-DE)";
    case UserProfileAttributeLanguage.EN_US:
      return "English-US (en-US)";
    case UserProfileAttributeLanguage.ES_ES:
      return "Spanish-ES (es-ES)";
    case UserProfileAttributeLanguage.FI_FI:
      return "Finnish (fi-FI)";
    case UserProfileAttributeLanguage.FR_FR:
      return "French-FR (fr-FR)";
    case UserProfileAttributeLanguage.HU_HU:
      return "Hungarian (hu-HU)";
    case UserProfileAttributeLanguage.IT_IT:
      return "Italian (it-IT)";
    case UserProfileAttributeLanguage.NL_NL:
      return "Dutch (nl-NL)";
    case UserProfileAttributeLanguage.NO_NO:
      return "Norwegian (no-NO)";
    case UserProfileAttributeLanguage.PL_PL:
      return "Polish (pl-PL)";
    case UserProfileAttributeLanguage.PT_PT:
      return "Portuguese (pt-PT)";
    case UserProfileAttributeLanguage.SK_SK:
      return "Slovak (sk-SK)";
    case UserProfileAttributeLanguage.SV_SE:
      return "Swedish (sv-SE)";
    case UserProfileAttributeLanguage.ZH_CN:
      return "Simplified Chinese (zh-CN)";
    case UserProfileAttributeLanguage.ZH_TW:
      return "Chinese Traditional (zh-TW)";
    case UserProfileAttributeLanguage.NONE:
      return "";
    default:
      return value;
  }
};

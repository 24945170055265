import React from "react";
import useContainerRepositoryClient from "../use-container-repository-client";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = void;

export interface UseUpdateRepositoryDescriptionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  id: string;
  description: string;
}

export interface UseUpdateRepositoryDescriptionModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseUpdateRepositoryDescriptionActions extends UseApiRequestActions<SuccessResponse> {
  updateDescription: () => void;
}

type Props = UseUpdateRepositoryDescriptionProps;
type Model = UseUpdateRepositoryDescriptionModel;
type Actions = UseUpdateRepositoryDescriptionActions;
type Result = [Model, Actions];

export const useUpdateContainerRepositoryDescription = (props: Props): Result => {

  const {
    id,
    description,
    defaultErrorMessage = "Failed to update container repository description",
    ...otherProps
  } = props;

  const ContainerRepositoryManagerClient = useContainerRepositoryClient();

  const makeApiRequest = React.useCallback(() => {
    return ContainerRepositoryManagerClient.updateContainerRepository(id,
      JSON.stringify({ description }));
  }, [ContainerRepositoryManagerClient, id, description]);

  const [{showSuccessView, ...baseModel}, {
    refresh,
    ...baseActions
  }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      deferRequest: true,
      defaultErrorMessage,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Repository description updated successfully" : ""
    , [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    updateDescription: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUpdateContainerRepositoryDescription;

import React from "react";
import { ContainerRepository } from "@data";
import { useCreateContainerRepository } from "@hooks";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel
} from "@components/submit-api-request-view";
import { RepositoryInfo } from "../components/RepositoryInfo";
import { isEmptyString, noop } from "@util";

interface ContainerModel extends SubmitApiRequestViewModel<ContainerRepository> {
}

interface ContainerActions extends SubmitApiRequestViewActions<ContainerRepository> {
  showRepositoryList?: () => void;
}

type Props = ContainerModel & ContainerActions;

export const CreateRepositoryContainer = (props: Props) => {

  const {
    showRepositoryList = noop,
    ...otherProps
  } = props;

  const [repository, setRepository] = React.useState<ContainerRepository>(new ContainerRepository());

  const [{ showErrorView, ...otherModel}, { createRepository, reset, ...otherActions}] =
    useCreateContainerRepository({ repository });

  const updateRepository = React.useCallback((newRepository: Partial<ContainerRepository>) => {
    if (showErrorView) {
      reset();
    }
    setRepository(new ContainerRepository({
      ...repository.toJS(),
      ...newRepository,
    }));
  }, [showErrorView, reset, repository]);

  const validRepo = React.useMemo(() => !isEmptyString(repository.getAlias()) && !isEmptyString(repository.getUrl())
      // username and password are required for GENERIC repositories
      && (!repository.isGeneric() || repository.hasUsernameAndPassword())
    , [repository]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      {...otherModel}
      {...otherActions}
      snackbarId="create-repository-snackbar"
      errorTitle="Create Repository Failed"
      saveButtonLabel="Create Repository"
      currentState={repository}
      defaultState={ContainerRepository.EMPTY}
      onSuccess={showRepositoryList}
      saveButtonDisabled={!validRepo}
      save={createRepository}
    >
      <RepositoryInfo
        repository={repository}
        setRepository={updateRepository}
      />
    </SubmitApiRequestView>
  );
};

export default CreateRepositoryContainer;

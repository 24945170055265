import React from "react";
import classnames from "classnames";
import TextField from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  enterKeyHandler,
  formEventHandler,
  noop,
} from "@util";
import { styles } from "./styles";

export interface WizardTextFieldModel {
  className?: string;
  inputLabelClassName?: string;
  type?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  name?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  shrinkInputLabel?: boolean;
  disableSpecialChars?: boolean;
  endAdornment?: React.ReactNode;
}

export interface WizardTextFieldActions {
  setValue?: (value: string) => void;
  onEnterPressed?: () => void;
}

type Props = WithStyles<typeof styles> & WizardTextFieldModel & WizardTextFieldActions;

export const WizardTextField = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    type = "text",
    value = "",
    label,
    placeholder,
    required = false,
    name,
    helperText,
    error,
    disabled,
    autoFocus,
    fullWidth = true,
    endAdornment,
    disableSpecialChars = false,
    setValue = noop,
    onEnterPressed = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classnames("textField", className, classes.textField)}
        label={label}
        placeholder={placeholder}
        name={name}
        type={type}
        variant="outlined"
        color="secondary"
        autoComplete="off"
        margin="none"
        required={required}
        fullWidth={fullWidth}
        value={value}
        inputProps={inputProps}
        helperText={helperText}
        error={error}
        disabled={disabled}
        autoFocus={autoFocus}
        disableSpecialChars={disableSpecialChars}
        InputLabelProps={inputLabelProps}
        InputProps={{ inputProps, endAdornment }}
        FormHelperTextProps={formHelperTextProps}
        onChange={formEventHandler(setValue)}
        onKeyDown={enterKeyHandler(onEnterPressed)}
      />
    </div>
  );
});

export default WizardTextField;

import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { noop } from "@util";
import { repositoryInfo as styles } from "./styles";
import { DropdownMenu, WizardTextField } from "@components";
import { ContainerRepository, RegistryType, SimpleCredentials } from "@data";

export interface Model {
  repository: ContainerRepository;
}

export interface Actions {
  setRepository?: (repository: ContainerRepository) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const RepositoryInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    repository,
    setRepository = noop,
  } = props;

  const updateSecurity = React.useCallback((credentials: Partial<SimpleCredentials>) => {
    setRepository({
      security: {
        authentication: {
          simpleCredentials: {
            ...repository.getCredentials(),
            ...credentials,
          }
        }
    }});
  }, [repository]);

  const isGeneric = React.useMemo(() => repository.isGeneric(), [repository]);

  return (
    <div className={classnames("repositoryInfo", classes.container)}>
      <div className={classnames("typeContainer", classes.dropdown)}>
        <DropdownMenu
          dropdownMenuLabel="Type *"
          values={[RegistryType.ECR, RegistryType.GENERIC]}
          selectedValue={repository.getType()}
          setSelectedValue={(selectedValue) => setRepository({...repository, type: selectedValue})}
          hideEmptyValue={true}
        />
      </div>
      <WizardTextField
        className={classnames("alias")}
        type="text"
        fullWidth={true}
        autoFocus={true}
        label="Alias"
        name="alisa"
        value={repository.getAlias()}
        required={true}
        placeholder="Alias"
        setValue={(alias) => setRepository({ alias })}
      />
      <WizardTextField
        className={classnames("repositoryUrl")}
        type="text"
        fullWidth={true}
        label="URL"
        name="repositoryUrl"
        value={repository.getUrl()}
        required={true}
        placeholder="URL"
        setValue={(url) => setRepository({...repository, url})}
      />
      <WizardTextField
        className={classnames("description")}
        type="text"
        fullWidth={true}
        label="Description"
        name="description"
        value={repository.getDescription()}
        required={false}
        placeholder="Description"
        setValue={(desc) => setRepository({...repository, description: desc})}
      />
      {isGeneric && (
        <React.Fragment>
          <WizardTextField
            className={classnames("username")}
            type="text"
            fullWidth={true}
            label="Username"
            name="username"
            value={repository.getUsername()}
            required={true}
            placeholder="Username"
            setValue={(name) => updateSecurity({ username: name })}
          />
          <WizardTextField
            className={classnames("password")}
            type="text"
            fullWidth={true}
            label="Password"
            name="password"
            value={repository.getPassword()}
            required={true}
            placeholder="Password"
            setValue={(pwd) => updateSecurity({ password: pwd })}
          />
        </React.Fragment>
        )}
    </div>
  );
});

export default RepositoryInfo;

import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import autocompleteStyles from "./styles";
import { CostReportingMetadata } from "@data";
import TextField from "@components/text-field";
import MetadataAutocompleteDropdown from "@material-ui/lab/Autocomplete";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface MetadataAutocompleteModel {
  options?: CostReportingMetadata[];
  className?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  helperTextClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  freeSolo?: boolean;
  multiple?: boolean;
  openOnFocus?: boolean;
  blurOnSelect?: boolean;
  selectOnFocus?: boolean;
  disableClearable?: boolean;
}

export interface MetadataAutocompleteActions {
  onOpen?: () => void;
  setValue?: (value: string[]) => void;
  onInputValueChange?: (inputValue: string) => void;
}

type Model = MetadataAutocompleteModel;
type Actions = MetadataAutocompleteActions;
type Props = WithStyles<typeof autocompleteStyles> & Model & Actions;

export const MetadataAutocomplete = withStyles(autocompleteStyles)((props: Props) => {

  const {
    classes,
    className,
    options = [],
    value = [],
    disabled,
    openOnFocus = true,
    multiple = false,
    blurOnSelect = true,
    selectOnFocus = true,
    disableClearable,
    freeSolo = false,
    label,
    loading,
    placeholder,
    helperText,
    helperTextClassName,
    onOpen = noop,
    setValue = noop,
    onInputValueChange = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("autocompleteFormHelperText", helperTextClassName),
    classes: {
      root: classes.autocompleteFormHelperText,
    },
  };

  const inputLabelProps = {
    className: "autocompleteInputLabel",
    shrink: true,
    classes: {
      root: classes.autocompleteInputLabel,
      shrink: classes.autocompleteInputLabelShrink,
      error: classes.error,
      focused: classes.focused,
    },
  };

  const autocompleteValue = React.useMemo(() => value === "" ? null : value, [value]);

  const onChange = React.useCallback((event, updatedValue) => setValue(updatedValue), [setValue]);

  const getOptionLabel = (option: any) => {
    return option instanceof CostReportingMetadata ? option.getMetadataValue() : option;
  };

  const getOptionSelected = (option: any, other: any) => {
    const optionValue = option instanceof CostReportingMetadata ? option.getMetadataValue() : option;
    const otherValue = other instanceof CostReportingMetadata ? other.getMetadataValue() : other;
    return optionValue === otherValue;
  };

  return (
    <MetadataAutocompleteDropdown
      className={classnames("autocomplete", className, classes.container)}
      classes={{
        tag: classnames(classes.autocompleteTag),
        option: classnames(classes.autocompleteOption),
        noOptions: classnames(classes.autocompleteOption),
      }}
      options={options}
      noOptionsText="No options available"
      value={autocompleteValue}
      loading={loading}
      disabled={disabled}
      openOnFocus={openOnFocus}
      multiple={multiple}
      blurOnSelect={blurOnSelect}
      selectOnFocus={selectOnFocus}
      disableClearable={disableClearable}
      freeSolo={freeSolo}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onOpen={(event) => onOpen()}
      onInputChange={(event, updatedValue) => onInputValueChange(updatedValue)}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          className={classnames("autocompleteTextField", classes.autocompleteTextField)}
          label={label}
          placeholder={placeholder}
          color="secondary"
          variant="outlined"
          InputLabelProps={inputLabelProps}
          helperText={helperText}
          FormHelperTextProps={formHelperTextProps}
          fullWidth={true}
          disabled={disabled || loading}
          InputProps={{
            ...InputProps,
            className: classnames(
              "autocompleteTextFieldInput",
              InputProps.className,
              classes.autocompleteTextFieldInput,
            ),
            classes: {
              input: classnames("autocompleteTextFieldInputPropsInput",
                classes.autocompleteTextFieldInputPropsInput),
            },
          }}
        />
      )}
    />
  );
});

export default MetadataAutocomplete;

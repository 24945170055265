export const formatMetricDate = (periodType: string = "MONTHLY", dateString: string = "") => {
    switch (periodType) {
        case "DAILY":
            return new Date(dateString).toLocaleDateString("en-US",
                { day: "numeric", month: "long", year: "numeric", timeZone: "UTC" });
        case "MONTHLY":
            return new Date(dateString).toLocaleDateString("en-US",
                { month: "long", year: "numeric", timeZone: "UTC" });
        case "YEARLY":
            return new Date(dateString).toLocaleDateString("en-US",
                { year: "numeric", timeZone: "UTC" });
        default:
            return dateString + "";
    }
};

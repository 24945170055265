import { Record } from "immutable";
import Policy, { PolicyAttributes } from "./Policy";
import { getStringValue, getUserPrincipal, isEmptyString } from "@util";
import WorkloadVariableSchema, { WorkloadVariableSchemaAttributes } from "./WorkloadVariableSchema";
import {
  WorkloadBatchProperties,
  WorkloadBatchPropertiesAttributes
} from "./WorkloadBatchProperties";

export enum WorkloadType {
  NONE = "NONE",
  FUNCTION = "FUNCTION",
  QUERY = "QUERY",
  BATCH = "BATCH",
}

export enum WorkloadQueryType {
  SELECT = "SELECT",
  INSERT = "INSERT",
}

export enum WorkloadState {
  NONE = "",
  DRAFT = "DRAFT",
  DEPLOYING = "DEPLOYING",
  DEPLOYMENT_FAILED = "DEPLOYMENT_FAILED",
  RELEASING = "RELEASING",
  RELEASED = "RELEASED",
  RELEASE_FAILED = "RELEASE_FAILED",
  DEPRECATED = "DEPRECATED",
  DECOMMISSIONING = "DECOMMISSIONING",
  DECOMMISSIONED = "DECOMMISSIONED",
  DECOMMISSION_FAILED = "DECOMMISSION_FAILED",
  DELETED = "DELETED",
}

export type WorkloadQueryTypeLabels<K extends keyof any = WorkloadQueryType> = {
  [P in K]: string;
};

export const DEFAULT_WORKLOAD_QUERY_TYPE_LABELS: WorkloadQueryTypeLabels = {
  [WorkloadQueryType.SELECT]: "Select",
  [WorkloadQueryType.INSERT]: "Insert",
};

export type WorkloadTypeLabels<K extends keyof any = WorkloadType> = {
  [P in K]: string;
};

export const DEFAULT_WORKLOAD_TYPE_LABELS: WorkloadTypeLabels = {
  [WorkloadType.NONE]: "NONE",
  [WorkloadType.FUNCTION]: "Function",
  [WorkloadType.QUERY]: "Query",
  [WorkloadType.BATCH]: "Batch",
};

export interface WorkloadCompletionMetadata {
  actions?: string[];
  variableSchemas?: WorkloadVariableSchemaAttributes[];
}

export interface WorkloadDataSetsAttributes {
  accountId: string;
  dataSetAlias: string;
}

export interface WorkloadData {
  inputs?: WorkloadDataSetsAttributes[];
  outputs?: WorkloadDataSetsAttributes[];
}

export interface QueryWorkloadAttributes {
  type?: WorkloadQueryType;
  query?: string;
  databaseName?: string;
  tableName?: string;
  upload?: boolean;
  overwrite?: boolean;
}

export interface WorkloadInternetConfig {
  accessibleDns: string[];
}

export interface WorkloadAttributes {
  name?: string;
  version?: number;
  description?: string;
  tags?: string[];
  type?: WorkloadType;
  eTag: string;
  data?: WorkloadData;
  state?: WorkloadState;
  stateReason?: string;
  entryPoint?: string;
  timeout?: number;
  memory?: number;
  runtime?: string;
  executingRole?: string;
  policy?: PolicyAttributes;
  query?: QueryWorkloadAttributes;
  completionMetadata?: WorkloadCompletionMetadata;
  workloadInternetConfig?: WorkloadInternetConfig;
  releasedBy?: string;
  batchWorkloadProperties?: WorkloadBatchPropertiesAttributes;
}

export class Workload extends Record({
  name: "",
  version: -1,
  description: "",
  tags: [],
  type: WorkloadType.NONE,
  eTag: "",
  data: {
    inputs: [],
    outputs: [],
  },
  state: WorkloadState.NONE,
  stateReason: "",
  entryPoint: "",
  timeout: 60,
  memory: 756,
  runtime: "",
  executingRole: "",
  policy: Policy.EMPTY.toJS(),
  batchWorkloadProperties: WorkloadBatchProperties.EMPTY.toJS(),
  query: {
    type: WorkloadQueryType.SELECT,
    query: "",
    databaseName: "",
    tableName: "",
    upload: false,
    overwrite: false,
  },
  completionMetadata: {
    actions: [],
    variableSchemas: [],
  },
  workloadInternetAccessConfig: {
    internetAccess: false,
    whitelistedDnsNames: [],
  },
  workloadInternetConfig: {
    accessibleDns: [],
  },
  releasedBy: "",
}) implements WorkloadAttributes {

  public static EMPTY: Workload = new Workload();

  public readonly name: string;
  public readonly version: number;
  public readonly description: string;
  public readonly tags: string[];
  public readonly type: WorkloadType;
  public readonly eTag: string;
  public readonly data: WorkloadData;
  public readonly state: WorkloadState;
  public readonly stateReason: string;
  public readonly entryPoint: string;
  public readonly timeout: number;
  public readonly memory: number;
  public readonly runtime: string;
  public readonly executingRole: string;
  public readonly policy: PolicyAttributes;
  public readonly query: QueryWorkloadAttributes;
  public readonly completionMetadata: WorkloadCompletionMetadata;
  public readonly workloadInternetConfig: WorkloadInternetConfig;
  public readonly releasedBy: string;
  public readonly batchWorkloadProperties: WorkloadBatchPropertiesAttributes;

  public static from(workloadName: string): Workload {

    return new Workload({
      name: workloadName,
    });
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getVersion(): number {
    return this.version;
  }

  public getVersionAsString(): string {
    const version = this.getVersion();
    return version >= 0 ? `${version}` : "";
  }

  public getVersionNumOrLatest(): number | "latest" {
    const version = this.getVersion();
    return version > 0 ? version : "latest";
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getTags(): string[] {
    const tags = this.tags;
    return !Array.isArray(tags) ? [] : tags.filter(tag => !isEmptyString(tag));
  }

  public getType(): WorkloadType {
    return this.type;
  }

  public getEtag(): string {
    return getStringValue(this.eTag);
  }

  public getData(): WorkloadData {
    const { inputs = [], outputs = [] } = this.data || {};
    return {
      inputs,
      outputs,
    };
  }

  public getDataInputs(): WorkloadDataSetsAttributes[] {
    const { inputs = [] } = this.getData();
    return inputs;
  }

  public getDataOutputs(): WorkloadDataSetsAttributes[] {
    const { outputs = [] } = this.getData();
    return outputs;
  }

  public getState(): WorkloadState {
    return this.state || WorkloadState.NONE;
  }

  public getStateReason(): string {
    return getStringValue(this.stateReason);
  }

  public isWorkloadInFailedState(): boolean {
    return this.getState() === WorkloadState.DEPLOYMENT_FAILED
      || this.getState() === WorkloadState.DECOMMISSION_FAILED
      || this.getState() === WorkloadState.RELEASE_FAILED;
  }

  public getStateAsString(): string {
    return getStringValue(this.getState());
  }

  public isDraft(): boolean {
    return this.getState() === WorkloadState.DRAFT;
  }

  public isDeployFailed(): boolean {
    return this.getState() === WorkloadState.DEPLOYMENT_FAILED;
  }

  public isReleased(): boolean {
    return this.getState() === WorkloadState.RELEASED;
  }

  public isDeprecated(): boolean {
    return this.getState() === WorkloadState.DEPRECATED;
  }

  public isDecommissioned(): boolean {
    return this.getState() === WorkloadState.DECOMMISSIONED;
  }

  public getEntryPoint(): string {
    return getStringValue(this.entryPoint);
  }

  public getTimeout(): number {
    return this.timeout;
  }

  public getTimeoutAsString(): string {
    return this.timeout + "s";
  }

  public getMemory(): number {
    return this.memory;
  }

  public getMemoryAsString(): string {
    return this.memory + "MB";
  }

  public getRuntime(): string {
    return getStringValue(this.runtime);
  }

  public getTypeLabel(): string {
    return DEFAULT_WORKLOAD_TYPE_LABELS[this.getType()];
  }

  public getExecutingRole(): string {
    return getStringValue(this.executingRole);
  }

  public hasExecutingRole(): boolean {
    return !isEmptyString(this.getExecutingRole());
  }

  public getPolicy(): Policy {
    return new Policy(this.policy);
  }

  public hasPolicy(): boolean {
    return this.getPolicy().isValid();
  }

  public getQueryData(): QueryWorkloadAttributes {
    return this.query;
  }

  public getDatabaseName(): string {
    const { databaseName = "" } = this.query;
    return databaseName;
  }

  public getTableName(): string {
    const { tableName = "" } = this.query;
    return tableName;
  }

  public getQueryType(): WorkloadQueryType {
    const { type = WorkloadQueryType.SELECT } = this.query;
    return type;
  }

  public getQuery(): string {
    const { query = "" } = this.query;
    return query;
  }

  public isOverwrite(): boolean {
    const { overwrite = false } = this.query;
    return overwrite;
  }

  public isUpload(): boolean {
    const { upload = false } = this.query;
    return upload;
  }

  public isFunctionType(): boolean {
    return this.type === WorkloadType.FUNCTION;
  }

  public isQueryType(): boolean {
    return this.type === WorkloadType.QUERY;
  }

  public isBatchType(): boolean {
    return this.type === WorkloadType.BATCH;
  }

  public isSelectQuery(): boolean {
    const { type = "" } = this.query;
    return this.type === WorkloadType.QUERY && type === WorkloadQueryType.SELECT;
  }

  public isInsertQuery(): boolean {
    const { type = "" } = this.query;
    return this.type === WorkloadType.QUERY && type === WorkloadQueryType.INSERT;
  }

  public getWorkloadCompletionMetadata(): WorkloadCompletionMetadata {
    const {
      actions = [],
      variableSchemas = [],
    } = this.completionMetadata || {};
    return {
      actions: Array.isArray(actions) ? actions : [],
      variableSchemas: Array.isArray(variableSchemas) ? variableSchemas : [],
    };
  }

  public getWorkloadCompletionMetadataActions(): string[] {
    const { actions = [] } = this.getWorkloadCompletionMetadata();
    return actions.map(getStringValue).filter(it => !isEmptyString(it));
  }

  public getWorkloadCompletionMetadataVariableSchemas(): WorkloadVariableSchema[] {
    const { variableSchemas = [] } = this.getWorkloadCompletionMetadata();
    return variableSchemas
      .map(attrs => new WorkloadVariableSchema(attrs))
      .filter(variableSchema => variableSchema.hasName());
  }

  public getInternetAccessConfiguration(): WorkloadInternetConfig {
    return this.workloadInternetConfig || { accessibleDns: [] };
  }

  public getAccessibleDns(): string[] {
    const { accessibleDns = [] } = this.getInternetAccessConfiguration();
    return Array.isArray(accessibleDns) ? accessibleDns : [];
  }

  public getReleasedBy(): string {
    return getUserPrincipal(this.releasedBy || "");
  }

  public getBatchWorkloadProperties(): WorkloadBatchProperties {
    return new WorkloadBatchProperties(this.batchWorkloadProperties);
  }
}

export default Workload;

import React from "react";
import { Actions, CodeInfoView, Model } from "../components/CodeInfoView";
import {
  useGetUploadWorkloadCodeUrl,
  useGetWorkloadCode,
  useGetWorkloadCodePackagingStatus,
  useUploadWorkloadCode,
} from "@hooks/workloadsManager";
import { isEmptyString, noop } from "@util";
import {
  DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS,
  WorkloadRuntimeOption,
  WorkloadRuntimeOptionLabels,
} from "@data/CreateWorkloadRequest";
import { SummaryViewData, WorkloadCodePackagingStatus } from "@data";

interface ContainerModel extends Model {
  workloadName?: string;
  version?: number;
  isDraftWorkload?: boolean;
  fileType?: string;
  entryPoint?: string;
  runtime?: string;
  eTag?: string;
  lastModified?: string;
  contentLength?: string;
  originalFilename?: string;
  showLoadingIndicatorHead?: boolean;
  codeUploaded?: boolean;
  codeUploadDisabled?: boolean;
  codePackingStatus?: WorkloadCodePackagingStatus;
  codePackagingError?: string;
  runtimeOptionLabels?: WorkloadRuntimeOptionLabels;
}

interface ContainerActions extends Actions {
  refresh?: () => void;
  editEntryPoint?: () => void;
  setPyCodePackaged?: (value: boolean) => void;
  setTestWorkloadLoading?: (value: boolean) => void;
  mapRuntimeOptionToLabel?: (type: WorkloadRuntimeOption) => React.ReactNode | string | null;
}

type Props = ContainerModel & ContainerActions;

const CodeInfoViewContainer = (props: Props) => {

  const {
    workloadName = "",
    version = -1,
    isDraftWorkload = false,
    entryPoint = "",
    runtime = "",
    eTag = "",
    lastModified = "",
    contentLength = "",
    fileType = "jar",
    originalFilename = "",
    codePackingStatus = WorkloadCodePackagingStatus.IN_PROGRESS,
    codePackagingError,
    showLoadingIndicatorHead,
    codeUploaded,
    codeUploadDisabled,
    editEntryPoint = noop,
    refresh = noop,
    setPyCodePackaged = noop,
    setTestWorkloadLoading = noop,
    runtimeOptionLabels = DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS,
    mapRuntimeOptionToLabel = React.useCallback((type: WorkloadRuntimeOption) =>
      runtimeOptionLabels[type] || null, [ runtimeOptionLabels ]),
  } = props;

  const [ downloading, setDownloading ] = React.useState(false);
  const [ uploading, setUploading ] = React.useState(false);
  const [ codeHref, setCodeHref ] = React.useState("");
  const [ file, setFile ] = React.useState(new File([], ""));
  const [ validationErrorMessage, setValidationErrorMessage ] = React.useState("");
  const [ statusCode, setStatusCode ] = React.useState<number | undefined>();
  const [ saveCodeRequest, setSaveCodeRequest] = React.useState(false);
  const [zipFile, setZipFile] = React.useState<any>(null);
  const [retries, setRetries] = React.useState(0);

  const fileSelected = React.useMemo(() => file?.size !== 0, [ file ]);
  const fileName = React.useMemo(() => isEmptyString(originalFilename) ? workloadName : originalFilename
    , [ originalFilename, workloadName ]);

  const [ {
    showLoadingIndicator: fileUploadUrlLoading,
    showSuccessView: fileUploadUrlSuccessView,
    showErrorView: fileUploadUrlErrorView,
    errorMessage: uploadUrlErrorMessage,
    statusCode: uploadUrlStatusCode,
    url,
  }, {
    getUploadUrl,
    reset,
  } ] = useGetUploadWorkloadCodeUrl({ workloadName, version, fileName });

  const uploadUrl = React.useMemo(() => url, [ url ]);

  const [ {
    showLoadingIndicator: fileUploadLoading,
    showSuccessView: fileUploadSuccessView,
    showErrorView: fileUploadErrorView,
    errorMessage: uploadErrorMessage,
    statusCode: uploadStatusCode,
  }, {
    uploadCode,
    reset: resetUpload,
  } ] = useUploadWorkloadCode({ uploadUrl, file });

  const [ {
    showLoadingIndicator: fileDownloadLoading,
    url: downloadCodeURL,
    showSuccessView: showDownloadCodeSuccessView,
    showErrorView: showDownloadErrorView,
    errorMessage: downloadErrorMessage,
  }, {
    download,
  } ] = useGetWorkloadCode({ workloadName, version });

  const [{
    status: updatedStatus,
    errorMessage: updatedErrorMessage
  }, { refresh: getPackagingStatus }] =
    useGetWorkloadCodePackagingStatus({ name: workloadName, version, deferRequest: true });

  const onSelectFile = React.useCallback(event => {

    reset();
    resetUpload();

    const selectedFile = event && event.target && event.target.files
      ? event.target.files[0] : undefined;

    if (!selectedFile) {
      return setValidationErrorMessage("Invalid File");
    }

    if (!isEmptyString(fileType)) {
      if (selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1) !== fileType) {
        return setValidationErrorMessage(`Invalid File! File format should be .${fileType}`);
      }
    }

    setFile(selectedFile);
    setValidationErrorMessage("");

  }, [ fileType, setFile, reset, setValidationErrorMessage ]);

  const onDrop = React.useCallback(([ selectedFile ]) => {

    reset();
    resetUpload();

    if (!selectedFile) {
      return setValidationErrorMessage("Invalid File");
    }

    if (!isEmptyString(fileType)) {
      if (selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1) !== fileType) {
        return setValidationErrorMessage(`Invalid File! File format should be .${fileType}`);
      }
    }

    setFile(selectedFile);
    setValidationErrorMessage("");

  }, [ fileType, setFile, reset, setValidationErrorMessage ]);

  const fileUploadErrorMessage = React.useMemo(() =>
      uploadUrlErrorMessage || validationErrorMessage || uploadErrorMessage,
    [ uploadUrlErrorMessage, validationErrorMessage, uploadErrorMessage ]);

  const isPythonWorkload = React.useMemo(() =>
    runtime === WorkloadRuntimeOption.PYTHON312, [runtime]);

  const codePackagingDone = React.useMemo(() =>
  updatedStatus === WorkloadCodePackagingStatus.SUCCESS || updatedStatus === WorkloadCodePackagingStatus.FAILED,
    [updatedStatus]);

  const codePackagingSuccess = React.useMemo(() =>
    updatedStatus && updatedStatus === WorkloadCodePackagingStatus.SUCCESS, [updatedStatus]);

  const codeInfoSummaryViewItems = React.useMemo(() =>
      showLoadingIndicatorHead ? [] : [
        {
          summaryViewItem:
            new SummaryViewData({
              className: "originalFileName",
              name: "Original File Name",
              value: originalFilename,
            })
        },
        {
          summaryViewItem:
            new SummaryViewData({
              className: "runtime",
              name: "Runtime",
              value: mapRuntimeOptionToLabel(WorkloadRuntimeOption[runtime]),
            })
        }, {
          showEditIcon: isDraftWorkload,
          editTooltipText: "Update Entry Point",
          onClickEditIcon: editEntryPoint,
          summaryViewItem:
            new SummaryViewData({
              className: "entryPoint",
              name: "Entry Point",
              value: entryPoint,
            })
        }, {
          summaryViewItem:
            new SummaryViewData({
              className: "contentLength",
              name: "Length",
              value: contentLength,
            })
        }, {
          summaryViewItem:
            new SummaryViewData({
              className: "lastModified",
              name: "Last Modified",
              value: lastModified,
              date: true,
            })
        },
        {
          summaryViewItem: new SummaryViewData({
            className: "eTag",
            name: "ETag",
            value: eTag,
          })
        },
        {
          summaryViewItem: new SummaryViewData({
            className: "packagingStatus",
            name: "Packaging Status ",
            value: isPythonWorkload ? updatedStatus : codePackingStatus,
          })
        },
        {
          summaryViewItem: new SummaryViewData({
            className: "packagingStatusError",
            name: "Packaging Status Error",
            value: isPythonWorkload ? updatedErrorMessage : codePackagingError,
          })
        },
      ],
    [
      showLoadingIndicatorHead,
      originalFilename,
      contentLength,
      lastModified,
      eTag,
      entryPoint,
      runtime,
      isDraftWorkload,
      updatedErrorMessage,
      codePackagingError,
      codePackingStatus,
      updatedStatus,
      isPythonWorkload,
      editEntryPoint,
      mapRuntimeOptionToLabel
    ]);

  const startDownloadCode = React.useCallback(() => {
    setDownloading(true);
    download();
  }, [ setDownloading, download ]);

  const finishDownloadCode = React.useCallback(() => {
    setDownloading(false);
    setCodeHref(downloadCodeURL);
  }, [ setDownloading, setCodeHref, downloadCodeURL ]);

  const startUploadCode = React.useCallback(() => {
    setUploading(true);
    setPyCodePackaged(false);
    setTestWorkloadLoading(true);
    getUploadUrl();
  }, [ setUploading, getUploadUrl, setPyCodePackaged, setTestWorkloadLoading ]);

  const pyCodeExists = React.useCallback(() => {
    download();
  }, [download]);

  const enableSaveCodeRequest = React.useCallback(() => {
    setSaveCodeRequest(true);
  }, [setSaveCodeRequest]);

  const disableSaveCodeRequest = React.useCallback(() => {
    setSaveCodeRequest(false);
  }, [setSaveCodeRequest]);

  React.useEffect(() => {
    let isMounted = true;

    if (downloadCodeURL) {
      fetch(downloadCodeURL)
        .then(response => response.blob())
        .then(blob => {
          if (isMounted) {
            const downloadedFile = new File([blob], `${workloadName}.${version}.zip`, { type: "application/zip" });
            setZipFile(downloadedFile);
          }
        })
        .catch(error => {
          if (isMounted) {
            console.error("Error downloading the zip file:", error);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [downloadCodeURL, setZipFile]);

  React.useEffect(() => {
    if (downloading && !fileDownloadLoading && showDownloadCodeSuccessView) {
      finishDownloadCode();
    } else {
      setCodeHref("");
    }
  }, [downloading, fileDownloadLoading, showDownloadCodeSuccessView, finishDownloadCode, setCodeHref]);

  React.useEffect(() => {
    if (uploading && fileUploadUrlSuccessView && !isEmptyString(uploadUrl)) {
      // after getting the upload URL, upload code file
      uploadCode();
    }
  }, [uploading, fileUploadUrlSuccessView, uploadUrl]);

  React.useEffect(() => {
    if (uploading && fileUploadSuccessView) {
      // upload is complete
      setUploading(false);
      // Reset retries every time upload is successful
      setRetries(0);
      // Do not refresh page if code is saved from editor
      if (!saveCodeRequest) {
        refresh();
      }
    }
  }, [uploading, fileUploadSuccessView, setUploading, refresh, setRetries]);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isPythonWorkload || fileUploadSuccessView) {
      const callGetPackagingStatus = () => {
        if (codePackagingSuccess) {
          setPyCodePackaged(true);
          setTestWorkloadLoading(false);
        } else {
          setPyCodePackaged(false);
        }
        if (retries > 3 && codePackagingDone) {
          clearTimeout(timeoutId!);
          setTestWorkloadLoading(false);
          return;
        }

        getPackagingStatus();
        setRetries(retries + 1);
      };

      // Call every 4 seconds for a min of 4 times. (16 seconds)
      // This is because it takes a few seconds for packaging status to go from SUCCESS or FAILED to IN_PROGRESS.
      // Test Workload button is enabled or disabled accordingly.
      timeoutId = setTimeout(callGetPackagingStatus, 4000);
    }

    return () => {
      clearTimeout(timeoutId!);
    };
  }, [
    fileUploadSuccessView,
    getPackagingStatus,
    codePackagingDone,
    retries,
    codePackagingSuccess,
    setPyCodePackaged,
    setTestWorkloadLoading,
    setRetries
  ]);

  React.useEffect(() => {
    if (fileUploadUrlErrorView) {
      setStatusCode(uploadUrlStatusCode);
    }
  }, [fileUploadUrlErrorView, uploadUrlStatusCode, setStatusCode]);

  React.useEffect(() => {
    if (fileUploadErrorView) {
      setStatusCode(uploadStatusCode);
    }
  }, [fileUploadErrorView, uploadStatusCode, setStatusCode]);

  React.useEffect(() => {
    if (codeUploaded && isPythonWorkload) {
      pyCodeExists();
      getPackagingStatus();
    }
  }, []);

  return (
    <CodeInfoView
      summaryViewItems={codeInfoSummaryViewItems}
      fileDownloadLoading={fileDownloadLoading}
      codeUploaded={codeUploaded}
      href={codeHref}
      pythonWorkload={isPythonWorkload}
      fileUploadErrorMessage={fileUploadErrorMessage}
      showDownloadErrorView={showDownloadErrorView}
      downloadErrorMessage={downloadErrorMessage}
      fileSelected={fileSelected}
      fileName={file.name}
      fileType={fileType}
      zipFile={zipFile}
      entryFileName={entryPoint.split(".")[0] + ".py"}
      entryPoint={entryPoint}
      saveCodeRequest={saveCodeRequest}
      fileUploadLoading={fileUploadUrlLoading || fileUploadLoading}
      fileUploadSuccess={fileUploadUrlSuccessView && fileUploadSuccessView}
      downloadCode={startDownloadCode}
      uploadCode={startUploadCode}
      onSelectFile={onSelectFile}
      onSuccessUploadPyCode={pyCodeExists}
      onDrop={onDrop}
      workloadName={workloadName}
      codeUploadDisabled={codeUploadDisabled}
      statusCode={statusCode}
      setFile={setFile}
      refresh={refresh}
      setPyCodePackaged={setPyCodePackaged}
      setTestWorkloadLoading={setTestWorkloadLoading}
      enableSaveCodeRequest={enableSaveCodeRequest}
      disableSaveCodeRequest={disableSaveCodeRequest}
    />
  );
};

export default CodeInfoViewContainer;

import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { formEventHandler, noop } from "@util";
import { serviceInfo as styles } from "./styles";
import { DropdownMenu, TextField } from "@components";
import { mapSmsMessageTypeToOptionName, SmsMessageType, SmsTemplateLanguage, SmsTemplateRequirements } from "@data";
import MessageTypeInfo from "./MessageTypeInfo";

export interface Model {
  description?: string;
  languages: SmsTemplateLanguage[];
  language?: SmsTemplateLanguage;
  messageType?: SmsMessageType;
  requirements: SmsTemplateRequirements;
  showLoadingIndicator?: boolean;
  editMode?: boolean;
}

export interface Actions {
  setDescription?: (serviceId: string) => void;
  setLanguage?: (protocol: string) => void;
  setMessageType?: (type: SmsMessageType) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SmsTemplateInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    description = "",
    language = SmsTemplateLanguage.EN_US,
    messageType = SmsMessageType.MESSAGE_SIGNUP,
    languages,
    requirements,
    showLoadingIndicator = false,
    editMode = false,
    setDescription = noop,
    setLanguage = noop,
    setMessageType = noop,
  } = props;

  const formHelperTextProps = {
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  };

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  const inputProps = {
    className: classnames("textField", classes.textField),
    spellCheck: false,
  };

  const messageTypes = React.useMemo(() => {
    const types = requirements.getAvailableMessageTypes();
    return types.length > 0 ? types : [messageType];
  }, [requirements, messageType]);

  const messageTypeInfo = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <MessageTypeInfo
        messageType={messageType}
        templateReqs={requirements}
      />
    );
  }, [messageType, requirements]);

  return (
    <div className={classnames("smsTemplateInfo", classes.container)}>
      <div className={classnames("languageContainer", classes.dropdown, classes.languageContainer)}>
        <DropdownMenu
          dropdownMenuLabel="Language *"
          dropdownMenuHint="Language used for the SMS template"
          values={languages}
          selectedValue={language}
          setSelectedValue={setLanguage}
          hideEmptyValue={true}
          disabled={editMode}
        />
      </div>
      <div className={classnames("messageTypeRow", classes.messageTypeRow)}>
        <div className={classnames("messageContainer", classes.dropdown, classes.messageContainer)}>
          <DropdownMenu
            dropdownMenuLabel="Message Type *"
            dropdownMenuHint="Type of message"
            values={messageTypes}
            selectedValue={messageType}
            setSelectedValue={setMessageType}
            hideEmptyValue={true}
            mapValueToLabel={mapSmsMessageTypeToOptionName}
            disabled={editMode}
          />
        </div>
        {!showLoadingIndicator && messageTypeInfo()}
      </div>
      <div className={classnames("descriptionContainer", classes.textFieldContainer)}>
        <TextField
          className={classnames("description", classes.textField)}
          type="text"
          variant="outlined"
          autoComplete="off"
          margin="none"
          fullWidth={true}
          autoFocus={true}
          label="Description"
          name="description"
          value={description}
          inputProps={inputProps}
          required={true}
          placeholder="SMS template description"
          onChange={formEventHandler(setDescription)}
          FormHelperTextProps={formHelperTextProps}
          InputLabelProps={inputLabelProps}
        />
      </div>
    </div>
  );
});

export default SmsTemplateInfo;

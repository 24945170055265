import { createSelector } from "reselect";
import { createSelectors, Selector } from "@base/createSelectors";
import { MODULE_ID as moduleId, SCHEMA_KEY as schemaKey } from "./constants";
import { CreateUserSchema, DEFAULT_STATE, UserProfileAttributeItems } from "./reducers";
import {
  User,
  UserAttributes,
  UserIdentityType,
  UserProfileAttribute,
  UserProfileAttributesMap,
} from "@data";

export const {
  identityType: getIdentityType,
  userId: getUserId,
  userIdErrorMessage: getUserIdErrorMessage,
  password: getPassword,
  passwordErrorMessage: getPasswordErrorMessage,
  showPassword: isPasswordVisible,
  mfaEnabled: isMfaEnabled,
  mfaSecret: getMfaSecret,
  mfaSecretErrorMessage: getMfaSecretErrorMessage,
  user: getUserAttributes,
  profile: getProfile,
  isPortalUser,
  isFederatedUser,
  federationProviders: getFederationProviders,
  selectedFederationProvider: getSelectedFederationProvider,
  errorMessage: getErrorMessage,
  successMessage: getSuccessMessage,
  showEmptyView: isEmptyViewVisible,
  showAccessDenied: isAccessDeniedVisible,
  showLoadingIndicator: isLoadingIndicatorVisible,
  isErrorMessageVisible,
  isSuccessMessageVisible,
  getAccessToken,
} = createSelectors<CreateUserSchema>(moduleId, schemaKey, DEFAULT_STATE);

export const getUserProfileAttributesMap: Selector<UserProfileAttributesMap> = createSelector(
  getProfile, (items: UserProfileAttributeItems) =>
    Object.keys(items).reduce((attrs: UserProfileAttributesMap, key: string) => {
      attrs[key] = new UserProfileAttribute(items[key]);
      return attrs;
    }, {}));

export const getUser: Selector<User> = createSelector(
  getUserAttributes, (attrs: UserAttributes) => new User(attrs));

export const isPhoneNumberUser: Selector<boolean> = createSelector(
  getIdentityType, (identityType: UserIdentityType) => identityType === UserIdentityType.PHONE);

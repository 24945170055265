import { User, UserAdministrationAttributes, UserAttributes } from "@data";
import { isEmptyString, isValidInteger } from "@util";
import {
  createQueryParams,
  makeApiRequest,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withApplicationIdHeader,
  withAuthToken,
  withRequiredArguments
} from "@network/helpers";
import isPortalWebserverEnabled from "@util/isPortalWebserverEnabled";
import PortalSessionManagementClient from "@network/PortalSessionManagementClient";

const DEFAULT_LIMIT = 50;

const USER_IDM_MANAGER_URL = process.env.REACT_APP_USER_IDM_MANAGER_LEGACY || "";

if (isEmptyString(USER_IDM_MANAGER_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_USER_IDM_MANAGER_LEGACY");
}

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export interface LoginResponse {
  accountId: string;
  userId: string;
  accessToken: string;
  refreshToken: string;
  // Token expiration time is in ISO UTC Date Time Format. Example: 2019-04-19T18:05:16.430Z
  // The default expiryTime for the access token is 1 hour.
  accessTokenExpiry: string;
}

export interface RefreshTokenResponse {
  authStatus: string;
  tokens: {
    accessToken: string;
    expiryTime: string;
  };
}

export interface GetUsersResponse {
  users: UserAttributes[];
  next?: string;
}

export const login = (accountId: string,
  userId: string,
  password: string,
  mfaToken?: string): Promise<LoginResponse> => {

  const validate = () => withRequiredArguments([
    ["Account ID", accountId],
    ["User ID", userId],
    ["Password", password],
  ]);

  const makeRequest = () => {

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}/login`;

    const settings = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-ApplicationId": process.env.REACT_APP_FEDERATION_APP_ID || "",
      },
      body: JSON.stringify({
        accountId,
        password,
        ...(!mfaToken ? {} : {
          mfaToken,
        }),
      }),
    };

    const defaultErrorMessage = "The credentials you entered are incorrect. Please try again.";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then(({ accessToken, refreshToken, accessTokenExpiry }) => ({
        accountId,
        userId,
        accessToken,
        refreshToken,
        accessTokenExpiry,
      }));
  };

  return validate().then(makeRequest);
};

export const refresh = (authToken: string,
                        userId: string,
                        refreshToken: string): Promise<RefreshTokenResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Refresh Token", refreshToken],
    ]));

  const makeRequest = () => {

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}/login/refresh`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        refreshToken
      }),
    };

    const defaultErrorMessage = "Refresh token failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage)
      .then(response => {
        const { tokens: { accessToken } } = response;
        if (isPortalWebserverEnabled()) {
          return PortalSessionManagementClient.refreshPortalSession(accessToken)
            .then(() => response);
        } else {
          return Promise.resolve(response);
        }
      });
  };

  return validate().then(makeRequest);
};

export const logout = (authToken: string, userId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}/logout`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Logout failed";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const expirePassword = (authToken: string, userId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}/password/expire`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Expire password failed";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getCurrentUser = (authToken: string,
  userId: string): Promise<UserAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      includeProfileSchema: true,
    });

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Get current user failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUsers = (authToken: string,
  offset: string = "",
  limit: number = DEFAULT_LIMIT): Promise<GetUsersResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      ...(!isValidInteger(limit) ? {} : {limit: Math.max(1, Math.min(DEFAULT_LIMIT, limit))}),
      offset,
      includeProfileSchema: true,
    });

    const url = `${REGIONAL_API}/security/identity/v1/users/administration${queryParams}`;

    const settings = {
      method: "GET",
      headers: withApplicationIdHeader(
        {
          "Authorization": `Bearer ${authToken}`,
          "Accept": "application/json",
        }
      ),
    };

    const defaultErrorMessage = "Fetch users failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest).then(({ users, paging }) => ({
    users: users.map((attrs: UserAdministrationAttributes) => new User(User.from(attrs))),
    next: paging ? paging.next : null,
  } as GetUsersResponse));
};

export const updateUserProfile = (authToken?: string,
  userId?: string,
  json?: string,
  selfAuthorized?: boolean): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["User Profile", json],
    ]));

  const makeRequest = () => {

    const url = selfAuthorized
      ? `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}`
      : `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: json,
    };

    const defaultErrorMessage = `Failed to update user profile [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteUser = (authToken: string,
  userId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to delete user [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const logoutUser = (authToken: string,
  userId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}/logout`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to logout user [${userId}]`;

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUserProfile = (authToken: string,
  userId: string = ""): Promise<UserAttributes> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
    ]));

  const makeRequest = () => {

    const queryParams = createQueryParams({
      includeProfileSchema: true,
    });

    const url = `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = `Failed to get profile for user [${userId}]`;

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUserBinaryData = (authToken: string,
  userId: string = "",
  name: string = "",
  selfAuthorized?: boolean): Promise<any> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Attribute Name", userId],
    ]));

  const makeRequest = () => {

    const url = selfAuthorized
      ? `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}/data/${name}`
      : `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}/data/${name}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = `Failed to get binary data [${name}] for user [${userId}]`;

    return makeApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const updateUserBinaryData = (authToken: string,
  userId: string = "",
  name: string = "",
  file: File,
  selfAuthorized?: boolean): Promise<any> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Attribute Name", userId],
    ]));

  const makeRequest = () => {

    const url = selfAuthorized
      ? `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}/data/${name}`
      : `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}/data/${name}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": `${file.type}`,
        "Content-Length": `${file.size}`,
      },
      body: file,
    };

    const defaultErrorMessage = `Failed to update binary data [${name}] for user [${userId}]`;

    return makeApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteUserBinaryData = (authToken: string,
  userId: string = "",
  name: string = "",
  selfAuthorized?: boolean): Promise<any> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Attribute Name", userId],
    ]));

  const makeRequest = () => {

    const url = selfAuthorized
      ? `${USER_IDM_MANAGER_URL}/v3/aam/users/${userId}/data/${name}`
      : `${USER_IDM_MANAGER_URL}/v3/aam/admin/users/${userId}/data/${name}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
      },
    };

    const defaultErrorMessage = `Failed to delete binary data [${name}] for user [${userId}]`;

    return makeApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

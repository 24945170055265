import React from "react";
import classnames from "classnames";
import { Typography } from "@material-ui/core";
import { messagePreview as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AccountCircle, Send } from "@material-ui/icons";
import { isEmptyString } from "@util";

export interface Model {
  message?: string;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
};

export const MessagePreview = withStyles(styles)((props: Props) => {

  const {
    classes,
    message = "",
  } = props;

  return (
      <div className={classnames("phone", classes.phone)}>
        <div className={classnames("screen", classes.screen)}>
          <div className={classnames("user", classes.user)}>
            <AccountCircle />
            <Typography className={classnames("title", classes.title)}>
              New User
            </Typography>
          </div>
          {!isEmptyString(message) && (
            <div className={classnames("message", classes.message)}>
              {message}
            </div>
          )}
          <div className={classnames("newMessage", classes.newMessage)}>
            <div className={classnames("text", classes.emptyText)}/>
            <Send className={classnames(classes.icon)}/>
          </div>
        </div>
      </div>
  );
});

export default MessagePreview;

export const DEFAULT_STRING_MAX_LENGTH = 32;
export const DEFAULT_STRING_MIN_LENGTH = 12;

// noinspection SpellCheckingInspection
const LETTERS = "abcdefghijklmnopqrstuvwxyz";
const SPECIAL_CHARS = "!@#$%^&*(){}[];:\\/?~_<>,.\|";

const getRandomNumber = (max: number, exclude: number[] = [], attempt: number = 0): number => {

  const excludedNumbers = Array.from(new Set(exclude));

  if ([...Array(max)].length === excludedNumbers.length) {
    throw new Error("Unable to generate a number that would not be excluded!");
  }

  const randomNumber = Math.floor(Math.random() * Math.floor(max));

  if (excludedNumbers.indexOf(randomNumber) >= 0) {

    // Just return the first allowed number if we already tried three times
    if (attempt >= 3) {
      return [...Array(max)].find(i => excludedNumbers.indexOf(i) === -1);
    }

    return getRandomNumber(max, excludedNumbers, attempt + 1);
  }

  return randomNumber;
};

const getRandomSpecialCharacter = () => SPECIAL_CHARS[getRandomNumber(SPECIAL_CHARS.length)];

const getRandomLetter = () => LETTERS[getRandomNumber(LETTERS.length)];

// Randomize the length if not provided, but let's not always keep it the same
export const randomStringGenerator =
  (length: number = Math.max(DEFAULT_STRING_MIN_LENGTH, getRandomNumber(DEFAULT_STRING_MAX_LENGTH))) => {

    // Password must be between 12 and 32 characters
    const passwordLength =
      Math.min(DEFAULT_STRING_MAX_LENGTH, Math.max(DEFAULT_STRING_MIN_LENGTH, length));

    // And include at least two special characters
    const specialIdx1 = getRandomNumber(passwordLength);
    const specialIdx2 = getRandomNumber(passwordLength, [specialIdx1]);
    const specialIdx = [specialIdx1, specialIdx2];

    // And include at least one capital letter
    const capitalIdx = getRandomNumber(passwordLength, specialIdx);

    // And include at least one lowercase letter
    const lowercaseIdx = getRandomNumber(passwordLength, specialIdx.concat([capitalIdx]));

    // And include at least one number
    const numberIdx = getRandomNumber(passwordLength, specialIdx.concat([capitalIdx, lowercaseIdx]));

    return Array.from(Array(passwordLength).keys())
      .map(i => {

        if (specialIdx.indexOf(i) >= 0) {
          return getRandomSpecialCharacter();
        } else if (i === capitalIdx) {
          return getRandomLetter().toUpperCase();
        } else if (i === lowercaseIdx) {
          return getRandomLetter().toLowerCase();
        } else if (i === numberIdx) {
          return `${getRandomNumber(10)}`;
        } else {
          return Math.random().toString(32).slice(2).slice(0, 1);
        }
      })
      .join("");
  };

export default randomStringGenerator;

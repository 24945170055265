import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import { Application, EmailTemplateMessageType } from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ApplicationsList, ApplicationsListActions, ApplicationsListModel } from "@components";
import { applicationView as styles } from "./styles";

export interface Model extends ApplicationsListModel {
  className?: string;
  title?: string;
  applicationId?: string;
  messageType?: EmailTemplateMessageType;
  editMode?: boolean;
}

export interface Actions extends ApplicationsListActions {
  setSelectedApplicationId?: (applicationId: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApplicationView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Select Application",
    items = [],
    showLoadMoreButton,
    showLoadingIndicator,
    applicationId,
    messageType,
    errorMessage,
    editMode = false,
    setSelectedApplicationId = noop,
    children,
    ...otherProps
  } = props;

  const [selectedApplication, setSelectedApplication] = React.useState<Application[]>([]);

  const applicationSelected = React.useMemo(() =>
    selectedApplication.length > 0, [selectedApplication]);

  const showLoadMore = React.useMemo(() =>
      applicationSelected ? false : showLoadMoreButton,
    [applicationSelected, showLoadMoreButton]);

  const showLoading = React.useMemo(() =>
      applicationSelected ? false : showLoadingIndicator,
    [applicationSelected, showLoadingIndicator]);

  const error = React.useMemo(() =>
      applicationSelected ? "" : errorMessage,
    [applicationSelected, errorMessage]);

  const visibleItems = React.useMemo(() => {
    if (applicationSelected) {
      return selectedApplication;
    } else {
      return items;
    }
  }, [applicationSelected, items, selectedApplication]);

  const updateSelectedItems = React.useCallback((selectedApplications: Application[]) => {
    setSelectedApplication(selectedApplications);

    if (selectedApplications.length > 0) {
      setSelectedApplicationId(selectedApplications[0].getId());
    } else {
      setSelectedApplicationId("");
    }
  }, [setSelectedApplication, setSelectedApplicationId]);

  // show application if selected
  React.useEffect(() => {
    if (applicationId) {
      const application = items.find(item => item.getId() === applicationId);
      setSelectedApplication(application ? [application] : []);
    }
  }, []);

  return (
    <div className={classnames("applicationView", className, classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      <ApplicationsList
        {...otherProps}
        className={classnames("applications", classes.applications)}
        selectable={true}
        selectAllDisabled={true}
        items={visibleItems}
        showLoadMoreButton={showLoadMore}
        showLoadingIndicator={showLoading}
        errorMessage={error}
        selectedItems={selectedApplication}
        setSelectedItems={updateSelectedItems}
      />
      {children}
    </div>
  );
});

export default ApplicationView;

import { isEmptyString } from "@util";
import { createQueryParams, makeJsonApiRequest, withAuthToken } from "./helpers";
import {
  CostReportingGroupedMetricAttributes,
  CostReportingMetadataAttributes,
  CostReportingMetricAttributes,
} from "@data";

const DEFAULT_LIMIT = 200;

const PORTAL_ORCHESTRATION_API = process.env.REACT_APP_PORTAL_ORCHESTRATION_API || "";

if (isEmptyString(PORTAL_ORCHESTRATION_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_ORCHESTRATION_API");
}

export interface GetCostReportingMetadataResponse {
  items: CostReportingMetadataAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetCostReportingMetricsResponse {
  metrics: CostReportingMetricAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetCostReportingGroupedMetricsResponse {
  metrics: CostReportingGroupedMetricAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetCostReportingMetadataOptions {
  next?: string;
  limit?: number;
  metadataGroupKey?: string;
}

export interface GetCostReportingMetricsOptions {
  next?: string;
  limit?: number;
  periodType: string;
  domainType?: string;
  groupType?: string;
  categoryType?: string;
  metricType?: string;
  resourceType?: string;
}

export interface GetCostReportingGroupedMetricsOptions {
  next?: string;
  limit?: number;
  periodType: string;
  metricPartition: string;
  domainType?: string;
  groupType?: string;
  categoryType?: string;
  metricType?: string;
  resourceType?: string;
}

export const getCostReportingMetadata = (
  authToken: string,
  options: GetCostReportingMetadataOptions
): Promise<GetCostReportingMetadataResponse> => {

  const { metadataGroupKey, next, limit = DEFAULT_LIMIT } = options || {};

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({ metadataGroupKey, next, limit });

    const url = `${PORTAL_ORCHESTRATION_API}/portal/cost-reporting/v1/metadata${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch metadata failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getCostReportingMetrics = (
  authToken: string,
  options: GetCostReportingMetricsOptions
): Promise<GetCostReportingMetricsResponse> => {

  const {
    periodType,
    domainType,
    groupType,
    categoryType,
    metricType,
    resourceType,
    next,
    limit = DEFAULT_LIMIT
  } = options || {};

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      periodType,
      domainType,
      groupType,
      categoryType,
      metricType,
      resourceType,
      next,
      limit
    });

    const url = `${PORTAL_ORCHESTRATION_API}/portal/cost-reporting/v1/metrics/list${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch metrics failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getCostReportingGroupedMetrics = (
  authToken: string,
  options: GetCostReportingGroupedMetricsOptions
): Promise<GetCostReportingGroupedMetricsResponse> => {

  const {
    periodType,
    metricPartition,
    domainType,
    groupType,
    categoryType,
    metricType,
    resourceType,
    next,
    limit = DEFAULT_LIMIT
  } = options || {};

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      periodType,
      metricPartition,
      domainType,
      groupType,
      categoryType,
      metricType,
      resourceType,
      next,
      limit
    });

    const url = `${PORTAL_ORCHESTRATION_API}/portal/cost-reporting/v1/metrics/list/grouped${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch grouped metrics failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

import React from "react";
import { isEmptyString } from "@util";
import { CostReportingMetric } from "@data";
import useCostReportingClient from "../use-cost-reporting-client";
import { GetCostReportingMetricsOptions, GetCostReportingMetricsResponse } from "@network";
import {
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps
} from "@hooks";

type SuccessResponse = GetCostReportingMetricsResponse;

export interface UseGetCostReportingMetricsProps extends
  Partial<UsePaginatedApiRequestProps<SuccessResponse>>, GetCostReportingMetricsOptions {
}

export interface UseGetCostReportingMetricsModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  metrics: CostReportingMetric[];
}

export interface UseGetCostReportingMetricsActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseGetCostReportingMetricsProps;
type Model = UseGetCostReportingMetricsModel;
type Actions = UseGetCostReportingMetricsActions;
type Result = [Model, Actions];

export const useGetCostReportingMetrics = (props: Props): Result => {

  const {
    periodType,
    domainType,
    groupType,
    categoryType,
    metricType,
    resourceType,
    defaultErrorMessage = "Failed to fetch metrics",
    ...otherProps
  } = props;

  const CostReportingClient = useCostReportingClient();

  const [metrics, setMetrics] = React.useState<CostReportingMetric[]>([]);

  const makeApiRequest = React.useCallback((accessToken, next) =>
    CostReportingClient.getCostReportingMetrics({
      periodType,
      domainType,
      groupType,
      categoryType,
      metricType,
      resourceType,
      ...(isEmptyString(next) ? ({}) : ({ next })),
    }), [
    CostReportingClient,
    periodType,
    domainType,
    groupType,
    categoryType,
    metricType,
    resourceType,
  ]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedMetrics = React.useMemo(() => {
    const { metrics: items = [] } = successResponse || {};
    return items.map(attrs => new CostReportingMetric(attrs));
  }, [successResponse]);

  const loadedMetrics = React.useMemo(() => {
    return updatedMetrics.length && !metrics.length ? updatedMetrics : metrics;
  }, [updatedMetrics, metrics]);

  const reset = React.useCallback(() => {
    setMetrics([]);
    baseReset();
  }, [setMetrics, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    metrics: loadedMetrics,
  }), [
    baseModel,
    successResponse,
    loadedMetrics,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setMetrics(currentDataSets => currentDataSets.concat(updatedMetrics));
  }, [updatedMetrics, setMetrics]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetCostReportingMetrics;

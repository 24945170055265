import { Record } from "immutable";
import { getStringValue } from "@util";

export interface CostReportingMetricAttributes {
  accountId: string;
  category: string;
  date: string;
  domain: string;
  group: string;
  metricType: string;
  periodType: string;
  resourceType: string;
  costValue: number;
  countValue: number;
}

export class CostReportingMetric extends Record({
  accountId: "",
  category: "",
  date: "",
  domain: "",
  group: "",
  metricType: "",
  periodType: "",
  resourceType: "",
  costValue: 0.0,
  countValue: 0,
}) implements CostReportingMetricAttributes {

  public static EMPTY: CostReportingMetric = new CostReportingMetric();

  public readonly accountId: string;
  public readonly category: string;
  public readonly date: string;
  public readonly domain: string;
  public readonly group: string;
  public readonly metricType: string;
  public readonly periodType: string;
  public readonly resourceType: string;
  public readonly costValue: number;
  public readonly countValue: number;

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getCategory(): string {
    return getStringValue(this.category);
  }

  public getDate(): string {
    return getStringValue(this.date);
  }

  public getDomain(): string {
    return getStringValue(this.domain);
  }

  public getGroup(): string {
    return getStringValue(this.group);
  }

  public getMetricType(): string {
    return getStringValue(this.metricType);
  }

  public getPeriodType(): string {
    return getStringValue(this.periodType);
  }

  public getResourceType(): string {
    return getStringValue(this.resourceType);
  }

  public getCostValue(): number {
    return this.costValue;
  }

  public getCountValue(): number {
    return this.countValue;
  }
}

export default CostReportingMetric;

import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      flex: "0 0 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      backgroundColor: Color.LIGHT_GREY,
      border: `1px solid ${Color.GREY1}`,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    title: {
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
      margin: "10px 0 10px 20px",
    },
    actionsMenu: {
      marginLeft: 8,
    },
    testButton: {
      marginLeft: "auto",
      marginRight: "10px",
      height: "29px",
      backgroundColor: Color.SIGNIFY_GREEN,
      color: Color.BLACK,
      borderRadius: 4,
      "&:hover": {
        backgroundColor: Color.SIGNIFY_GREEN2,
      },
      "&:active": {
        backgroundColor: Color.SIGNIFY_GREEN3,
      },
      "&.Mui-disabled": {
        backgroundColor: Color.LIGHT_GREY2,
      },
    },
    content: {
      flex: "1 0 auto",
      backgroundColor: Color.WHITE,
      border: `1px solid ${Color.GREY1}`,
      borderTop: "none",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-evenly",
      padding: "15px 10px 10px",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    card: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      marginLeft: 10,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    cardState: {
      backgroundColor: Color.GREY1,
      padding: "10px 25px",
      borderRadius: 4,
      "&$active": {
        backgroundColor: Color.SIGNIFY_GREEN,
      },
      "&$failed": {
        backgroundColor: Color.RED,
        "& > $cardStateLabel": {
          color: Color.WHITE,
        },
      },
      "&$inProgress": {
        backgroundColor: Color.AQUA,
      },
    },
    cardStateLabel: {
      color: Color.BLACK,
      fontSize: 13,
      textTransform: "uppercase",
      textAlign: "center",
    },
    cardFooterLabel: {
      marginTop: 10,
      color: Color.TEXT,
      fontSize: 10,
      fontWeight: 300,
      lineHeight: "16px",
      textAlign: "center",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    arrowIcon: {
      flex: "0 0 50px",
      color: Color.GREY1,
      marginLeft: 10,
      width: 50,
      height: "auto",
    },
    active: {},
    failed: {},
    inProgress: {},
    draft: {},
    released: {},
    deprecated: {},
    decommissioned: {},
    progressIndicator: {
      color: Color.TEXT,
      marginLeft: 8,
      zIndex: 1,
    },
  });

export default styles;

import React from "react";
import useContainerRepositoryClient from "../use-container-repository-client";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks/use-api-request";

type SuccessResponse = void;

export interface UseDeleteRepositoryProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  repoId: string;
}

export interface UseDeleteRepositoryModel extends UseApiRequestModel<SuccessResponse> {
}

export interface UseDeleteRepositoryActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseDeleteRepositoryProps;
type Model = UseDeleteRepositoryModel;
type Actions = UseDeleteRepositoryActions;
type Result = [Model, Actions];

export const useDeleteRepository = (props: Props): Result => {

  const {
    repoId,
    defaultErrorMessage = "Failed to delete container repository",
    ...otherProps
  } = props;

  const ContainerRepositoryManagerClient = useContainerRepositoryClient();

  const makeApiRequest = React.useCallback(() => {
    return ContainerRepositoryManagerClient.deleteContainerRepository(repoId);
  }, [ContainerRepositoryManagerClient, repoId]);

  const [{ showSuccessView, ...otherModel}, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ? "Repository deleted" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...otherModel,
    showSuccessView,
    successMessage,
  }), [
    otherModel,
    showSuccessView,
    successMessage,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteRepository;

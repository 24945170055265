import React from "react";
import { ProfileAttributeDefinition, UserProfileAttribute } from "@data";
import { UserProfileAttributesListColumn } from "./UserProfileAttributesListColumn";
import useSortedSearchResults, {
  FetchItemsApi,
  UseSortedSearchResultsActions,
  UseSortedSearchResultsModel,
} from "@components/sorted-search-results-list/useSortedSearchResults";
import {
  filterResultsByNameFilter,
  mapItemToColumnValue,
  mapUserToUserProfileAttribute,
} from "./helpers";
import User, { UserAttributes } from "@data/User";
import { ProfileAttributeClient, UserIdmLegacyClient } from "@network/index";
import { AccountIdContext } from "@components/account-id-provider";
import { equalsIgnoreCase } from "@util/Functions";

// TODO: Remove once IOTSEC figures out how they want the Binary Profile Attributes API to work
const SHOW_BINARY_PROFILE_ATTRIBUTES =
  equalsIgnoreCase("true", process.env.REACT_APP_BINARY_PROFILE_ATTRIBUTES_ENABLED);

type Item = UserProfileAttribute;
type Column = UserProfileAttributesListColumn;

export interface UseUserProfileAttributesModel extends UseSortedSearchResultsModel<Item, Column> {
}

export interface UseUserProfileAttributesActions extends UseSortedSearchResultsActions<Item, Column> {
}

type Model = UseUserProfileAttributesModel;
type Actions = UseUserProfileAttributesActions;

export const useProfileAttributes = (fetchItems: FetchItemsApi<Item>): [Model, Actions] =>
  useSortedSearchResults<Item, Column>({
    defaultSortByColumn: UserProfileAttributesListColumn.NONE,
    filterResultsByNameFilter,
    mapItemToColumnValue,
    fetchItems,
  });

export const useUserProfileAttributes = (userId: string, selfAuthorized?: boolean) => {

  const accountId = React.useContext(AccountIdContext);

  const getDefaultProfileAttributes = React.useCallback((authToken: string) => {

    return ProfileAttributeClient.getProfileAttributeDefinition(authToken, accountId)
      .then(({ attributes }) => {
        return attributes.map(definition =>
          new ProfileAttributeDefinition(definition)
            .toUserProfileAttribute());
      })
      // If user does not have permission to fetch account profile attributes, just return
      // an empty list here so that they can at least edit their current user profile attributes
      .catch(() => ([]));
  }, [userId, accountId]);

  const getUserProfile = React.useCallback((authToken: string) => {
    if (selfAuthorized) {
      return UserIdmLegacyClient.getCurrentUser(authToken, userId);
    } else {
      return UserIdmLegacyClient.getUserProfile(authToken, userId);
    }
  }, [userId, selfAuthorized]);

  const getUserProfileAttributes = React.useCallback((authToken: string) =>
    getUserProfile(authToken)
      .then((attrs: UserAttributes) => new User(attrs))
      .then((user: User) => mapUserToUserProfileAttribute(user)),
    [getUserProfile]);

  const getProfileAttributes = React.useCallback((authToken: string) => {
    return Promise.all([
      getDefaultProfileAttributes(authToken),
      getUserProfileAttributes(authToken),
    ]).then(([defaultAttributes = [], userAttributes = []]) => {
      const decoratedUserAttributes = userAttributes.map(userAttribute => {
        const defaultAttribute = defaultAttributes.find(profileAttribute =>
          equalsIgnoreCase(profileAttribute.getName(), userAttribute.getName()));

        if (!defaultAttribute) {
          return userAttribute;
        }

        return new UserProfileAttribute({
          ...userAttribute.toJS(),
          required: defaultAttribute.isRequired(),
        });
      });
      return [defaultAttributes, decoratedUserAttributes];
    });
  }, [getDefaultProfileAttributes, getUserProfileAttributes]);

  const fetchItems = React.useCallback((authToken: string) => getProfileAttributes(authToken)
    .then(([defaultAttributes = [], userAttributes = []]) => ({
      items: userAttributes.concat(
        defaultAttributes.filter(defaultAttribute =>
          !userAttributes.some(userAttribute =>
            equalsIgnoreCase(userAttribute.getName(), defaultAttribute.getName()))))
        .filter(attribute => SHOW_BINARY_PROFILE_ATTRIBUTES || !attribute.isBinaryDataType()),
    })), [getProfileAttributes]);

  return useProfileAttributes(fetchItems);
};

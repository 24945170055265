import React from "react";
import CreateRepository from "./containers/CreateRepository";
import { useHistory } from "react-router-dom";
import { getPathToRepositories } from "@modules/containerRepositories/helpers";

export function ContainerRepositoriesView() {

  const history = useHistory();

  const showRepositoryList = React.useCallback(() => {
    history.replace(getPathToRepositories());
  }, [history]);

  return (
    <CreateRepository showRepositoryList={showRepositoryList}/>
  );
}

export default ContainerRepositoriesView;

import { Record } from "immutable";
import UserIdentityType from "@data/UserIdentityType";

export interface CreateUserStateAttributes {
  identityType: UserIdentityType;
  portalUser: boolean;
  federatedUser: boolean;
  federationProvider: string;
  userId: string;
  password: string;
  profileValueUpdated: boolean;
}

export class CreateUserState extends Record({
  identityType: UserIdentityType.EMAIL,
  portalUser: true,
  federatedUser: true,
  federationProvider: "",
  userId: "",
  password: "",
  profileValueUpdated: false,
}) implements CreateUserStateAttributes {

  public static EMPTY = new CreateUserState();

  public readonly identityType: UserIdentityType;
  public readonly portalUser: boolean;
  public readonly federatedUser: boolean;
  public readonly federationProvider: string;
  public readonly userId: string;
  public readonly password: string;
  public readonly profileValueUpdated: boolean;
}

export default CreateUserState;

import React from "react";
import {
  isQualityReportsEnabledForCommonServices,
  isQualityReportsEnabledForDeviceManagement,
  isQualityReportsEnabledForSecurityServices,
  noop,
} from "@util";
import classnames from "classnames";
import List from "@material-ui/core/List";
import MuiDrawer from "@material-ui/core/Drawer";
import HeaderListItem, { HeaderListItemProps } from "./HeaderListItem";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { QualityReportPlatformDomainId, ReleaseNotePlatformDomain } from "@data";
import { ReleaseNotesTab } from "@modules/releaseNotes/components/ReleaseNotesTabs";
import isFeedbackDialogEnabled from "@util/isFeedbackDialogEnabled";
import { drawer, styles } from "./styles";
import TitleListItem from "./TitleListItem";
import SettingsListItem from "./SettingsListItem";
import FeedbackListItem from "./FeedbackListItem";
import ReleaseNotesListItem from "./ReleaseNotesListItem";
import CostReportsListItem from "./CostReportsListItem";
import Module from "@data/Module";

const Drawer = withStyles(drawer)(MuiDrawer);

export interface Model {
  accountId?: String;
  favoriteModules?: Module[];
  favoritesList?: string[];
  informationBannerVisible?: boolean;
  isUserPrincipal?: boolean;
  showMaintenanceReminder?: boolean;
  showCostReports?: boolean;
  showQualityReportsIconButton?: boolean;
  showQualityReportsForCommonServices?: boolean;
  showQualityReportsForForDeviceManagement?: boolean;
  showQualityReportsForForSecurityServices?: boolean;
  modules?: { [key: string]: (Module | { [key: string]: Module[] })[]; };
  modulesList?: Module[];
}

export interface Actions {
  showFeedbackDialog?: (value: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const Navigation = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId = "",
    favoriteModules = [],
    modules = {},
    isUserPrincipal,
    informationBannerVisible,
    showMaintenanceReminder = false,
    showCostReports,
    showQualityReportsIconButton,
    showQualityReportsForCommonServices = isQualityReportsEnabledForCommonServices(),
    showQualityReportsForForDeviceManagement = isQualityReportsEnabledForDeviceManagement(),
    showQualityReportsForForSecurityServices = isQualityReportsEnabledForSecurityServices(),
    showFeedbackDialog = noop,
  } = props;

  const commonServicesQualityReportsEnabled = React.useMemo(() =>
      showQualityReportsIconButton && showQualityReportsForCommonServices,
    [showQualityReportsIconButton, showQualityReportsForCommonServices]);

  const deviceManagementQualityReportsEnabled = React.useMemo(() =>
      showQualityReportsIconButton && showQualityReportsForForDeviceManagement,
    [showQualityReportsIconButton, showQualityReportsForForDeviceManagement]);

  const securityServicesQualityReportsEnabled = React.useMemo(() =>
      showQualityReportsIconButton && showQualityReportsForForSecurityServices,
    [showQualityReportsIconButton, showQualityReportsForForSecurityServices]);

  const settingsListItem = React.useMemo(() =>
    <SettingsListItem accountId={accountId} />,
    [accountId]);

  const headerListItems = React.useMemo(() =>
    Object.keys(modules).map(name => {

      let headerListItemProps: HeaderListItemProps = {
        name,
        modules: modules[name],
      };

      if (name === ReleaseNotesTab.DATA_AND_ANALYTICS) {
        headerListItemProps = {
          ...headerListItemProps,
          releaseNotesPlatformDomain: ReleaseNotePlatformDomain.DATA_AND_ANALYTICS,
          releaseNotesClassName: classnames("dataAndAnalyticsReleaseNotesButton", classes.releaseNotesIconButton),
          releaseNotesTooltip: "Data & Analytics Release Notes",
        };
      } else if (name === ReleaseNotesTab.COMMON_SERVICES) {
        headerListItemProps = {
          ...headerListItemProps,
          releaseNotesPlatformDomain: ReleaseNotePlatformDomain.COMMON_SERVICES,
          releaseNotesClassName: classnames("commonServicesReleaseNotesButton", classes.releaseNotesIconButton),
          releaseNotesTooltip: "Common Services Release Notes",
          showQualityReportsIconButton: commonServicesQualityReportsEnabled,
          qualityReportsClassName: "commonServicesQualityReportsIconButton",
          qualityReportsPlatformDomain: QualityReportPlatformDomainId.COMMON_SERVICES,
          qualityReportsTooltip: "Common Services Quality Reports",
        };
      } else if (name === ReleaseNotesTab.DEVICE_MANAGEMENT) {
        headerListItemProps = {
          ...headerListItemProps,
          releaseNotesPlatformDomain: ReleaseNotePlatformDomain.DEVICE_MANAGEMENT,
          releaseNotesClassName: classnames("deviceManagementReleaseNotesButton", classes.releaseNotesIconButton),
          releaseNotesTooltip: "Device Management Release Notes",
          showQualityReportsIconButton: deviceManagementQualityReportsEnabled,
          qualityReportsClassName: "deviceManagementQualityReportsIconButton",
          qualityReportsPlatformDomain: QualityReportPlatformDomainId.DEVICE_MANAGEMENT,
          qualityReportsTooltip: "Device Management Quality Reports",
        };
      } else if (name === ReleaseNotesTab.SECURITY_SERVICES) {
        headerListItemProps = {
          ...headerListItemProps,
          releaseNotesPlatformDomain: ReleaseNotePlatformDomain.SECURITY_SERVICES,
          releaseNotesClassName: classnames("securityServicesReleaseNotesButton", classes.releaseNotesIconButton),
          releaseNotesTooltip: "Security Services Release Notes",
          showQualityReportsIconButton: securityServicesQualityReportsEnabled,
          qualityReportsClassName: "securityServicesQualityReportsIconButton",
          qualityReportsPlatformDomain: QualityReportPlatformDomainId.SECURITY_SERVICES,
          qualityReportsTooltip: "Security Services Quality Reports",
        };
      }

      return (
        <HeaderListItem
          key={`header-list-item-${name}`}
          {...headerListItemProps}
        />
      );
    }), [
    modules,
    classes,
    commonServicesQualityReportsEnabled,
    deviceManagementQualityReportsEnabled,
    securityServicesQualityReportsEnabled,
  ]);

  const limitFavorites = React.useMemo(() => {
      const topFavorites = favoriteModules.slice(0, 3);
      const extraFavorites = {"Show More": favoriteModules.slice(3)};
      return [...topFavorites, extraFavorites];
    }
    , [favoriteModules]);

  const favoriteListItems = React.useMemo(() => {
    return (
      <HeaderListItem
        key="header-list-item-favorites"
        name="Favorites"
        modules={limitFavorites}
        isFavorites={true}
      />
    );
  }, [limitFavorites]);

  const listItems = React.useMemo(() => (
    <React.Fragment>
      <TitleListItem />
      {settingsListItem}
      {isFeedbackDialogEnabled() && isUserPrincipal &&
        <FeedbackListItem showFeedbackDialog={showFeedbackDialog}/>
      }
      {isUserPrincipal && favoriteModules.length > 0 &&
        <div className="favorites">{favoriteListItems}</div>
      }
      {headerListItems}
    </React.Fragment>
  ), [settingsListItem, showFeedbackDialog, isUserPrincipal, favoriteModules, favoriteListItems, headerListItems]);

  return React.useMemo(() => (
    <Drawer
      className={classnames("navigation", classes.container)}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classnames({
          [classes.informationBannerVisible]: informationBannerVisible,
          [classes.maintenanceReminder]: showMaintenanceReminder,
        }),
      }}
    >
      <List disablePadding={true}>
        {listItems}
      </List>
      <ReleaseNotesListItem 
        className={classnames("releaseNotes", classes.releaseNotes)} 
      />
      <CostReportsListItem
        className={classnames("costReports", classes.costReports)}
        showCostReports={showCostReports}
      />
      {showMaintenanceReminder &&
        <div className={classnames("maintenanceReminderFooter", classes.maintenanceReminderFooter)} />
      }
    </Drawer>
  ), [classes, informationBannerVisible, showMaintenanceReminder, showCostReports, listItems]);
});

export default Navigation;

import classnames from "classnames";
import { Alert, AlertTitle } from "@components";
import React from "react";
import {
  mapSmsMessageTypeToOptionName,
  RegionalTemplateParameterInfoStatus,
  SmsMessageType,
  SmsTemplateRequirements,
} from "@data";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { messageTypeInfoAlert as styles } from "./styles";

interface MessageTypeInfoModel {
  messageType: SmsMessageType;
  templateReqs: SmsTemplateRequirements;
}

type Props = WithStyles<typeof styles> & MessageTypeInfoModel;

export const MessageTypeInfo = withStyles(styles)((props: Props) => {
  const {
    classes,
    messageType,
    templateReqs,
  } = props;

  const description = React.useMemo(() => templateReqs.getDescriptionByMessageType(messageType)
    , [messageType, templateReqs]);

  const requiredParameters = React.useMemo(() => templateReqs.getReqParamsByMessageType(messageType)
    , [messageType, templateReqs]);

  const deprecatedMap = React.useMemo(() => {
    let map = {};
    const allParams = Object.keys(templateReqs.getParameterInfo());
    allParams.map((param) => {
      if (templateReqs.getStatusByParameter(param) === RegionalTemplateParameterInfoStatus.DEPRECATED) {
        const replacement = templateReqs.getReplacementByParameter(param);
        if (requiredParameters.includes(replacement)) { // only interested in required parameters
          map[replacement] = map[replacement] ? map[replacement].concat(param) : [param];
        }
      }
    });
    return map;
  }, [requiredParameters, templateReqs]);

  return (
    <Alert severity="info" className={classnames("messageTypeInfo", classes.container)}>
      <AlertTitle className={classnames("alertTitle", classes.title)}>
        <strong>
          {mapSmsMessageTypeToOptionName(messageType)?.toUpperCase()}
        </strong>
      </AlertTitle>
      <ul className={classnames(classes.description)}>
        <li><strong>Description - </strong>{description}</li>
        {requiredParameters.length > 0 && (
          <li><strong>Required Parameters - </strong>The following parameters are required for this message type:</li>
        )}
        <ul>
          {requiredParameters.map((param) => (
            <li key={param}>
              <strong>{param}</strong> - {templateReqs.getDescriptionByParameter(param)}
              <ul>
                <li><strong>Syntax - </strong>{templateReqs.getSyntaxByParameter(param)}</li>
                <li><strong>Deprecated - </strong>{deprecatedMap[param].join(", ")}</li>
              </ul>
            </li>
          ))}
        </ul>
      </ul>
    </Alert>
  );
});

export default MessageTypeInfo;

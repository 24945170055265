import ProfileAttributesIcon from "@material-ui/icons/Ballot";
import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export { ProfileAttributesIcon, ProfileAttributesIcon as PrimaryIcon };

export const primaryColor = Color.SIGNIFY_GREEN;

export const loadingIndicator = () =>
  createStyles({
    container: {
      display: "inline-flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      paddingLeft: 8,
    },
    loadingIndicator: {
      margin: 0,
      padding: 0,
    },
    loadingIndicatorIcon: {
      margin: 0,
      padding: 0,
    },
    label: {
      marginLeft: 8,
    },
  });

export const binaryDataListItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    label: {
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
    },
    error: {
      color: Color.RED,
      textDecoration: "none",
      cursor: "default",
    },
    success: {
      color: Color.DARK_GREEN,
      textDecoration: "none",
      cursor: "default",
    },
    downloading: {
      color: Color.TEXT,
      textDecoration: "none",
      cursor: "default",
    },
    deleting: {
      color: Color.TEXT,
      textDecoration: "none",
      cursor: "default",
    },
    button: {
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 400,
      marginLeft: 16,
      "&:first-child": {
        marginLeft: 0,
      },
      backgroundColor: Color.WHITE,
      borderRadius: 8,
      color: Color.TEXT,
      padding: "0 8px",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: Color.GREY1,
      },
      "&:active": {
        backgroundColor: Color.GREY2,
      },
    },
    cancelButton: {
      marginLeft: 8,
    },
    loadingIndicator: {
    },
    editDialog: {
    },
    editDialogLabel: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    editDialogContent: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 16,
      border: `1px dashed ${Color.TEXT}`,
      borderRadius: 6,
      textAlign: "center",
    },
    editDialogContentInput: {
      flex: "0 0 auto",
    },
    editDialogContentDragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
    editDialogContentSelected: {
      backgroundColor: Color.LIGHT_GREY,
    },
  });

export const editUserProfile = () =>
  createStyles({
    select: {
      paddingLeft: 10,
      width: "100%",
    }
  });

export const listItem = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      alignContent: "center",
    },
    label: {
      color: Color.TEXT,
      cursor: "default",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      textTransform: "none",
      wordBreak: "break-word",
      marginRight: 8,
    },
    icon: {
      color: primaryColor,
      alignSelf: "center",
      width: 16,
      height: 16,
      marginTop: 0,
      marginRight: 10,
    },
    actions: {

    },
    downloadLink: {
      color: Color.BLUE,
      textDecoration: "underline",
      cursor: "pointer",
    },
    error: {
      color: Color.RED,
      textDecoration: "none",
      cursor: "default",
    },
    checkmarkIcon: {
      color: Color.SIGNIFY_GREEN,
      width: 16,
      height: 16,
      marginLeft: 8,
    },
  });

import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      flex: "0 0 60px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      backgroundColor: Color.LIGHT_GREEN3,
      marginTop: 25,
    },
    headerIcon: {
      color: Color.GREY9,
      marginLeft: 8,
      width: "auto",
      height: 24,
    },
    title: {
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
      margin: "10px 0 10px 8px",
    },
    toggleCollapsedButton: {
      marginLeft: "auto",
    },
    toggleCollapsedIcon: {
    },
    content: {
      display: "flex",
      flexFlow: "column nowrap",
      padding: "15px 10px 10px",
      justifyContent: "center",
      backgroundColor: Color.LIGHT_GREEN2,
    },
    emptyView: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    emptyViewTitle: {
      fontSize: 22,
      fontWeight: 300,
      margin: "10px 0 5px",
    },
    emptyViewText: {
      alignSelf: "center",
      margin: "8px 0",
      textAlign: "center",
      textDecoration: "none",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "24px",
      color: Color.TEXT,
    },
    loadingView: {
      color: Color.GREY3,
      margin: "10px 0",
      "& > label": {
        fontSize: 18,
        lineHeight: "28px",
      },
    },
    linkContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "right",
    },
    viewMoreLink: {
      fontSize: 14,
      marginTop: 20,
      textAlign: "center",
    },
    metricContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    metric: {
      width: "30%",
      borderRadius: 10,
      overflow: "hidden",
      border: `1px solid ${Color.LIGHT_GREEN3}`,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)"
    },
    metricTitle: {
      padding: 8,
      fontSize: 14,
      lineHeight: "16px",
      textAlign: "center",
      backgroundColor: Color.LIGHT_GREEN3,
    },
    metricValue: {
      padding: "20px 20px 5px 20px",
      fontSize: 14,
      fontWeight: 300,
      textAlign: "center",
    },
    metricPercent: {
      margin: 5,
      padding: 5,
      fontSize: 10,
      fontWeight: 300,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    percentIconUp: {
      color: Color.SIGNIFY_GREEN,
      marginRight: 8,
      width: "auto",
      height: 18,
    },
    percentIconDown: {
      color: Color.DARK_RED,
      marginRight: 8,
      width: "auto",
      height: 18,
    },
  });

export default styles;

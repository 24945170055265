import Color from "@Color";
import ModuleIcon from "@material-ui/icons/MonetizationOn";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: "auto",
      height: 48,
    },
    title: {
      marginLeft: 10,
      color: Color.TEXT,
      fontWeight: 300,
    },
    chart: {
      marginTop: 10,
      flex: "1 0 auto",
      minWidth: 400,
      minHeight: 400,
    },
    columnContainer: {
      flexFlow: "column-reverse wrap",
    },
    leftContainer: {
      width: "78%",
    },
    fullLeftContainer: {
      width: "100%",
    },
    rightContainer: {
      minHeight: 500,
      width: "18%",
      marginLeft: "2%",
    },
    fullRightContainer: {
      minHeight: 500,
      width: "100%",
      margin: 0,
    },
    infoLabel: {
      fontSize: 14,
      color: Color.GREY3,
      lineHeight: "36px",
    },
    infoLabelContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    filterButton: {
      "& .MuiButton-label": {
        color: Color.GREY3,
      },
      boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.1)",
    },
    headers: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      justifyContent: "space-around",
      gap: 10,
      marginBottom: 20,
      borderRadius: 10,
      boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.1)",
    },
    headerCard: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px 20px",
      margin: "10px 0px",
      color: Color.TEXT,
      fontSize: 16,
      fontWeight: 300,
      width: "fit-content",
      minWidth: "19%",
      minHeight: 100,
      borderRadius: 4,
    },
    headerCardTitle: {
      color: Color.TEXT,
      fontSize: 16,
      fontWeight: 500,
    },
    headerCardSubTitle: {
      color: Color.SIGNIFY_GREEN2,
      fontSize: 24,
      fontWeight: 300,
      marginTop: 10,
    },
    headerFilterCard: {
      marginBottom: 20,
      width: "100%",
      justifyContent: "normal",
      borderRadius: 10,
      boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.1)",
    },
    dropdown: {
      alignItems: "stretch",
      margin: "15px 0px",
      width: "100%",
      "& .MuiOutlinedInput-input": {
        paddingBottom: 12,
        paddingLeft: 10,
      },
      "& .dropdownMenuHelperText": {
        marginLeft: 4,
      },
    },
    dropdownMenuEmptyValueLabel: {
      color: Color.GREY3,
      fontStyle: "normal",
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      fontSize: 16,
      lineHeight: "16px",
    },
    graphUnit: {
      fontSize: 12,
      textAlign: "center",
      marginTop: 15,
      width: "100%",
      "& .MuiToggleButtonGroup-root": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: 10,
      },
    },
    autocomplete: {
      width: "100%",
      margin: "15px 0px",
      maxHeight: "fit-content",
    },
    autocompleteTextField: {
    },
    autocompleteFormHelperText: {
      fontSize: 12,
      margin: "3px 0px 0px 4px"
    },
    autocompleteInput: {
      padding: "12px 14px",
    },
    autocompleteInputLabel: {
      fontSize: 16,
      lineHeight: "16px",
      transform: "translate(12px, 25px) scale(1)",
      "&$error:not($focused)": {
        transform: "translate(12px, 5px) scale(1)",
      },
      "&$focused": {
      },
    },
    autocompleteInputLabelShrink: {
      lineHeight: "1 !important",
      transform: "translate(10.5px, -6px) scale(0.75) !important",
    },
    autocompleteOption: {
      color: Color.TEXT,
      padding: "8px 12px 8px 12px",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
    },
    autocompleteTag: {
      maxWidth: 200,
      fontSize: 10,
      "& svg": {
        color: Color.WHITE,
        "&:hover, &:active, &:focus": {
          color: Color.BLACK,
        },
      },
    },
    autocompleteTextFieldInput: {
      "& .MuiAutocomplete-clearIndicator svg": {
        width: 20,
        height: 20,
        color: Color.GREY5,
      },
    },
    autocompleteTextFieldInputPropsInput: {
      lineHeight: "1 !important",
      padding: "9.5px 0px 9.5px 1px !important"
    },
    focused: {},
    error: {},
  });

export default styles;
